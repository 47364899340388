import React from 'react';
import { Button as ChakraButton } from '@chakra-ui/react';
import MotionBox from '../MotionBox/MotionBox';

function Button({ children, ...props }) {
  return (
    <MotionBox
      whileTap={{ opacity: 0, scale: 0.8 }}
      whileHover={{ opacity: 0.8 }}
    >
      <ChakraButton
        m="3"
        borderRadius="50px"
        filter="drop-shadow(-21.6355px 37.5774px 2.27742px rgba(0, 0, 0, 0.25));"
        fontSize={{ base: 'xl', md: '3xl' }}
        color="sky"
        bg="white"
        p="7"
        minW={{ base: '80%', md: '200px' }}
        {...props}
      >
        {children}
      </ChakraButton>
    </MotionBox>
  );
}

export default Button;
