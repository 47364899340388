import React, { useState, useEffect } from 'react';
import useSocket from '../../hooks/useSocket';
import { Text } from '@chakra-ui/react';
import { TEAMS } from 'constants';

function InstructionPopUpContainer() {
  const socket = useSocket();
  const [instructions, setInstructions] = useState([]);

  useEffect(() => {
    if (!socket) return;
    socket.on('instruction', ({ instruction, team }) => {
      setInstructions(prev => [...prev, { instruction, team }]);
      console.log('ici', instruction);
    });
  }, [socket]);

  return (
    <>
      {instructions.map((message, index) => (
        <InstructionMessage key={index} message={message} />
      ))}
    </>
  );
}

function InstructionMessage({ message }) {
  const { instruction, team } = message;
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [display, setDisplay] = useState(true);

  // Logique pour définir une position aléatoire sur l'écran
  useEffect(() => {
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;
    const randomX = Math.random() * (screenWidth - 100); // 100 est la largeur maximale du message
    const randomY = Math.random() * (screenHeight - 200); // 50 est la hauteur maximale du message
    setPosition({ x: randomX, y: randomY });

    const timer = setTimeout(() => {
      setDisplay(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  if (!display) return;
  return (
    <Text
      color="white"
      p="2"
      w="fit-content"
      fontWeight="bold"
      bg={team === TEAMS.RED ? 'redTeam' : 'blueTeam'}
      textAlign="center"
      position="absolute"
      left={position.x}
      top={position.y}
    >
      {instruction}
    </Text>
  );
}

export default InstructionPopUpContainer;
