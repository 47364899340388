import { useEffect, useState } from 'react';

import useSound from 'hooks/useSound';
import sadMusic from 'sounds/sad-music.mp3';
import { Flex, Text } from '@chakra-ui/react';
import MotionBox from 'components/MotionBox';
import { LOCATIONS } from 'constants';
import { useSelector } from 'react-redux';
import { randomInArray } from 'utils/random';
import { sadMessages } from 'constants/messages';

const sadLocations = [LOCATIONS.GAME, LOCATIONS.TIE_BREAK];

const SadOverlay = () => {
  const { options: userOptions } = useSelector(state => state.user);
  const { location, me } = useSelector(state => state.game);
  const [play, { stop }] = useSound(sadMusic);
  const [currentMessage, setCurrentMessage] = useState(
    randomInArray(sadMessages)
  );

  useEffect(() => {
    if (me?.spectator) return
    if (
      userOptions.sound === false ||
      location !== LOCATIONS.GAME ||
      (me.alive && me.canPlay)
    ) {
      stop();
      return;
    }
    setTimeout(() => {
      // on laisse le temps de l'animation des jetons
      play({ forceSoundEnabled: userOptions.sound });
    }, 1000);
    return () => stop();
  }, [play, location, stop, userOptions, me.alive, me.canPlay, me?.spectator]);

  useEffect(() => {
    const messageInterval = setInterval(() => {
      setCurrentMessage(randomInArray(sadMessages));
    }, 10000);
    return () => {
      clearInterval(messageInterval);
    };
  }, []);

  if (!sadLocations.includes(location) || (me.alive && me.canPlay)) return;

  return (
    <Flex
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'black',
      }}
      opacity="0.6"
      zIndex="1"
      pointerEvents="none"
      textAlign="center"
      justif="center"
      align="center"
      p="5"
    >
      {me?.spectator ? (
        <Text
          fontWeight="bold"
          w="100%"
          fontSize={{ base: '20px', md: '50px' }}
          color="white"
        >
          Mode Spectateur
        </Text>)
        : (<MotionBox
          key={currentMessage}
          w="100%"
          initial={{ opacity: 0 }}
          animate={{
            opacity: [0, 1, 0], // Vous pouvez ajuster les valeurs et le nombre de points vers le haut ici
          }}
          transition={{ duration: 10 }}
        >
          <Text
            fontWeight="bold"
            w="100%"
            fontSize={{ base: '50px', md: '100px' }}
            color="white"
          >
            {me.canPlay
              ? currentMessage
              : 'Tu as tiré la boule noire, ça peut arriver, garde ton calme'}
          </Text>
        </MotionBox>)}
    </Flex>
  );
};

export default SadOverlay;
