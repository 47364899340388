import MotionBox from '../components/MotionBox';
import useSound from '../hooks/useSound';

import rightSound from '../sounds/lucky.mp3';
import wrongSound from '../sounds/unlucky.mp3';
import { useEffect } from 'react';

const Feedback = ({ isError = null }) => {
  const color = isError ? 'rgba(255, 0, 0, 0.5)' : 'rgba(0, 255, 0, 0.5)';
  const [playRight] = useSound(rightSound);
  const [playWrong] = useSound(wrongSound);

  useEffect(() => {
    if (isError === null) return;

    if (isError) {
      playWrong();
      return;
    }

    playRight();
  }, [playRight, playWrong, isError]);

  if (isError === null) return;

  return (
    <MotionBox
      initial={{ opacity: 0 }}
      animate={{ opacity: [1, 0] }}
      exit={{ opacity: 1 }}
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: color,
      }}
      pointerEvents="none"
    />
  );
};

export default Feedback;
