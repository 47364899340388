// important if this file changes you also need to change server/src/constants

export const ANNOUNCE = {
  FACE : {
    NORMAL: "NORMAL",
    NORMAL_EYES_LEFT: "NORMAL_EYES_LEFT",
    NORMAL_EYES_RIGHT: "NORMAL_EYES_RIGHT",
    SNEAKY: "SNEAKY",
    SHOCKED: "SHOCKED",
    DISAPPOINTED: "DISAPPOINTED",
    HAPPY: "HAPPY",
    SAD: "SAD",
  },
  ANIMATION: {
    WALKING: "WALINKG",
    JOY: "JOY",
    PROUD: "PROUD",
    DISAPPOINTED: "DISAPPOINTED",
  },
  SOUND: {
    APPLAUSE: "APPLAUSE",
    BOO: "BOO",
    GASP: "GASP"
  }
}


export const TEAMS = {
  RED: 'red',
  BLUE: 'blue',
  BLACK: 'black',
  JURY: 'jury',
};

export const LOCATIONS = {
  ANNOUNCEMENT: 'ANNOUNCEMENT',
  GAME: 'GAME',
  CAMP: 'CAMP',
  ELIMINATION: 'ELIMINATION',
  VOTE: 'VOTE',
  TIE_BREAK: 'BOULE NOIRE',
  SEMI_FINAL: 'DEMI FINALE',
  PIOUTEAUX: "PIOUTEAUX",
  FINAL: 'FINALE',
  ACTIVITY: {
    BRACELET: 'BRACELET',
    FISHING: 'FISHING',
    INVENTORY: 'CRAFT',
    CAMPFIRE: 'CAMPFIRE',
  },
};

export const ELEM = {
  PALM: "Palmier",
  // ROCK: "Rocher",
  // PINES: "Sapins",
  NORMAL_TREE: "Arbre normal",
  FRUIT_TREE: "Arbre à fruits",
  DEAD_TREE: "Arbre Mort",
}

export const FOREST_LOCATIONS = {
  NORTH: 'NORD',
  EAST: 'EST',
  WEST: 'OUEST',
  SOUTH: 'SUD',
  CENTER: 'CENTRE',
};

export const MEMORY_OBJECTS = {
  FISH: 'FISH',
  BRACELET: 'BRACELET',
  PALM: 'PALM',
  VOTE: 'VOTE',
};

export const GAMES = {
  ARCHERY: "ARCHERY",
  RELAIS: "RELAIS",
  MORE_OR_LESS: "PLUS OU MOINS",
  DODGE: "ESQUIVE",
  MEMORY: "SUITE MEMOIRE",
  BLIND_RUN: "BLIND RUN",
  BATTLESHIPS: "BATTLESHIPS",
  ODD_ONE_OUT: "ODD ONE OUT",
  COSMOS_COMBO: "COSMOS COMBO",
  DEFENDER: "DEFENDER",
  WHACKAMOLE: "TAPE TAUPE",
}

export const TYPE_GAMES = {
  SOLO: [GAMES.ARCHERY, GAMES.MORE_OR_LESS, GAMES.DODGE, GAMES.MEMORY, GAMES.ODD_ONE_OUT, GAMES.COSMOS_COMBO, GAMES.DEFENDER, GAMES.WHACKAMOLE],
  TEAM: [GAMES.ARCHERY, GAMES.RELAIS, GAMES.DODGE, GAMES.MEMORY, GAMES.BLIND_RUN, GAMES.BATTLESHIPS, GAMES.DEFENDER, GAMES.WHACKAMOLE]
}
