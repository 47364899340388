import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    user: null,
    options: {},
  },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setAvatar: (state, action) => {
      state.user.avatar = action.payload;
    },
    updateUserState: (state, action) => {
      state.user = { ...state.user, ...action.payload };
    },
    clearUser: state => {
      state.user = null;
    },
    setUserOptions: (state, action) => {
      state.options = action.payload;
    },
  },
});

export const {
  setUser,
  clearUser,
  setAvatar,
  setUserOptions,
  updateUserState,
} = userSlice.actions;

export const selectUser = state => state.user.user;

export default userSlice.reducer;
