import React, { useEffect, useState } from 'react';
import { Heading, VStack, Text, HStack, CircularProgress, CircularProgressLabel } from '@chakra-ui/react';
import { db } from 'myFirebase';
import { collection, query, orderBy, getDocs } from 'firebase/firestore';
import Avatar from 'components/Avatar';
import Icon from 'components/Icon';
import userTitles from 'constants/userTitles';
import { useNavigate } from 'react-router-dom';

const Leaderboard = () => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const getUsers = async () => {
      const usersCollection = collection(db, 'users');
      const q = query(usersCollection, orderBy('stats.total_final_won', 'desc'));
      const querySnapshot = await getDocs(q);
      const userData = querySnapshot.docs.map(doc => ({
        ref: doc.id,
        ...doc.data(),
      }));
      setUsers(userData);
    };

    getUsers();
  }, []);  return (
    <VStack mt={8} spacing={10}>
      <Heading as="h1" size="3xl">Leaderboard</Heading>
      {users.map((user, index) => (
        <UserCard key={index} user={user} position={index + 1} />
      ))}
    </VStack>
  );
};

const UserCard = ({ user, position }) => {
  console.log("user", user)
  const navigate = useNavigate()
  return (
    <HStack
      w="80vw"
      spacing={5}
      bg="gold"
      border="1px solid black"
      p={5}
      cursor="pointer"
      onClick={() => navigate(`/profil/${user?.ref}`)}
      flexWrap="wrap"
      justify="center"
    >
      <Text fontSize="200" color="black" fontWeight="extrabold">{position}</Text>
      <Avatar forceAvatar={user.avatar} name={user.name} />
      <VStack>
        <Text fontSize="4xl" fontWeight="bold">{user.name}</Text>
        {user?.currentTitle && (
          <HStack>
            <Text fontSize="2xl">
              {userTitles[user.currentTitle].name}
            </Text>
            <Icon
              boxSize="7"
              color={userTitles[user.currentTitle].color}
              icon={userTitles[user.currentTitle].icon}
            />
          </HStack>
        )}
      </VStack>
      <VStack>
        <Text fontWeight="extrabold" fontSize="xl">{user.stats.total_final_won} Parties remportées</Text>
        <Text fontSize="xl">{user.stats.total_games} Parties jouées</Text>
      </VStack>
      <VStack>
        <CircularProgress value={(user.stats.total_final_won / user.stats.total_games).toFixed(2) * 100} color='vegetation' thickness='12px'>
          <CircularProgressLabel fontWeight="bold">{Math.round((user.stats.total_final_won / user.stats.total_games).toFixed(2) * 100)}%</CircularProgressLabel>
          </CircularProgress>
      </VStack>
    </HStack>
  );
};

export default Leaderboard;