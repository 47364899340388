import React, { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import useSocket from '../../hooks/useSocket';
import PixiCanvas from '../../components/PixiCanvas';
import PlayerPixi from '../../components/PixiCanvas/PlayerPixi';

import { Flex } from '@chakra-ui/react';
import { getPlayerCoords } from '../getPlayerCoords';
import ElementPixi from '../../components/PixiCanvas/ElementPixi';
import IconButton from '../../components/Button/IconButton';
import GameHelper from '../../components/GameHelper/GameHelper';
import usePixi from 'hooks/usePixi';

const CANVA_WIDTH = 100;
const CANVA_HEIGHT = 50;

function Dodge() {
  const {width, height} = usePixi()
  const [playerCoords, setPlayerCoords] = useState({ x: 10, y: 15 });

  const { me, players, gameId, myId, obstacles } = useSelector(
    state => state.game
  );
  const socket = useSocket();

  const canClick = useMemo(
    () =>
      me.canMove &&
      me.canPlay &&
      me.alive,
    [me]
  );

  useEffect(() => {
    setPlayerCoords(
      getPlayerCoords(
        me.x,
        me.y,
        CANVA_WIDTH,
        CANVA_HEIGHT,
        width,
        height
      )
    );
  }, [me, height, width]);

  useEffect(() => {
    console.log("canclick", canClick);
    console.log("mecanclcik", me);
    if(!canClick) return
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
    // eslint-disable-next-line
  }, [canClick]);

  function handleKeyDown(e) {
    if (e.key === 'ArrowUp') {
      handleDodgeUp();
      return;
    }
    if (e.key === 'ArrowDown') {
      handleDodgeDown();
    }
  }

  const handleDodgeUp = () => {
    if (!me.canMove || !me.canPlay || !me.alive) return;
    socket.emit('dodge', { gameId, myId, direction: 1 });
  };
  const handleDodgeDown = () => {
    if (!me.canMove || !me.canPlay || !me.alive) return;
    socket.emit('dodge', { gameId, myId, direction: -1 });
  };

  return (
    <>
      <PixiCanvas>
        {players
          .filter(p => p.alive && p.id !== myId && p.canMove)
          .map(player => (
            <PlayerPixi
              key={player.id}
              opacity={0.5}
              name={player.name}
              facingRight
              coords={getPlayerCoords(
                player.x,
                player.y,
                CANVA_WIDTH,
                CANVA_HEIGHT,
                width,
                height,
              )}
              avatar={player?.avatar}
            />
          ))}
        {me.canMove && me.canPlay && me.alive && (
          <PlayerPixi
            facingRight
            coords={playerCoords}
            name={me.name}
            id={me.id}
            isMe={true}
            avatar={me?.avatar}
          />
        )}
        {obstacles.map(obstacle => (
          <Obstacle obstacle={obstacle} />
        ))}
      </PixiCanvas>

      <GameHelper text="Clique sur les flêches pour changer de position">
        <Flex justify="center" wrap="wrap">
          <IconButton icon="icon-park-solid:up-two" onClick={handleDodgeUp} isDisabled={!canClick} />
          <IconButton
            icon="icon-park-solid:down-two"
            onClick={handleDodgeDown}
            isDisabled={!canClick}
          />
        </Flex>
      </GameHelper>
    </>
  );
}

function Obstacle({ obstacle }) {
  const {width, height} = usePixi()
  const [obstacleCoords, setObstacleCoords] = useState(obstacle);
  useEffect(() => {
    setObstacleCoords(
      getPlayerCoords(
        obstacle.x,
        obstacle.y,
        CANVA_WIDTH,
        CANVA_HEIGHT,
        width,
        height,
      )
    );
  }, [obstacle, height, width]);

  return (
    <ElementPixi
      x={obstacleCoords.x}
      y={obstacleCoords.y}
      image={'/images/rock.svg'}
    />
  );
}

export default Dodge;
