import { useState } from 'react';

function useLocalStorage(key, initialValue) {
  // Récupérer la valeur stockée dans le localStorage au chargement de la page
  const storedValue = localStorage.getItem(key);
  const initial =
    storedValue && storedValue !== 'undefined'
      ? JSON.parse(storedValue)
      : initialValue;

  // Utiliser le state local pour la valeur actuelle
  const [value, setValue] = useState(initial);

  // Mettre à jour la valeur dans le localStorage à chaque changement de la valeur
  const setStoredValue = newValue => {
    console.log('store', newValue, 'key', key);
    setValue(newValue);
    localStorage.setItem(key, JSON.stringify(newValue));
  };

  return [value, setStoredValue];
}

export default useLocalStorage;
