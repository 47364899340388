import React, { useMemo } from 'react';
import Modal from './Modal';
import {
  useDisclosure,
  ModalHeader,
  useNumberInput,
  HStack,
  Button as ChakraButton,
  Flex,
  Image,
} from '@chakra-ui/react';
import Input from '../Input/Input';
import Button from '../Button/Button';
import SelectPlayerModal from './SelectPlayerModal';
import usePlayerItems from '../../hooks/usePlayerItems';
import useSocket from '../../hooks/useSocket';
import { useSelector } from 'react-redux';
import { ITEMS } from 'constants/items';

function GiveOrEatModal({ isOpen, onClose, max, itemKey }) {
  const { myId, gameId } = useSelector(state => state.game);
  const {
    items,
    updateItems,
  } = usePlayerItems();
  const socket = useSocket();

  const {
    isOpen: isOpenSelectPlayer,
    onClose: onCloseSelectPlayer,
    onOpen: onOpenSelectPlayer,
  } = useDisclosure();

  const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } =
    useNumberInput({
      step: 1,
      defaultValue: 0,
      min: 0,
      max: max || 0,
    });

  const inc = getIncrementButtonProps();
  const dec = getDecrementButtonProps();
  const input = getInputProps();

  const canClick =
    useMemo(() => input?.value > 0 && input?.value <= max, [max, input]) ||
    false;

  const handleEat = () => {
    const toEatItems = input?.value || 0;
    // we get that number of lifepoit by fish eaten
    const lifeBarPoints = toEatItems * (ITEMS[itemKey]?.hp || 4);
    // prevent the user to have a lifebar to high
    const tmpLifeBar =
      items.lifeBar + lifeBarPoints > 100 ? 100 : items.lifeBar + lifeBarPoints;
    updateItems({ [itemKey]: items?.[itemKey] - toEatItems }, ITEMS?.[itemKey]?.type);
    updateItems({ lifeBar: tmpLifeBar });
  };

  const handleGive = () => {
    onClose();
    onOpenSelectPlayer();
  };

  const handleValidateGive = ({ to, message }) => {
    const toSendItems = parseInt(input?.value || 0);
    updateItems({ [itemKey]: items?.[itemKey] - toSendItems }, ITEMS?.[itemKey]?.type);
    socket.emit('sendItems', {
      from: myId,
      to,
      message,
      type: ITEMS[itemKey]?.type,
      gameId,
      count: toSendItems,
      item : itemKey,
    });
  };

  return (
    <>
      <Modal onClose={onClose} isOpen={isOpen} bg="primary" size="md">
        <ModalHeader fontSize="2xl" align="center" color="white">
          Combien ?
        </ModalHeader>
        {itemKey && <Image h="fit-content" maxH="10vh" w="auto" src={ITEMS[itemKey].image} alt={ITEMS[itemKey].name} />}
        <HStack  maxW="100%" placeSelf="center" placeContent="center" p="5">
          <ChakraButton {...dec}>-</ChakraButton>
          <Input
            w="30%"
            {...input}
            textAlign="center"
            fontSize="3xl"
            fontWeight="700"
            max="12"
          />
          <ChakraButton {...inc}>+</ChakraButton>
        </HStack>
        <Flex p="2" justify="center">
          {itemKey && ITEMS[itemKey].canEat && <Button disabled={!canClick || items.lifeBar >= 100} onClick={handleEat}>
            Manger
          </Button>}
          <Button
            disabled={!canClick}
            onClick={handleGive}
            bg="sky"
            color="white"
          >
            Offrir
          </Button>
        </Flex>
      </Modal>
      <SelectPlayerModal
        isOpen={isOpenSelectPlayer}
        onClose={onCloseSelectPlayer}
        onValidate={handleValidateGive}
      />
    </>
  );
}

export default GiveOrEatModal;
