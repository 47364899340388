import { useSelector } from 'react-redux';
import useSocket from './useSocket';

export default function useChangeForestLocation() {
  const socket = useSocket();
  const { gameId, myId } = useSelector(state => state.game);

  const changeForestLocation = forestLocation => {
    socket.emit('forestLocation', { forestLocation, gameId, myId });
  };
  return changeForestLocation;
}
