import Palm from '../svg/Palm.svg';
import Fish from '../svg/Fish.svg';
import Heart from '../svg/heart.svg';
import CampFire from '../svg/ActivityCampfire.svg';
import Hint from '../svg/hint.svg';
import FishCan from '../components/Camp/fishing-cursor.png';
import Vote from '../png/vote.png';
import BlackBall from '../svg/black-ball.svg';
import Trees from '../components/Camp/forestAssets/trees.svg';
import Bracelet from '../svg/Bracelet.svg';
import Coin from 'svg/coin.svg';

const trophies = {
  total_fishes: {
    title: 'Le poissonier du groupe',
    stat: 'poissons obtenus au total',
    bg: 'sea',
    trophySrc: FishCan,
  },
  total_fishes_sent: {
    title: 'Donateur sans limite',
    stat: 'objets envoyés aux coéquipiers',
    bg: 'sea',
    trophySrc: Fish,
  },
  total_game_won: {
    title: 'Athlète de haut niveau',
    stat: 'épreuves remportées',
    bg: 'gold',
    trophySrc: Palm,
  },
  total_life_restored: {
    title: 'Source de vie',
    stat: 'points de vie restaurés',
    bg: 'primary',
    trophySrc: Heart,
  },
  total_bracelets: {
    title: 'Invulnérable',
    stat: 'bracelets obtenus',
    bg: 'gold',
    trophySrc: Bracelet,
  },
  total_bracelets_used: {
    title: 'Plot Twist Master',
    stat: 'bracelets utilisés au conseil',
    bg: 'red',
    trophySrc: Bracelet,
  },
  total_bracelets_sent: {
    title: 'Grand stratège',
    stat: 'bracelets envoyés',
    bg: 'gray',
    color: 'white',
    trophySrc: Bracelet,
  },
  total_fire_sent: {
    title: 'Porteur de lumière',
    stat: 'clics sur le feu de camps',
    bg: 'black',
    color: 'white',
    trophySrc: CampFire,
  },
  total_messages_sent: {
    title: 'Piplette',
    stat: 'messages envoyés',
    bg: 'sky',
    color: 'white',
    trophySrc: Hint,
  },
  total_forest_search: {
    title: 'Pas très écologique',
    stat: 'recherches dans la forêt',
    bg: 'vegetation',
    trophySrc: Trees,
  },
  total_vote_received: {
    title: 'La cible à abattre',
    stat: 'votes reçus au conseil',
    bg: 'black',
    color: 'white',
    trophySrc: Vote,
  },
  total_tie_break_lose: {
    title: 'Malchance incarnée',
    stat: 'boules noires piochées',
    bg: 'white',
    trophySrc: BlackBall,
  },
  total_coins: {
    title: 'Addict au jeux',
    stat: 'jetons au total',
    bg: 'gold',
    trophySrc: Coin,
  },
};
const computeTrophies = players => {
  if (!players?.length) return [];
  const results = [];

  Object.entries(trophies).forEach(([key, value]) => {
    // Trouver le joueur avec le score le plus élevé pour chaque statistique.
    const highestScoringPlayer = players.reduce((prev, current) => {
      return prev.stats[key] > current.stats[key] ? prev : current;
    });
    // Ajouter un objet à 'results' avec les détails du trophée et du joueur.
    if (highestScoringPlayer.stats[key] > 0) {
      // On ajoute seulement si le score est supérieur à 0.
      results.push({
        title: value.title,
        stat: `${highestScoringPlayer.stats[key]} ${value.stat}`,
        player: highestScoringPlayer, // Ou une autre propriété identifiant le joueur.
        bg: value.bg,
        trophySrc: value.trophySrc,
        color: value.color,
      });
    }
  });
  return results;
};

export default computeTrophies;
