export const sadMessages = [
  'Profite bien de ta vue panoramique sur le banc des remplaçants...',
  'Qui aurait cru que regarder les autres jouer serait un jour ton activité principale ?',
  "Tu es comme le VIP de l'équipe : tu as une vue imprenable sur le jeu sans avoir à participer !",
  "L'herbe est toujours plus verte... sauf quand tu es éliminé et que tu dois la regarder de loin !",
  'La prochaine fois, peut-être que tu choisiras une stratégie qui te permettra de jouer plus longtemps...',
  "C'est dur d'être une star quand on est relégué au banc de touche, n'est-ce pas ?",
  'On dit que la patience est une vertu. Profite de cette occasion pour la développer !',
  "Ne t'inquiète pas, les spectateurs ont souvent une meilleure vue que les acteurs...",
  'Qui aurait cru que tu serais si bon pour encourager les autres depuis le banc de touche ?',
  'Si tu as besoin de pleurer ne te retiens pas surtout ...',
  'On a tous droit à une seconde chance, tous ... sauf toi',
  "Si tu as déjà vu toutes les phrases c'est que tu perds trop souvent",
  "Tu es sûrement super fort à d'autres jeux que Cozy Surviving !",
  'Concentre toi sur tes études ou ton métier, ça vaut mieux',
  "Tu te souviens quand toi aussi tu jouais ? C'était cool hein",
  'Allez discute avec les autres joueurs qui manquent de talent pour patienter',
];
