import React from 'react';
import { Flex, Image, Text } from '@chakra-ui/react';

function valueTokg(value) {
  if (value >= 1000) {
    return `${value / 1000}Kg`;
  } else {
    return `${value}g`;
  }
}

const MoreOrLessButton = ({ imageSrc, value, onClick, disabled }) => {
  const handleClick = () => {
    if (disabled) return;
    console.log('click');
    onClick && onClick({ imageSrc, value });
  };

  return (
    <Flex
      onClick={handleClick}
      w={{ base: '20%', md: '15%' }}
      h="fit-content"
      cursor={disabled ? 'unset' : 'pointer'}
      overflow="hidden"
      rounded="25px"
      transition="transform 0.2s ease-in-out"
      _hover={{ transform: 'scale(1.05)' }}
      bg="white"
      opacity={disabled ? '0.5' : '1'}
      m="2"
      p="1"
      direction="column"
    >
      <Image maxH="90px" w="auto" h="auto" src={imageSrc} />
      <Text fontWeight="bold" fontSize="xl">
        {valueTokg(value)}
      </Text>
    </Flex>
  );
};

export default MoreOrLessButton;
