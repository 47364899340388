export default function applyChange(target, changes) {
  // Vérifier si les modifications sont définies et s'il s'agit d'un objet
  if (changes && typeof changes === 'object') {
    for (const key in changes) {
      if (changes.hasOwnProperty(key)) {
        const change = changes[key];

        // Vérifier si la valeur de la modification est un tableau
        if (Array.isArray(change)) {
          // Mettre à jour le tableau dans la cible avec les valeurs de modification
          target[key] = change.filter(value => value !== 'undefined');
        } else if (change && typeof change === 'object') {
          if (!target.hasOwnProperty(key)) {
            target[key] = {};
          }

          applyChange(target[key], change);
        } else {
          // Vérifier si la valeur est "undefined"
          if (change === 'undefined') {
            if (Array.isArray(target)) {
              target.splice(parseInt(key), 1);
            }
          } else {
            target[key] = change;
          }
        }
      }
    }
  } else {
    // Si les changements sont undefined, utiliser la valeur de la cible
    target = changes || target;
  }
}
