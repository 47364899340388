import React, { useState } from 'react';
import {
  Flex,
  Image,
  Text,
  FormLabel,
  FormControl,
  Checkbox,
  Link,
} from '@chakra-ui/react';
import Axios from 'axios';
import Title from '../components/Title/Title';
import Input from '../components/Input/Input';
import Button from '../components/Button/Button';
import Form from '../components/Form/Form';
import useError from '../hooks/useError';
import useSuccess from '../hooks/useSuccess';

function Preinscription() {
  const [email, setEmail] = useState('');
  const [newsletter, setNewsletter] = useState(false);
  const [onError] = useError();
  const [onSuccess] = useSuccess();

  const handleSubscribe = async e => {
    const listIds = [6];
    if (newsletter) {
      listIds.push(3);
    }
    e.preventDefault();
    try {
      await Axios.post(
        'https://api.sendinblue.com/v3/contacts',
        {
          email: email,
          listIds,
        },
        {
          headers: {
            'api-key': process.env.REACT_APP_SENDINBLUE_API_KEY,
            'Content-Type': 'application/json',
          },
        }
      );
      setEmail('');
      onSuccess("L'inscription a bien été prise en compte !");
    } catch (error) {
      onError(error.response.data.message);
    }
  };
  return (
    <Flex direction="column" justify="space-around" align="center" minH="80vh">
      <Title fontSize="5xl">Cozy Surviving - Preinscription</Title>
      <Flex
        borderRadius="15px"
        direction="column"
        align="center"
        p="6"
        color="white"
        bg="vegetation"
        w={{ base: '90%', md: '50%' }}
      >
        <Text fontSize="xl" textAlign="center">
          Le jeu est en cours de développement, laisse ton E-mail pour en
          connaître les avancées et te préinscrire afin de devenir un membre
          <b> premium</b> gratuitement le jour J !
        </Text>
      </Flex>
      <Image
        borderRadius="15px"
        border="2px solid white"
        src="./gifs/tutorial.gif"
        h="40vh"
        m="3"
      />
      <Form onSubmit={handleSubscribe}>
        <FormControl isRequired>
          <FormLabel>E-mail</FormLabel>
          <Input
            value={email}
            onChange={e => setEmail(e.target.value)}
            placeholder="mail.example.fr"
            type="email"
            autoComplete="email"
          />
        </FormControl>
        <Checkbox
          onChange={e => {
            setNewsletter(e.target.checked);
          }}
          m="3"
          size="md"
          colorScheme="green"
        >
          Je souhaite également recevoir une newsletter pour les développeurs
          Freelances
        </Checkbox>
        <Link
          color="vegetation"
          fontWeight="bold"
          target="_blank"
          href="https://www.cozy-codeur.fr/newsletter"
        >
          En savoir plus
        </Link>
        <Button m="5" type="submit">
          Participer à la préinscription
        </Button>
      </Form>
    </Flex>
  );
}
export default Preinscription;
