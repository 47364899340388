import React, { useState } from 'react';
import Modal from './Modal';
import { ModalHeader, ModalFooter, VStack, HStack, Slider, SliderTrack, SliderFilledTrack, SliderThumb, Switch } from '@chakra-ui/react';
import useSocket from "hooks/useSocket"
import Player from '../Player/Player';
import Button from "components/Button"
import Input from "components/Input"

import { useSelector } from 'react-redux';
import { isEasterEggAvatar } from 'constants/easterEggAvatars';
import Icon from 'components/Icon';

function UpdateBots({ isOpen, onClose = () => {} }) {
  const { players, gameId } = useSelector(state => state.game);
  const [renamedBots, setRenamedBots] = useState([]);
  const [restatBots, setRestatBots] = useState([]);
  const socket = useSocket();

  // Fonction pour mettre à jour la liste des joueurs renommés
  const handlePlayerRename = (id, value) => {
    const updatedBots = [...renamedBots];
    const playerIndex = updatedBots.findIndex(player => player.id === id);
    if (playerIndex !== -1) {
      updatedBots[playerIndex] = { id, value, avatar: isEasterEggAvatar(value) };
    } else {
      updatedBots.push({ id, value, avatar: isEasterEggAvatar(value) });
    }
    setRenamedBots(updatedBots);
  };
  
  const handlePlayerChangeStats = (id, key, value) => {
    const updatedBots = [...restatBots];
    const playerIndex = updatedBots.findIndex(player => player.id === id);
    if (playerIndex !== -1) {
      updatedBots[playerIndex] = { id, personality: { ...updatedBots[playerIndex].personality, [key]: value } };
    } else {
      updatedBots.push({ id, personality: {[key]: value} });
    }
    setRestatBots(updatedBots);
  };

  // Fonction appelée lorsque vous cliquez sur "Valider"
  const handleValidation = () => {
    console.log(renamedBots);
    socket.emit('updateBots', {
      renamedBots,
      restatBots,
      gameId,
    });
    onClose()
  };

  return (
    <Modal
      onClose={() => onClose()}
      isOpen={isOpen}
      bg="sea"
      size="lg"
      isCentered={false}
      overlay
    >
      <ModalHeader borderBottom="1px solid black" fontSize="2xl" align="center" color="white">
        Modifier les PNJ
      </ModalHeader>
      <HStack flexWrap="wrap" align="center" justify="center">
        {players?.filter(({ bot }) => bot).map(player => (
          <PlayerStat key={player?.id} player={player} handlePlayerRename={handlePlayerRename} handlePlayerChangeStats={handlePlayerChangeStats} />
        ))}
      </HStack>
      <ModalFooter w="100%">
        <HStack borderTop="1px solid black" w="100%" justify="space-around" align="center">
          <Button w="100%" bg="sky" color="white" onClick={() => onClose()}>Annuler</Button>
          <Button w="100%" onClick={handleValidation}>Valider</Button>
        </HStack>
      </ModalFooter>
    </Modal>
  );
}

function PlayerStat({ player, handlePlayerRename, handlePlayerChangeStats}) {
  return (
    <VStack>
      <Player
        position="initial"
        name={player?.name}
        avatar={player?.avatar}
        title={player?.title}
        noUsername
      />
      <Input 
        name={player?.id} 
        maxLength={20} 
        minLength={1} 
        w="80%" 
        defaultValue={player.name} 
        onChange={(e) => handlePlayerRename(player.id, e.target.value)} 
      />
      <VStack w="80%">
        <SliderStat id={player.id} icon="twemoji:fishing-pole" statKey={"food"} value={player.personality.food} onChange={handlePlayerChangeStats} />
        <SliderStat id={player.id} icon="icon-park-twotone:muscle" statKey={"challenge"} value={player.personality.challenge} onChange={handlePlayerChangeStats} />
        <SliderStat id={player.id} icon="material-symbols:forest" statKey={"bracelet"} value={player.personality.bracelet} onChange={handlePlayerChangeStats} />
        <SliderStat id={player.id} icon="ri:emotion-happy-line" statKey={"altruism"} value={player.personality.altruism} onChange={handlePlayerChangeStats} />
        <SliderStat id={player.id} icon="covid:social-distancing-1" statKey={"social"} value={player.personality.social} onChange={handlePlayerChangeStats} />
        <HStack w="100%">
          <Icon icon="streamline-emojis:determined-face" boxSize="25px" />
          <Switch defaultChecked={player.personality?.likeWinners}
            onChange={(event) => handlePlayerChangeStats(player.id, "likeWinners", event.target.checked)}
          />
        </HStack>
      </VStack>
    </VStack>
  );
}

const SliderStat = ({ id, icon, statKey, value, onChange, ...props }) => {
  return (
    <HStack w="100%">
      <Icon icon={icon} boxSize="25px" />
      <Slider min={1} max={100} defaultValue={value} onChange={(value) => onChange(id, statKey, value)} {...props}>
        <SliderTrack>
          <SliderFilledTrack />
        </SliderTrack>
        <SliderThumb />
      </Slider>
    </HStack>
  )
}

export default UpdateBots;
