import { useEffect, useRef } from 'react';
import * as PIXI from 'pixi.js'; // Importation de PIXI directement
import { useApp } from '@pixi/react';

const Confetti = () => {
  const app = useApp();
  const confettiRef = useRef([]);

  useEffect(() => {
    const confetti = confettiRef.current;
    const colors = [0xff0000, 0x00ff00, 0x0000ff, 0xffff00, 0xff00ff, 0x00ffff];

    const createConfetti = () => {
      const confettiPiece = new PIXI.Graphics();
      const size = Math.random() * 10 + 5;
      const color = colors[Math.floor(Math.random() * colors.length)];
      confettiPiece.beginFill(color);
      confettiPiece.drawRect(0, 0, size, size);
      confettiPiece.endFill();
      confettiPiece.x = Math.random() * app.screen.width;
      confettiPiece.y = Math.random() * -app.screen.height;
      confettiPiece.vx = (Math.random() - 0.5) * 2;
      confettiPiece.vy = Math.random() * 2 + 1;
      app.stage.addChild(confettiPiece);
      confetti.push(confettiPiece);
    };

    const animateConfetti = (delta) => {
      confetti.forEach((piece, index) => {
        piece.x += piece.vx * delta;
        piece.y += piece.vy * delta;
        piece.rotation += 0.1 * delta;
        if (piece.y > app.screen.height) {
          app.stage.removeChild(piece);
          confetti.splice(index, 1);
        }
      });

      if (confetti.length < 100) {
        createConfetti();
      }
    };

    app.ticker.add(animateConfetti);

    return () => {
      app.ticker.remove(animateConfetti);
      confetti.forEach((piece) => app.stage.removeChild(piece));
      confettiRef.current = [];
    };
  }, [app]);

  return null;
};

export default Confetti;
