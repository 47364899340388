import { useCallback } from 'react';
import useLocalStorage from './useLocalStorage';
import { useDispatch } from 'react-redux';
import { setUserOptions } from 'redux/features/userSlice';

const useOptions = () => {
  const [options, setOptions] = useLocalStorage('userOptions', {});
  const dispatch = useDispatch();

  const getOption = useCallback(
    key => {
      console.log('key', options);
      return options[key];
    },
    [options]
  );

  const setOption = useCallback(
    (key, value) => {
      const tmpOptions = { ...options, [key]: value };
      dispatch(setUserOptions(tmpOptions));
      setOptions(tmpOptions);
      console.log("set options", key);
    },
    [setOptions, options, dispatch]
  );

  return { options, getOption, setOption };
};

export default useOptions;
