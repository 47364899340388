import React from 'react';
import Login from '../components/Login/Login';
import { Flex } from '@chakra-ui/react';
import Title from '../components/Title/Title';

function LoginPage() {
  return (
    <Flex direction="column" justify="space-around" align="center" h="80vh">
      <Title>Connexion</Title>
      <Login />
    </Flex>
  );
}

export default LoginPage;
