import React, { useState, useEffect, useRef } from 'react';
import { Sprite, useApp, Container } from '@pixi/react';
import * as PIXI from 'pixi.js';
import netSvg from 'svg/fish-net.svg';

const FishNetElement = ({isCurrent, isPlaced, setIsPlaced, onPositionChange }) => {
  const [x, setX] = useState(window.innerWidth / 2);
  const [y, setY] = useState(0);
  const [isShrinking, setIsShrinking] = useState(false);
  const app = useApp();
  const spriteRef = useRef(null);
  const timeoutRef = useRef(null);
  const prevIsPlacedRef = useRef(isPlaced);

  const updateMousePosition = (event) => {
    const rect = app.view.getBoundingClientRect();
    const mouseX = event.clientX - rect.left;
    const mouseY = event.clientY - rect.top;
    setX(mouseX);
    setY(mouseY);
    onPositionChange(mouseX, mouseY);
  };

  useEffect(() => {
    const handleMouseMove = (event) => {
      if (!isPlaced && !isShrinking) {
        updateMousePosition(event);
      }
    };

    const handleClick = (event) => {
      if (!isCurrent) return
      const rect = app.view.getBoundingClientRect();
      if (
        event.clientX >= rect.left &&
        event.clientX <= rect.right &&
        event.clientY >= rect.top &&
        event.clientY <= rect.bottom
      ) {
        if (!isPlaced && !isShrinking) {
          setIsPlaced(true);
          timeoutRef.current = setTimeout(() => {
            setIsPlaced(false);
          }, 5000); // 5 seconds
        }
      }
    };
    
    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('click', handleClick);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('click', handleClick);
    };
    // eslint-disable-next-line
  }, [isPlaced, isCurrent, isShrinking, app.view, setIsPlaced]);

  useEffect(() => {
    const prevIsPlaced = prevIsPlacedRef.current;
    if (prevIsPlaced && !isPlaced) {
      clearTimeout(timeoutRef.current);

      // Commencer l'animation de rétrécissement
      setIsShrinking(true);
      const startTime = performance.now();
      const duration = 2000; // 2 secondes

      const animateShrink = (currentTime) => {
        const elapsed = currentTime - startTime;
        const progress = Math.min(elapsed / duration, 1);
        if (spriteRef.current && spriteRef.current.scale.x > 0) {
          spriteRef.current.scale.set(0.1 - progress); // Rétrécir le filet
        }

        if (progress < 1) {
          requestAnimationFrame(animateShrink);
        } else {
          setIsShrinking(false); // Animation terminée
          spriteRef.current.scale.set(0.1); // Réinitialiser l'échelle
        }
      };

      requestAnimationFrame(animateShrink);
    }

    prevIsPlacedRef.current = isPlaced;
  }, [isPlaced]);

  return (
    <Container>
      <Sprite
        ref={spriteRef}
        texture={PIXI.Texture.from(netSvg)}
        anchor={0.5}
        x={x}
        y={y}
        width={50}
        height={50}
        interactive={false}
      />
    </Container>
  );
};

export default FishNetElement;
