import React, { useState, useEffect } from 'react';
import MotionBox from '../MotionBox/MotionBox';
import useSocket from '../../hooks/useSocket';
import { GridItem, Progress } from '@chakra-ui/react';

function TimerPopUpContainer() {
  const socket = useSocket();
  const [timer, setTimer] = useState(null);
  const [timeMax, setTimeMax] = useState(null);

  useEffect(() => {
    if (!socket) return;
    socket.on('timer', ({ time, timeMax }) => {
      setTimer(time);
      setTimeMax(timeMax);
    });
  }, [socket]);

  return timer ? <TimerPopUp max={timeMax} value={timer} /> : null;
}

function TimerPopUp({ max, value }) {
  return (
    <MotionBox
      // key={value}
      initial={{ opacity: 0 }}
      animate={{ opacity: 0.8 }}
      style={{ pointerEvents: 'none' }}
      transition={{
        ease: 'linear',
        duration: 1,
      }}
      position="absolute"
      marginLeft="auto"
      marginRight="auto"
      left="0"
      right="0"
      bottom="0"
      textAlign="center"
      top="0"
      zIndex={3}
      pointerEvents="none"
      fontSize={{ base: '5xl', md: '9xl' }}
      w="100%"
      h="20%"
    >
      <GridItem colSpan={4} w="100%" justifySelf="center" alignSelf="center">
        <Progress colorScheme="blue" size="lg" value={(value / max) * 100} />
      </GridItem>
    </MotionBox>
  );
}

export default TimerPopUpContainer;
