import React from 'react';
import { Box, Flex } from '@chakra-ui/react';

function Form({ children, ...props }) {
  return (
    <Box as="form" w={{ base: '90%', md: '30%' }} {...props}>
      <Flex justify="center">
        <Flex w="100%" direction="column" align="center">
          {children}
        </Flex>
      </Flex>
    </Box>
  );
}

export default Form;
