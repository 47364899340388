export function noDoublonPlayers(players) {
  if (!players) return;
  if (!players.length) return players;

  const uniquePlayers = Array.from(new Set(players.map(item => item.id))).map(
    id => players.find(item => item.id === id)
  );

  return uniquePlayers;
}
