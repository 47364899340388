import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import { Box, HStack, Spinner, Text } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import Button from 'components/Button';
import { updateUser } from "myFirebase/user";
import { updateUserState } from "redux/features/userSlice";
import { useState } from "react";
import { Link as ChakraLink } from '@chakra-ui/react';

const Checkout = () => {
  const [{ isPending },] = usePayPalScriptReducer();
  const [errorText, setErrorText] = useState()
  const { user } = useSelector(state => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const onPremium = (updateObj) => {
    dispatch(updateUserState(updateObj));
  }

  const onError = (err, user, data) => {
    setErrorText(`${user?.email}, ref : ${user?.ref}, order: ${data?.orderID}`)
  }

  const onCreateOrder = (data, actions) => {
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            value: process.env.REACT_APP_PREMIUM_PRICE,
          },
        },
      ],
    });
  }

  const onApproveOrder = (data, actions) => {
    return actions.order.capture().then((details) => {
      const updateObj = { premium: true };
      updateUser(user.ref, updateObj, onPremium, (err) => onError(err, user, data))
    });
  }

  if (errorText) return (
    <Box bg="white" w="100%" fontSize="2xl">
      <Text fontWeight="bold" fontSize="4xl" color="red">Il y a eu une erreur lors de votre paiement</Text>
      <Text>
        Contactez moi sur le{" "}
        <ChakraLink color="vegetation" href="https://discord.gg/TvDCnEU5gf">
          discord
        </ChakraLink>{' '}
      </Text>
      <Text>
        Ou par mail à l'adresse{" "}
        <Box as="span" fontWeight="bold">
          jimmy.soussan@gmail.com
        </Box>{' '}
      </Text>
      <Text>Et envoyez moi les infos suivantes :</Text>
      <Text fontSize="xl">{errorText}</Text>
    </Box>
  )

  if (!user || !process.env.REACT_APP_PREMIUM_PRICE) return (
    <Button
      mb="100px"
      color="white"
      bg="sea"
      onClick={() => navigate(`/signup?redirect=${window.location}`)}
    >
      Créer un compte
    </Button>
  )

  if (user && user.premium) return (
    <Text w="90vw" fontSize="3xl" bg="sea" color="white">Vous êtes déjà membre, merci de votre confiance</Text>
  )

  return (
    <Box>
      <HStack borderTopRadius="lg" justify="center" bg="sea"><Text fontWeight="bold" fontSize="3xl" color="white" px="2">Payer une fois {process.env.REACT_APP_PREMIUM_PRICE}€ pour devenir membre premium</Text></HStack>
      <Box bg="white" p="5">
        {isPending ? <Spinner /> : (
          <>
            <PayPalButtons
              style={{ layout: "vertical" }}
              createOrder={(data, actions) => onCreateOrder(data, actions)}
              onApprove={(data, actions) => onApproveOrder(data, actions)}
            />
          </>
        )}
      </Box>
    </Box>
  );
}

export default Checkout;