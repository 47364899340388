import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import useSocket from '../../hooks/useSocket';

import { GridItem, Grid, HStack, VStack, Flex, Text } from '@chakra-ui/react';
import GameHelper from '../../components/GameHelper/GameHelper';
import { TEAMS } from 'constants';
import TeamIcon from 'components/Teams/TeamIcon';
import Player from 'components/Player';
import Icon from 'components/Icon';
import Avatar from 'components/Avatar';

const gridW = 4;
const gridH = 5;

function BlindMaze() {
  const [myTeamCaseArray, setMyTeamCaseArray] = useState([]);
  const [otherTeamCaseArray, setOtherTeamCaseArray] = useState([]);
  const [myTeamAimArray, setMyTeamAimArray] = useState([]);
  const [otherTeamAimArray, setOtherTeamAimArray] = useState([]);
  const [gameStart, setGameStart] = useState(false);
  const [playerTurn, setPlayerTurn] = useState(null);

  const { me, myTeam, teams, players } = useSelector(state => state.game);

  useEffect(() => {
    const player = players.find(
      p => p.canMove && p.alive && p.canPlay && p.canMove
    );
    setPlayerTurn(player);
  }, [players, gameStart]);

  useEffect(() => {
    // if we can move again after being placed it means we can shoot
    if (me.canMove && me.x > -1) {
      setGameStart(true);
    } else {
      setGameStart(false);
    }
  }, [me]);

  const newCases = () => {
    let tmpMyTeamCaseArray = [];
    let tmpOtherTeamCaseArray = [];

    let tmpMyTeamAimArray = [];
    let tmpOtherTeamAimArray = [];

    const otherTeam =
      me.team === TEAMS.RED ? teams[TEAMS.BLUE] : teams[TEAMS.RED];

    for (let column = 0; column < gridW * gridH; column++) {
      if (!myTeam) return
      const myTeamPlayer = myTeam.players.find(p => p.x === column);
      tmpMyTeamCaseArray.push(myTeamPlayer);
      const otherTeamPlayer = otherTeam.players.find(p => p.x === column);
      tmpOtherTeamCaseArray.push(otherTeamPlayer);

      const myTeamPlayerAim = myTeam.players.find(p => p.y === column);
      tmpMyTeamAimArray.push(myTeamPlayerAim);
      const otherTeamPlayerAim = otherTeam.players.find(p => p.y === column);
      tmpOtherTeamAimArray.push(otherTeamPlayerAim);
    }
    setMyTeamCaseArray(tmpMyTeamCaseArray);
    setOtherTeamCaseArray(tmpOtherTeamCaseArray);

    setMyTeamAimArray(tmpMyTeamAimArray);
    setOtherTeamAimArray(tmpOtherTeamAimArray);
  };

  useEffect(() => {
    newCases();
    // eslint-disable-next-line
  }, [teams]);
  return (
    <>
      <HStack flexWrap="wrap" justify="space-between">
        <GameHelper
          canDisplay={!gameStart && me.canMove}
          w={{ base: '40%', md: '45%' }}
          text="Positionne ton joueur sur une des cases, tu pourras jouer tant qu'il n'a pas été touché"
        >
          <Board
            gameStart={gameStart}
            caseArray={myTeamCaseArray}
            aimArray={otherTeamAimArray}
            team={me?.team}
            myTeam
          />
        </GameHelper>
        <GameHelper
          canDisplay={gameStart && me.canMove}
          w={{ base: '40%', md: '45%' }}
          text="C'est à ton tour, vise une case adverse !"
        >
          <Board
            gameStart={gameStart}
            caseArray={otherTeamCaseArray}
            aimArray={myTeamAimArray}
            team={me?.team === TEAMS.RED ? TEAMS.BLUE : TEAMS.RED}
          />
        </GameHelper>
        {playerTurn && (
          <Flex w="100%" align="center" justify="center" wrap="wrap">
            <Player
              name={playerTurn.name}
              id={playerTurn.id}
              position="unset"
              avatar={playerTurn?.avatar}
            />
            <Text color="sky" fontWeight="extrabold" fontSize="4xl">
              est en train de jouer son coup
            </Text>
          </Flex>
        )}
      </HStack>
    </>
  );
}

function Board({
  caseArray,
  aimArray,
  team,
  myTeam = false,
  gameStart = false,
}) {
  return (
    <VStack w="100%">
      <TeamIcon color={`${team}Team`} />
      
        <Text fontSize="xl" fontWeight="bold">
        {gameStart ? `${caseArray?.filter(p => p && p.x > -1).length} joueurs encore en vie` : "..."} 
        </Text>
      <Grid
        templateColumns={`repeat(${gridW}, 1fr)`}
        templateRows={`repeat(${gridH}, 1fr)`}
        h="fit-content"
        alignItems="center"
        m="2"
        w="100%"
      >
        {caseArray.map((player, index) => (
          <Case
            aimPlayer={aimArray[index]}
            num={index}
            gameStart={gameStart}
            key={index}
            player={player}
            myTeam={myTeam}
          />
        ))}
      </Grid>
    </VStack>
  );
}

function Case({
  num,
  player = null,
  aimPlayer = null,
  handleSink,
  myTeam = false,
  gameStart = false,
}) {
  const { me, gameId } = useSelector(state => state.game);
  const socket = useSocket();

  const canClick = useMemo(
    () =>
      me.canMove &&
      me.canPlay &&
      me.alive &&
      ((gameStart && !myTeam && !aimPlayer) ||
        (!gameStart && myTeam && !player)),
    [myTeam, gameStart, me, player, aimPlayer]
  );

  const handleClick = () => {
    if (!gameStart && myTeam) {
      handlePosition();
      return;
    }
    handleAim();
  };

  const handlePosition = () => {
    if (player) return;
    console.log('my team', me.team, me.id, gameId);
    console.log('click on', num);
    socket.emit('place', { gameId, myId: me.id, num });
  };
  const handleAim = () => {
    if (aimPlayer) return;
    console.log('my team', me.team, me.id, gameId);
    console.log('aim on', num);
    socket.emit('aim', { gameId, myId: me.id, num });
  };

  return (
    <GridItem
      border="1px solid black"
      textAlign="center"
      onClick={canClick ? handleClick : undefined}
      rowSpan={1}
      h="10vh"
      maxH="10vh"
      cursor={canClick ? 'pointer' : 'unset'}
      _hover={canClick ? { bg: myTeam ? 'sea' : 'black' } : undefined}
      position="relative"
    >
      {canClick ? (
        <Flex
          overflow="hidden"
          alignItems="center"
          justify="center"
          w="100%"
          h="100%"
          _hover={{ opacity: '1' }}
          opacity="0"
        >
          {myTeam && !gameStart && (
            <Player
              boxSize="60%"
              position="auto"
              name={me?.name}
              avatar={me?.avatar}
              noUsername
            />
          )}
          {!myTeam && gameStart && (
            <Icon
              w="100%"
              h="100%"
              color="white"
              icon="game-icons:cannon-ball"
            />
          )}
        </Flex>
      ) : null}
      <Flex
        position="absolute"
        overflow="hidden"
        alignItems="center"
        justify="center"
        w="100%"
        h="100%"
      >
        {player && myTeam && (
          <Player
            boxSize="60%"
            position="auto"
            name={player?.name}
            avatar={player?.avatar}
            noUsername
          />
        )}
      </Flex>
      <Flex position="relative" overflow="hidden" h="100%" w="100%">
        {aimPlayer && (
          <>
            <Avatar
              filter="contrast(0%)"
              position="absolute"
              top="0"
              right="0"
              forceAvatar={aimPlayer?.avatar}
              name={aimPlayer.name}
              key={aimPlayer.id}
              boxSize={10}
            />
            <Icon
              w="100%"
              h="100%"
              color={myTeam ? 'red' : 'vegetation'}
              icon="game-icons:cannon-ball"
            />
          </>
        )}
      </Flex>
    </GridItem>
  );
}

export default BlindMaze;
