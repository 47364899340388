import React from 'react';
import Team from './Team';
import { TEAMS } from '../../constants/index';
import { Flex } from '@chakra-ui/react';

function Teams({ teams }) {
  return (
    <Flex direction="column">
      {teams[TEAMS.RED] && (
        <Team name={TEAMS.RED} team={teams[TEAMS.RED]} color="redTeam" />
      )}
      {teams[TEAMS.BLUE] && (
        <Team
          name={TEAMS.BLUE}
          team={teams[TEAMS.BLUE]}
          facingRight
          color="blueTeam"
        />
      )}
    </Flex>
  );
}

export default Teams;
