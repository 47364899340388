import { useState, useRef, useEffect } from 'react';
import {
  Box,
  Input,
  InputRightElement,
  InputGroup,
  Flex,
  Text,
  VStack,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import Button from '../Button';
import Icon from '../Icon';
import Avatar from '../Avatar';
import { useSelector } from 'react-redux';
import useSocket from '../../hooks/useSocket';

const MotionBox = motion(Box);

function Chat({ text = 'Discussion', canWrite=true, ...props }) {
  const socket = useSocket();
  const { me, myId, gameId, players } = useSelector(state => state.game);
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [unread, setUnread] = useState(0);
  const messagesEndRef = useRef(null);

  useEffect(() => {
    if (!socket) return;
    socket.on('receivingChat', message => {
      if (message.team !== me.team) return;
      console.log('receiving msg', message);
      setMessages(msgs => [...msgs, message]);
      if (!isOpen) {
        setUnread(prev => prev + 1);
      }
    });
    return () => {
      socket.off('receivingChat');
    };
    // eslint-disable-next-line
  }, [socket, me.team]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const toggleChat = () => {
    setUnread(0);
    setIsOpen(!isOpen);
  };

  const handleCampMessage = e => {
    e.preventDefault();
    if (!message) return;
    console.log('message', message);
    socket.emit('sendChat', {
      from: myId,
      team: me.team,
      gameId,
      message,
    });
    setMessage('');
  };

  return (
    <>
      <MotionBox
        position="fixed"
        bottom="20px"
        right="20px"
        p={4}
        shadow="md"
        borderRadius="md"
        h="70vh"
        w={{ base: '80vw', md: '40vw' }}
        zIndex={3}
        initial={{ opacity: 0, y: 0 }}
        animate={{ opacity: isOpen ? 1 : 0, y: isOpen ? 0 : 80 }}
        transition={{ duration: 0.3 }}
        pointerEvents={isOpen ? 'auto' : 'none'}
        bg="sea"
      >
        <Icon
          boxSize="8"
          cursor="pointer"
          onClick={toggleChat}
          position="absolute"
          top={2}
          right={2}
          icon={isOpen ? 'ion:caret-down-sharp' : 'ion:caret-up-sharp'}
        />
        <VStack
          mt="4"
          bg="sky"
          p="4"
          borderRadius="2px"
          border="1px solid black"
          mb="2"
          h="80%"
          overflowY="scroll"
        >
          {messages.map((msg, index) => (
            <Flex
              w="100%"
              key={index}
              mb={2}
              alignItems="center"
              justifyContent={msg.from === myId ? 'flex-end' : 'flex-start'}
            >
              {msg.from !== myId && (
                <Avatar
                  name={players.find(p => p.id === msg.from)?.name}
                  forceAvatar={players.find(p => p.id === msg.from)?.avatar}
                  size="sm"
                  boxSize={10}
                  mr={2}
                />
              )}
              <Box
                p={2}
                borderRadius="md"
                bg={msg.auto ? 'gold' : 'white'}
                color="black"
              >
                <Text>{msg.message}</Text>
              </Box>
              {msg.from === myId && (
                <Avatar
                  name={me?.name}
                  forceAvatar={me.avatar}
                  boxSize={10}
                  size="sm"
                  ml={2}
                />
              )}
            </Flex>
          ))}
          <div ref={messagesEndRef} />
        </VStack>

        <form style={{ width: '100%' }} onSubmit={handleCampMessage}>
          <InputGroup>
            <Input
              autoFocus
              placeholder="Salut l'équipe !"
              onChange={e => {
                setMessage(e.target.value);
              }}
              value={message}
              maxLength={150}
              bg="white"
              isDisabled={!canWrite}
            />
            <InputRightElement>
              <Button
                minW="fit-content"
                w="fit-content"
                fontSize="sm"
                bg="none"
                type="submit"
                p="0"
                m="0"
              >
                <Icon boxSize="7" icon="tabler:send" />
              </Button>
            </InputRightElement>
          </InputGroup>
        </form>
      </MotionBox>
      <Button
        bg="sand"
        color="white"
        rightIcon={<Icon icon="basil:chat-outline" />}
        onClick={toggleChat}
        fontSize="lg"
        p="2"
        zIndex="2"
        {...props}
      >
        {unread ? `(${unread})` : ''}
        {text}
      </Button>
    </>
  );
}

export default Chat;
