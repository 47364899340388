import { Image } from '@chakra-ui/react';
import MotionBox from 'components/MotionBox';
import { ITEMS } from 'constants/items';
import usePlayerItems from 'hooks/usePlayerItems';
import React, { useEffect, useState, useRef, useCallback } from 'react';
import { createPortal } from 'react-dom';

const AnimatedItem = () => {
  const [currentAnimation, setCurrentAnimation] = useState(null);
  const [animationQueue, setAnimationQueue] = useState([]);
  const { items } = usePlayerItems();
  const prevItemsRef = useRef({});

  useEffect(() => {
    if (!items) return
    const prevItems = prevItemsRef.current;
    const newAnimations = [];

    for (const key in items) {
      console.log("key in items", key, items);
      if (items[key] > (prevItems[key] || 0)) {
        newAnimations.push({
          key,
          image: ITEMS[key]?.image,
        });
      }
    }

    if (newAnimations.length > 0) {
      setAnimationQueue(prev => [...prev, ...newAnimations]);
    }

    prevItemsRef.current = items;
  }, [items]);

  const handleAnimationComplete = useCallback(() => {
    setAnimationQueue(prev => {
      const [, ...rest] = prev;
      return rest;
    });
    setCurrentAnimation(null);
  }, []);

  useEffect(() => {
    if (animationQueue.length > 0 && !currentAnimation) {
      setCurrentAnimation(animationQueue[0]);
    }
  }, [animationQueue, currentAnimation]);

  if (!currentAnimation) return null;
  if (!items) return
  return createPortal(
    <MotionBox
      initial={{ opacity: 1, scale: 1 }}
      animate={{ opacity: [0.8, 0.5, 0], scale: 0.5, y: '-10vh' }}
      exit={{ opacity: 0 }}
      transition={{ duration: 2 }}
      style={{ position: 'fixed', top: '10%', left: '90%', transform: 'translate(-50%, -50%)', pointerEvents:"none" }}
      onAnimationComplete={handleAnimationComplete}
    >
      {!!currentAnimation && currentAnimation.image && <Image pointerEvents="none" src={currentAnimation?.image} alt="animated item" style={{ width: '100px', height: '100px' }} />}
    </MotionBox>,
    document.body
  );
};

export default AnimatedItem;