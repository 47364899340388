import React, { useEffect, useState, useMemo } from 'react';
import Canvas from '../components/Canvas/Canvas';

import {
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Switch,
  useDisclosure,
  VStack,
  Text,
  Box,
} from '@chakra-ui/react';
import { CopyIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';

import Button from '../components/Button/Button';
import Title from '../components/Title/Title';

import { useSelector } from 'react-redux';
import useSuccess from '../hooks/useSuccess';
import useSocket from '../hooks/useSocket';
import useQuit from 'hooks/useQuit';
import BackButton from '../components/BackButton';
import Teams from '../components/Teams';
import CreateTeamsModal from '../components/Modal/CreateTeamsModal';
import UpdateBotsModal from 'components/Modal/UpdateBotsModal';
import Icon from '../components/Icon';
import Option from '../components/Option';
import IncrementalOption from "components/Option/IncrementalOption"
import Player from 'components/Player';
import { noDoublonPlayers } from 'utils/noDoublonPlayers';
import { TEAMS, TYPE_GAMES } from 'constants';
import Menu from 'components/Menu/Menu';
import GameHelper from 'components/GameHelper';
import MoreOptionsMenu from "components/Menu/MoreOptionsMenu"

const Lobby = () => {
  const { players, gameId, myId, me, teams, options, isStart } = useSelector(
    state => state.game
  );
  const { user } = useSelector(state => state.user);
  const [canStart, setCanStart] = useState(false);
  const [onSuccess] = useSuccess();
  const navigate = useNavigate();

  const handleQuit = useQuit(`/join/${gameId}`);
  const handleQuitForever = useQuit();

  const socket = useSocket();

  const { isOpen, onClose, onOpen } = useDisclosure();
  const { isOpen: isOpenUpdateBots, onClose: onCloseUpdateBots, onOpen: onOpenUpdateBots } = useDisclosure();

  const gameOptions = useMemo(() => [{ label: "Épreuves en équipe", value: "TEAM", values: options?.selectedGames?.TEAM, options: TYPE_GAMES?.TEAM?.map(g => ({ label: g, value: g })) }, { value: "SOLO", label: "Épreuves en solo", values: options?.selectedGames?.SOLO, options: TYPE_GAMES?.SOLO?.map(g => ({ label: g, value: g })) }], [options?.selectedGames])

  const openUpgradePageTab = () => {
    window.open('/upgrade', '_blank');
  };


  const getInvitationLink = () => {
    const url = `${window.location.origin}/join/${gameId}`;
    onSuccess(`Invitation link copied to clipboard: ${url}`);
    navigator.clipboard.writeText(url);
    return url;
  };

  const handleStartGame = () => {
    socket.emit('startGame', gameId);
    navigate('/start');
  };

  const handleMakeTeams = () => {
    socket.emit('makeTeams', gameId);
  };

  const handleBecomeSpectator = () => {
    if (me.spectator) return
    socket.emit('spectate', {gameId, myId});
  };

  const handleShuffleTeams = () => {
    socket.emit('shuffleTeams', gameId);
  };

  const handleOptions = e => {
    socket.emit('options', gameId, { [e.target.name]: e.target.checked });
  };

  const handleIncrementalOption = options => {
    console.log("incremental", options)
    socket.emit('options', gameId, options);
  };

  const handleGameOptions = (values, gameType) => {
    console.log("gameoptions", gameType, values);
    if (!values || values.length ===0) return
    socket.emit('options', gameId, { selectedGames: { ...options.selectedGames, [gameType]: values } });
  }

  useEffect(() => {
    // eslint-disable-next-line
    if (
      players.length == options?.maxPlayers &&
      teams.hasOwnProperty(TEAMS.RED)
    ) {
      setCanStart(true);
    } else {
      setCanStart(false);
    }
  }, [players, options?.maxPlayers, teams]);

  // save game for disconnect people
  useEffect(() => {
    localStorage.setItem('lastGameId', gameId);
    localStorage.setItem('lastMyId', myId);
  }, [gameId, myId]);

  useEffect(() => {
    // this is for reconnection
    if (isStart) {
      navigate('/start');
    }
  }, [isStart, navigate]);

  return (
    <>
      <Title position="absolute" top="0" w="100%">
        Lobby
        <br />
        {players.length} / {options.maxPlayers || 10}
      </Title>
      <Canvas h="95%">
        {Object.keys(teams).length === 0 && (
          <Box h="50%" my="14" py="10" position="relative">
            <Flex>
              {noDoublonPlayers(players).map((player, index) => (
                <Player
                  key={player.id}
                  name={player.name}
                  canDelete={me?.host}
                  id={player.id}
                  position="relative"
                  avatar={player?.avatar}
                  title={player?.title}
                />
              ))}
            </Flex>
          </Box>
        )}
        {!(Object.keys(teams).length === 0) && <Teams teams={teams} />}
        <Flex
          position="absolute"
          bottom="0"
          w="100%"
          direction="column"
          align="center"
          justify="center"
        >
          {me?.host && (
            // only if you are the host
            <>
              {canStart ? (
                <>
                  <Flex zIndex={1} align="center" flexWrap="wrap" justify="center">
                    <Button
                      rightIcon={<Icon icon="lucide:shuffle" />}
                      color="white"
                      bg="vegetation"
                      onClick={handleShuffleTeams}
                    >
                      Mélanger les équipes
                    </Button>
                    <Button
                      rightIcon={<Icon icon="openmoji:goldfish" />}
                      color="white"
                      bg="sea"
                      onClick={user?.premium ? onOpen : openUpgradePageTab}
                    >
                      Changer les équipes
                    </Button>
                    <MoreOptionsMenu options={[{label:"Modifier les PNJ", action: onOpenUpdateBots, icon: "uil:edit"}]} />
                  </Flex>
                  <Flex zIndex={1}>
                  <Button onClick={handleStartGame}>Commencer</Button>
                  </Flex>
                </>
              ) : (
                <>
                  <VStack zIndex={1} spacing={0} m="3" textAlign="center">
                    <VStack bg="gold" m="1" p="2" border="1px solid black"  filter="drop-shadow(-21.6355px 37.5774px 2.27742px rgba(0, 0, 0, 0.25));">
                      <Flex p="3" borderBottom="1px solid black" alignItems="center" justify="center">
                        <Text fontSize={{base: "sm", md:"2xl"}} fontWeight="bold">Options reservées aux membres <Box color="vegetation" cursor="pointer" _hover={{textDecoration: "underline"}} as="span" onClick={openUpgradePageTab}>premium</Box></Text>
                        <Icon p="1" boxSize="10" icon="openmoji:goldfish" />
                      </Flex>
                      <HStack flexWrap="wrap" justify="center" maxW="100%" spacing={5}>
                        <VStack>
                          <Text fontSize="md" fontWeight="bold">
                            Épreuves
                          </Text>
                        <Menu title="Choisir vos épreuves" onChange={handleGameOptions} options={gameOptions} my="2" />
                        </VStack>
                        <IncrementalOption title="Nombre de joueurs" defaultValue={10} min={8} max={16} step={2} optionKey="maxPlayers" onChange={handleIncrementalOption}/>
                        <IncrementalOption title="Temps sur le camps (en secondes)" defaultValue={process.env.REACT_APP_CAMP_TIME || 100} min={25} max={300} step={25} optionKey="campTime" onChange={handleIncrementalOption} />
                        <IncrementalOption title="Nombre de bracelets par camps" defaultValue={2} min={1} max={5} step={1} optionKey="nbBracelets" onChange={handleIncrementalOption} />
                      </HStack>
                    </VStack>
                    <GameHelper autoFocus text={user?.premium ? "Choissiez vos options puis cliquez ici pour commencer" : "Cliquez ici pour lancer la partie"}>
                      <Button mt="5" mb="0" w="100%" maxW="300px" onClick={handleMakeTeams}>Faire les équipes</Button>
                    </GameHelper>
                  </VStack>
                </>
              )}
            </>
          )}
          
          {/* displaying options to non host players */}
          {!me?.host && !canStart && (
            <VStack fontSize="lg" bg="gold" m="1" p="5" border="1px solid black"  filter="drop-shadow(-21.6355px 37.5774px 2.27742px rgba(0, 0, 0, 0.25));">
              <Text fontWeight="bold" fontSize="2xl">Options selectionnées par l'hôte</Text>
            <Text>{options?.campTime} secondes sur le camps</Text>
            <Text>{options?.maxPlayers} joueurs</Text>
            <Text>{options?.nbBracelets} bracelets par camps</Text>
            </VStack>
          )}

          {canStart ? null : (
            <HStack>
            <Button
              onClick={getInvitationLink}
              bg="sky"
              color="white"
              rightIcon={<CopyIcon />}
            >
              Copier le lien d'invitation
            </Button>
            <Button
              onClick={handleBecomeSpectator}
              bg="white"
              color="sea"
              rightIcon={<Icon icon="mdi:eye" />}
              isDisabled={me?.spectator}
            >
              Devenir spectateur
            </Button>
            </HStack>
          )}
          <FormControl
            justifyContent="center"
            display="flex"
            alignItems="center"
          >
            <FormLabel
              fontWeight="bold"
              fontSize="lg"
              htmlFor="showTuto"
              mb="0"
            >
              Afficher les tutoriels
            </FormLabel>
            <Switch
              onChange={handleOptions}
              isReadOnly={!me?.host}
              isChecked={options.showTuto}
              name="showTuto"
              id="showTuto"
            />
          </FormControl>
          <FormControl
            justifyContent="center"
            display="flex"
            alignItems="center"
          >
            <FormLabel
              fontWeight="bold"
              fontSize="lg"
              htmlFor="isPublic"
              mb="0"
            >
              Rendre la partie publique
            </FormLabel>
            <Switch
              onChange={handleOptions}
              isReadOnly={!me?.host}
              isChecked={options.isPublic}
              name="isPublic"
              id="isPublic"
            />
          </FormControl>
        </Flex>
      </Canvas>
      <VStack position="absolute" top="1%" left="1%" align="start">
        <BackButton color="red" position="static" onClick={handleQuitForever}>
          Quitter la partie
        </BackButton>
        {gameId !== myId && !canStart && (
          <BackButton
            position="static"
            to={`/join/${gameId}`}
            onClick={handleQuit}
          >
            Faire des changements avant de rejoindre
          </BackButton>
        )}
      </VStack>
      <CreateTeamsModal isOpen={isOpen} onClose={onClose} />
      <UpdateBotsModal isOpen={isOpenUpdateBots} onClose={onCloseUpdateBots}/>
      <Option />
    </>
  );
};

export default Lobby;
