import React, { useRef, useEffect } from 'react';
import { Container, useApp } from '@pixi/react';
import usePixi from 'hooks/usePixi';

const WalkingAnimation = ({ children, startX = null, startY = null, endX = null, endY = null }) => {
  const { width, height } = usePixi();
  const containerRef = useRef();
  const app = useApp();

  useEffect(() => {
    const container = containerRef.current;
    const defaultStartX = startX !== null ? startX : width - width/3;
    const defaultStartY = startY !== null ? startY : height / 3;
    const defaultEndX = endX !== null ? endX : width / 2;
    const defaultEndY = endY !== null ? endY : height / 3;

    container.x = defaultStartX;
    container.y = defaultStartY;

    const speed = 2;
    const jumpHeight = 5;
    let jumpDirection = 1;

    const animate = (delta) => {
      if (container.x > defaultEndX) {
        container.x -= speed * delta;
      } else {
        container.x = defaultEndX;
      }

      if (container.y >= defaultEndY) {
        jumpDirection = -1;
      } else if (container.y <= defaultEndY - jumpHeight) {
        jumpDirection = 1;
      }
      container.y += jumpHeight * jumpDirection * delta * 0.1;
    };

    app.ticker.add(animate);

    return () => {
      app.ticker.remove(animate);
    };
  }, [app, width, height, startX, startY, endX, endY]);

  return (
    <Container ref={containerRef}>
      {children}
    </Container>
  );
};

export default WalkingAnimation;
