import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useSocket from '../../hooks/useSocket';
import PixiCanvas from '../../components/PixiCanvas';
import PlayerPixi from '../../components/PixiCanvas/PlayerPixi';

import { Box, Flex } from '@chakra-ui/react';
import { getPlayerCoords } from '../getPlayerCoords';
import ElementPixi from '../../components/PixiCanvas/ElementPixi';
import IconButton from '../../components/Button/IconButton';
import GameHelper from '../../components/GameHelper/GameHelper';
import { TEAMS } from 'constants';
import InstructionPopUpContainer from 'components/PopUp/InstructionPopUp';
import usePixi from 'hooks/usePixi';

const CANVA_WIDTH = 100;
const CANVA_HEIGHT = 50;
const SIZE = 50;

function BlindRun() {
  const {width, height} = usePixi()
  const [canClick, setCanClick] = useState(true);

  const { me, players, gameId, myId, obstacles } = useSelector(
    state => state.game
  );
  const socket = useSocket();

  const handleDirection = ({ xDiff, yDiff }) => {
    if (!me.blind) {
      handleInstruction({ xDiff, yDiff });
    }
    handleMove({ xDiff, yDiff });
  };

  const handleMove = ({ xDiff, yDiff }) => {
    socket.emit('move', { gameId, myId, xDiff, yDiff });
  };

  const handleInstruction = ({ xDiff, yDiff }) => {
    socket.emit('instruction', { gameId, myId, xDiff, yDiff });
    setCanClick(false);
    setTimeout(() => {
      console.log('can click');
      setCanClick(true);
    }, 1000);
  };

  return (
    <>
      {me.blind ? (
        <Box h="100%" w="100%" bg="black" position="absolute" />
      ) : (
        <PixiCanvas border>
          {players
            .filter(p => p.alive && p.blind && p.canMove)
            .map(player => (
              <PlayerPixi
                usernameColor={
                  player.team === TEAMS.RED ? '#F24E1E' : '#0075FF'
                }
                blind
                size={SIZE}
                key={player.id}
                opacity={0.5}
                name={player.name}
                facingRight
                coords={getPlayerCoords(
                  player.x,
                  player.y,
                  CANVA_WIDTH,
                  CANVA_HEIGHT,
                  width,
                  height,
                )}
                avatar={player?.avatar}
                title={player?.title}
              />
            ))}
          <Flag flag={obstacles?.[0]} />
        </PixiCanvas>
      )}

      <GameHelper
        h={me.blind ? '100%' : 'auto'}
        text={
          me.blind
            ? 'Bouge ton personnage aveuglé en suivant les instructions de ton équipe'
            : 'Indique à la personne au yeux bandés par où aller'
        }
      >
        <Flex
          h={me.blind ? '100%' : 'auto'}
          w="100%"
          maxW="100vw"
          justify="center"
          align="center"
        >
          <IconButton
            isDisabled={!canClick || !me.canPlay || !me.alive || !me.canMove}
            onClick={() => handleDirection({ xDiff: -1 })}
            minW="20%"
            w="20%"
            icon="icon-park-solid:left-two"
          />
          <Flex direction="column">
            <IconButton
              isDisabled={!canClick || !me.canPlay || !me.alive || !me.canMove}
              onClick={() => handleDirection({ yDiff: 1 })}
              w="20%"
              icon="icon-park-solid:up-two"
            />
            <IconButton
              isDisabled={!canClick || !me.canPlay || !me.alive || !me.canMove}
              onClick={() => handleDirection({ yDiff: -1 })}
              w="20%"
              icon="icon-park-solid:down-two"
            />
          </Flex>
          <IconButton
            isDisabled={!canClick || !me.canPlay || !me.alive || !me.canMove}
            onClick={() => handleDirection({ xDiff: 1 })}
            minW="20%"
            w="20%"
            icon="icon-park-solid:right-two"
          />
        </Flex>
      </GameHelper>
      <InstructionPopUpContainer />
    </>
  );
}

function Flag({ flag }) {
  const {width, height} = usePixi()
  const [flagCoords, setFlagCoords] = useState(flag);
  useEffect(() => {
    if (!flag) return;
    setFlagCoords(
      getPlayerCoords(
        flag.x,
        flag.y,
        CANVA_WIDTH,
        CANVA_HEIGHT,
        width,
        height,
      )
    );
  }, [flag, width, height]);

  if (!flag) return;
  return (
    <ElementPixi
      size={SIZE}
      x={flagCoords.x}
      y={flagCoords.y}
      image={'/images/flag.png'}
    />
  );
}

export default BlindRun;
