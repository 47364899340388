import {Fragment, useState} from "react"
import { MenuButton, MenuList, MenuOptionGroup, MenuItemOption, MenuDivider, Menu as ChakraMenu, Button} from '@chakra-ui/react';
// import Icon from "components/Icon";
import { useSelector } from "react-redux";

// interface Option {
//   value: string;
//   label: string;
// }

// interface OptionGroup {
//   label: string;
//   value: string
//   value: string[];
//   options: Option[];
// }

// interface Props {
//   options: OptionGroup[];
//   onChange: (values: string[], groupTitle: string) => void;
// }

const Menu = ({title="Menu", options, onChange, ...props }) => {
  const { user } = useSelector(state => state.user);
  const [isOpen, setIsOpen] = useState(false)
  const onOpen = () => {
    if (user?.premium) {
      setIsOpen(true)
      return
    }
    console.log("not premium");
    window.open('/upgrade', '_blank');
  };
  return (
    <ChakraMenu onOpen={onOpen} isOpen={isOpen} onClose={() => setIsOpen(false)} closeOnSelect={false} placement='top' zIndex={3}>
      <MenuButton fontSize="md" as={Button} bg="sea" color="white" {...props}>
        {title}
      </MenuButton>
      <MenuList fontSize="xs" display={isOpen ? "block" :"none"} minWidth='240px' maxH="50vh" overflow="scroll" zIndex="3">
        {options.map((optionGroup, index) => (
          <Fragment key={index}>
            <MenuOptionGroup title={optionGroup.label} type='checkbox' value={optionGroup.values} defaultValue={optionGroup.values} onChange={(values) => onChange(values, optionGroup.value)}>
              {optionGroup.options.map((option, optionIndex) => (
                <MenuItemOption key={optionIndex} value={option.value}>
                  {option.label}
                </MenuItemOption>
              ))}
            </MenuOptionGroup>
            {index < options.length - 1 && <MenuDivider />}
          </Fragment>
        ))}
      </MenuList>
    </ChakraMenu>
  );
};

export default Menu;