import React, { useEffect, useState } from 'react';
import { Image } from '@chakra-ui/react';
import { animate } from 'motion';

function Avatar({ name = '', forceAvatar, variant, ...props }) {
  console.log('variant', variant);
  const [animation, setAnimation] = useState(null);
  useEffect(() => {
    setAnimation(animate('#avatar', { rotate: 360 }));
  }, []);
  useEffect(() => {
    if (animation) {
      animation.cancel();
      animation.play();
    }
  }, [name, animation]);

  return (
    <Image
      id="avatar"
      boxSize="200px"
      src={
        forceAvatar
          ? forceAvatar
          : `https://api.dicebear.com/7.x/adventurer/svg?seed=${name?.replaceAll(
              '?',
              ''
            )}&${variant}`
      }
      {...props}
    />
  );
}

export default Avatar;
