import * as React from 'react';
import { ReceivingModal } from './ReceivingModal';

export default {
  component: ReceivingModal,
  // argTypes: {
  //   from: {
  //     // type: { name: 'string', required: false },
  //     //     defaultValue: 'Hello',

  //     control: { type: 'text' },
  //   },
  // },
};

export const ReceivingFishes = () => {
  return (
    <ReceivingModal
      isOpen
      onClose={() => {}}
      from={'marc'}
      message="Cadeau pour mon poto"
      fishes={22}
    />
  );
};
