import {useMemo} from "react"
import { HStack, Text } from "@chakra-ui/react"
import Icon from "components/Icon"
import { useSelector } from 'react-redux';


const SpectatorCount = () => {

  const { spectators } = useSelector(state => state.game);

  const count = useMemo(() => spectators.filter(s => !s.disconnected).length || 0 , [spectators])


  return (
    <HStack
      position="absolute"
      bottom={2}
      left={"12%"}
      zIndex={2}
      color="sea"
      opacity ={count ? 1 : 0.2}
    >
      <Icon icon="mdi:eye" />
      <Text fontSize="xl" fontWeight="bold">{count}</Text>

    </HStack>
  )
}

export default SpectatorCount