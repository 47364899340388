import React, { useState } from 'react';
import {
  Box,
  Popover,
  PopoverArrow,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
} from '@chakra-ui/react';

const GameHelper = ({ children, text, canDisplay = true, onClose, autoFocus=false, ...props }) => {
  const [isOpen, setIsOpen] = useState(true);

  const handleClose = () => {
    setIsOpen(false);
    onClose && onClose()
  };

  return (
    <>
      {isOpen && canDisplay ? (
        <Popover
          autoFocus={autoFocus}
          isOpen={isOpen}
          onClose={handleClose}
          placement="auto"
        >
          <PopoverTrigger>
            <Box
              w="100%"
              minW="fit-content"
              h="fit-content"
              position="relative"
              border="3px dashed white"
              borderRadius="lg"
              {...props}
            >
              {children}
            </Box>
          </PopoverTrigger>
          <PopoverContent border="none" bg="transparent">
            <PopoverArrow />
            <PopoverCloseButton />
            <PopoverHeader
              pt="5"
              borderRadius="lg"
              textAlign="center"
              bg="white"
              color="sky"
              fontSize="lg"
            >
              {text}
            </PopoverHeader>
          </PopoverContent>
        </Popover>
      ) : (
        <Box
          w="100%"
          minW="fit-content"
          h="fit-content"
          position="relative"
          {...props}
        >
          {children}
        </Box>
      )}
    </>
  );
};

export default GameHelper;
