import React, { useEffect, useCallback, useState, useMemo } from 'react';
import { Box, Flex, Image } from '@chakra-ui/react';
import { animate } from 'motion';
import usePlayerItems from 'hooks/usePlayerItems';
import useSound from 'hooks/useSound';
import FishingCanvas from './FishingCanvas';
import fishAnimations from './fishAnimations.json'; // Assuming this contains fish animation data
import fishSvg from "svg/Fish.svg"

import bubblesSound from 'sounds/bubbles.mp3';
import fishMusic from 'sounds/music/fish.mp3';
import { useSelector } from 'react-redux';
import { LOCATIONS } from 'constants';
import { PlayersInLocation } from '../Camp';
import { ITEM_NAME, ITEM_TYPE } from 'constants/items';
import successPercent from 'utils/successPercent';
import Tuto from 'games/Tuto';
import useLocalStorage from 'hooks/useLocalStorage';

function Fishing({ onFish }) {
  const { myTeam, isDemo } = useSelector(state => state.game);
  const [play] = useSound(bubblesSound);
  const [playMusic, { stop }] = useSound(fishMusic);
  const { updateItems, items } = usePlayerItems();
  const [currentFish, setCurrentFish] = useState(null);

  useEffect(() => {
    playMusic();
    return () => stop();
  }, [playMusic, stop]);

  const handleFishedTurtle = () => {
    // turtules give other items
    if (successPercent(80)) {
      updateItems({[ITEM_NAME.WEB]: items[ITEM_NAME.WEB] + 1}, ITEM_TYPE.COLLECTIBLES)
    }
  }

  const handleFished = useCallback((fishType) => {
    const fishData = fishAnimations[fishType];
    if (!fishData) return;
    
    if (fishType === "turtle") {
      handleFishedTurtle()
    }

    onFish ? onFish() : updateItems({ fishes: items?.fishes + fishData.value });
    play();
    setCurrentFish(fishType);

    const fishElements = Array.from({ length: fishData.value }).map((_, index) => ({
      id: `FishImageAnimation-${index}`,
      src: fishData.publicImagePath,
    }));

    fishElements.forEach((fish, index) => {
      setTimeout(() => {
        const animation = animate(
          `#${fish.id}`,
          {
            opacity: [1, 0],
            y: [-600, 300],
            x: 50 + Math.random() * (window.innerWidth - 50),
            rotate: [0, 360],
          },
          {
            duration: 2,
          }
        );

        animation.finished.then(() => {
          // console.log('finished', fish.id);
        });
      }, index * 100); // Add delay between each fish animation
    });
     // eslint-disable-next-line
  }, [items, play, onFish, updateItems,]);

  const playersInLocation = useMemo(
    () =>
      myTeam?.players?.filter(
        player => player.location === LOCATIONS.ACTIVITY.FISHING
      ),
    [myTeam]
  );

  const [displayTuto, setDisplayTuto] = useLocalStorage('fishing-tuto', true);
  if (isDemo && displayTuto)
    return (
      <Tuto
        canSkip
        timeout={4000}
        id={LOCATIONS.ACTIVITY.FISHING}
        setDisplay={setDisplayTuto}
      />
    );

  return (
    <>
      <FishingCanvas onFish={handleFished} netCount={items.net} />
      <Flex
        direction="column"
        justify="center"
        textAlign="center"
        align="center"
        w="100%"
      >
        <PlayersInLocation players={playersInLocation} />
      </Flex>
      <Box
        style={{
          width: '300px',
          pointerEvents: 'none',
          position: 'absolute',
          top: '5px',
        }}
      >
        {currentFish && Array.from({ length: fishAnimations[currentFish].value }).map((_, index) => (
          <Image
            key={index}
            id={`FishImageAnimation-${index}`}
            src={fishSvg}
            alt={`Fish ${currentFish}`}
            style={{ width: '100%', height: 'auto', position: 'absolute', opacity: 0 }} // Start with opacity 0
          />
        ))}
      </Box>
    </>
  );
}

export default Fishing;
