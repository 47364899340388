import  { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import useSocket from '../../hooks/useSocket';
import PixiCanvas from '../../components/PixiCanvas';
import PlayerPixi from '../../components/PixiCanvas/PlayerPixi';

import { Flex, useDisclosure } from '@chakra-ui/react';
import { getPlayerCoords } from '../getPlayerCoords';
import IconButton from '../../components/Button/IconButton';
import GameHelper from '../../components/GameHelper/GameHelper';
import { Sprite } from '@pixi/react';
import SelectPlayerModal from 'components/Modal/SelectPlayerModal';
import usePixi from 'hooks/usePixi';

const CANVA_WIDTH = 100;
const CANVA_HEIGHT = 50;
const SIZE = 100;

function Piouteaux() {
  const { me, players, gameId, myId, } = useSelector(
    state => state.game
  );
  const socket = useSocket();

  const {isOpen, onClose, onOpen} = useDisclosure()

  const polePlayers = useMemo(() => players.filter(p => p.alive), [players])
  const polePlayersStanding = useMemo(() => players.filter(p => p.alive && p.canMove), [players])

  useEffect(() => {
    if (polePlayersStanding.length === 1 && polePlayersStanding[0].id === myId) {
      onOpen()
    }
  }, [polePlayersStanding, myId, onOpen])

  const handleRotation = (rDiff) => {
    socket.emit('rotate', { gameId, myId, rotate: rDiff });
  };

  const handleSelectFinalist = ({to}) => {
    console.log("selected", to);
    socket.emit('finalist', {
      gameId,
      myId: to,
    });
    onClose();
  };


  return (
    <>
      <PixiCanvas>
        {polePlayersStanding.length > 1 ? polePlayers
          .map((player, index) => (
            <Pole r={player.r} x={10 + ((index) / polePlayers.length) * (CANVA_WIDTH - 5)} player={player} me={me} />
          )) :
            <Pole r={0} x={(CANVA_WIDTH / 2)} player={polePlayersStanding[0]} me={me} />
          }
      </PixiCanvas>
      <GameHelper
        text="Tu dois garder l'équilibre en aidant ton avatar à rester le plus droit possible"
      >
        <Flex
          w="100%"
          maxW="100vw"
          justify="center"
          align="center"
        >
          <IconButton
            isDisabled={!me.canPlay || !me.alive || !me.canMove || polePlayersStanding.length < 2}
            onClick={() => handleRotation(-2)}
            minW="20%"
            w="20%"
            icon="fa:rotate-left"
          />
          <IconButton
            isDisabled={!me.canPlay || !me.alive || !me.canMove || polePlayersStanding.length < 2}
            onClick={() => handleRotation(2)}
            minW="20%"
            w="20%"
            icon="fa:rotate-right"
          />
        </Flex>
      </GameHelper>

      <SelectPlayerModal forcePlayers={polePlayers} isOpen={isOpen} onValidate={handleSelectFinalist} noInput title="Choisis qui ira en final avec toi !" />
    </>
  );
}

const Pole = ({ player, me, x, r = 0 }) => {
  const {width, height} = usePixi()
  const POLE_Y = 25;

  const [y, setY] = useState(POLE_Y)
  const [startFall, setStartFall] = useState(false);
  const checkStartFall = useMemo(() => r >= 90 || r <= -90, [r]);

  // Effet qui démarre la chute lorsque startFall devient vrai
  useEffect(() => {
    if (startFall) {
      const fallInterval = setInterval(() => {
        setY(prevY => (prevY > 0 ? prevY - 1 : prevY));
      }, 10);

      return () => clearInterval(fallInterval);
    }
  }, [startFall]);

  // Démarrer la chute lorsque checkStartFall devient vrai
  useEffect(() => {
    if (checkStartFall) {
      setStartFall(true);
    }
  }, [checkStartFall]);

  const coords = useMemo(() => ({
    ...getPlayerCoords(x, y, CANVA_WIDTH, CANVA_HEIGHT, width, height), r
  }), [y, r, x, width, height])
  return <>

    <PlayerPixi
      isMe={player.name === me.name}
      blind
      size={SIZE}
      name={player.name}
      facingRight
      coords={coords}
      avatar={player?.avatar}
      title={player?.title}
    />
    <Sprite image={"/images/pole.svg"}
      anchor={{ x: 0.5, y: 0.5 }}
      x={coords.x}
      y={coords.y + SIZE}
      width={SIZE}
      height={SIZE}

    />
  </>
}

export default Piouteaux;
