import React, { useEffect, useState, useRef } from 'react';
import { AnimatedSprite } from '@pixi/react';
import * as PIXI from 'pixi.js';

const FishElement = ({ spriteImage, frameCount, initialX, initialY, animationSpeed, speed, onDisappear, onUpdate, fishType }) => {
  const [textures, setTextures] = useState([]);
  const animationRef = useRef(null);
  const [x, setX] = useState(initialX);
  const [y, setY] = useState(initialY);
  const [alpha, setAlpha] = useState(0); // Initial opacity for fade in

  useEffect(() => {
    // Load the base texture
    const baseTexture = PIXI.BaseTexture.from(spriteImage);
    const frameWidth = baseTexture.width / frameCount - 1; // Assuming the sprite sheet has 'frameCount' frames horizontally
    const frameHeight = baseTexture.height;

    const frames = [];
    for (let i = 0; i < frameCount; i++) {
      frames.push(new PIXI.Texture(baseTexture, new PIXI.Rectangle(i * frameWidth, 0, frameWidth, frameHeight)));
    }
    setTextures(frames);

    const updatePosition = () => {
      setX((prevX) => {
        const newX = prevX + speed; // Move the fish to the right
        onUpdate({ x: newX, y, fishType });
        return newX;
      });
    };

    const animate = () => {
      updatePosition();
      animationRef.current = requestAnimationFrame(animate);
    };

    animationRef.current = requestAnimationFrame(animate);

    // Fade in effect
    const fadeInInterval = setInterval(() => {
      setAlpha((prevAlpha) => {
        if (prevAlpha >= 1) {
          clearInterval(fadeInInterval);
          return 1;
        }
        return prevAlpha + 0.05;
      });
    }, 50);

    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
      clearInterval(fadeInInterval);
    };
    // eslint-disable-next-line
  }, [spriteImage, frameCount, speed, fishType, y]);

  useEffect(() => {
    // Fade out effect when fish is close to leaving the screen
    if (x > window.innerWidth - 50) {
      const fadeOutInterval = setInterval(() => {
        setAlpha((prevAlpha) => {
          if (prevAlpha <= 0) {
            clearInterval(fadeOutInterval);
            onDisappear(); // Notify the parent component to remove this fish
            return 0;
          }
          return prevAlpha - 0.05;
        });
      }, 50);
    }
  }, [x, onDisappear]);

  return (
    <>
      {textures.length > 0 && (
        <AnimatedSprite
          textures={textures}
          x={x}
          y={y}
          width={50} // Fixed size
          height={50} // Fixed size
          anchor={0.5}
          animationSpeed={animationSpeed}
          isPlaying={true}
          alpha={alpha} // Set the opacity
        />
      )}
    </>
  );
};

export default FishElement;
