import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import casinoWin from 'sounds/casino-win.mp3';
import useSound from 'hooks/useSound';
import { ReactComponent as CoinSvg } from '../../svg/coin.svg';
import MotionBox from 'components/MotionBox';
import { svgSize } from './constants';
import AnimatedNumber from 'components/ui/AnimatedNumber';

const Coin = ({ value = 0, fontSize = '2xl', ...props }) => {
  const [currentValue, setCurrentValue] = useState(value);
  const [prevValue, setPrevValue] = useState(value);
  const [reAnimate, setReAnimate] = useState(0);
  const { options } = useSelector(state => state.user);
  const [play] = useSound(casinoWin);

  useEffect(() => {
    setPrevValue(currentValue)
    if (value > currentValue) {
      play({ forceSoundEnabled: options.sound });
      setReAnimate(prev => prev + 1);
    }
    setCurrentValue(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, play]);

  return (
    <>
      <MotionBox
        key={reAnimate}
        mt="2"
        opacity={value ? '1' : '0.3'}
        direction="column"
        align="center"
        zIndex="2"
        animate={{
          scale: [1, 5, 1],
          opacity: [0, 1, 0],
          position: 'fixed',
          top: '50%',
          left: '75%',
          zIndex: '3',
          transitionEnd: {
            position: 'static',
            opacity: value ? 1 : 0.3,
          },
        }}
        transition={{ duration: 4 }}
      >
        <CoinSvg style={{ height: svgSize }} {...props} />
        <AnimatedNumber prevValue={prevValue} value={value} color="black" fontWeight="extrabold" fontSize={fontSize} />
      </MotionBox>
    </>
  );
};

export default Coin;
