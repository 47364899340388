import React, { useCallback, useEffect, useState } from 'react';
import Modal from './Modal';
import { ModalHeader, Flex, Text, ModalBody } from '@chakra-ui/react';
import useSocket from '../../hooks/useSocket';
import { useSelector } from 'react-redux';
import Player from '../Player/Player';
import useTts from '../../hooks/useTts';

function FinalSpeechReadingContainer({ isOpen, onOpen, onClose }) {
  const socket = useSocket();

  const [displaySpeeches, setDisplaySpeeches] = useState([]);
  const [currentSpeech, setCurrentSpeech] = useState(0);

  const handleEndTts = useCallback(() => {
    if (currentSpeech === 1) {
      // we went through all speeches
      onClose();
      return;
    }
    setCurrentSpeech(n => n + 1);
  }, [currentSpeech, setCurrentSpeech, onClose]);

  useEffect(() => {
    if (!socket) return;
    socket.on('displaySpeech', speeches => {
      onOpen();
      console.log('speeches', speeches);
      setDisplaySpeeches(speeches);
    });
  }, [socket, onOpen]);

  return (
    <FinalSpeechReadingModal
      speechInfo={displaySpeeches[currentSpeech]}
      isOpen={isOpen}
      onEndSpeech={handleEndTts}
    />
  );
}

const FinalSpeechReadingModal = ({ speechInfo, isOpen, onEndSpeech }) => {
  const { players } = useSelector(state => state.game);
  const [play] = useTts(`Discours : ${speechInfo?.speech}`, onEndSpeech);

  useEffect(() => {
    if (isOpen && play && speechInfo?.speech) {
      play();
    }
  }, [play, speechInfo, isOpen]);

  return (
    <Modal isOpen={isOpen} bg="gold" size="md" onClose={onEndSpeech}>
      <ModalHeader fontSize="2xl" align="center" color="sky">
        <Flex direction="column">
          <Text>Discours de</Text>
          <Flex justify="center">
            {speechInfo && (
              <Player
                position="unset"
                name={players.find(p => p.id === speechInfo.id).name}
                avatar={players.find(p => p.id === speechInfo.id).avatar}
              />
            )}
          </Flex>
        </Flex>
      </ModalHeader>
      <ModalBody>
        <Text>{speechInfo?.speech}</Text>
      </ModalBody>
    </Modal>
  );
};

export default FinalSpeechReadingContainer;
