import React, { useState } from 'react';
import Modal from './Modal';
import { ModalHeader, Flex, Text, ModalBody } from '@chakra-ui/react';
import Button from '../Button/Button';
import TextArea from '../Input/TextArea';
import useSocket from '../../hooks/useSocket';
import { useSelector } from 'react-redux';

function FinalSpeechWriting({ isOpen, onClose }) {
  const [speech, setSpeech] = useState('');
  const socket = useSocket();
  const { gameId, myId } = useSelector(state => state.game);

  const handleChange = e => {
    setSpeech(e.target.value);
  };

  const handleValidateSpeech = () => {
    socket.emit('final-speech', { gameId, myId, speech });
    onClose();
  };

  return (
    <Modal isOpen={isOpen} bg="gold" size="md" closeOnOverlayClick={false}>
      <ModalHeader fontSize="2xl" align="center" color="sky">
        <Flex direction="column">
          <Text>Pourquoi mérites tu de gagner ?</Text>
          <Text>Rédige ton discours</Text>
        </Flex>
      </ModalHeader>
      <ModalBody>
        <TextArea
          onChange={handleChange}
          maxLength="400"
          minH="35vh"
          placeholder="Votez pour moi svp"
        />
        <Flex align="center" justify="center">
          <Button onClick={handleValidateSpeech}>Valider</Button>
        </Flex>
      </ModalBody>
    </Modal>
  );
}

export default FinalSpeechWriting;
