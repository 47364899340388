// animations/DisappointedAnimation.jsx
import React, { useRef, useEffect, useState } from 'react';
import { Container, useApp } from '@pixi/react';
import { AdjustmentFilter } from '@pixi/filter-adjustment';
import usePixi from 'hooks/usePixi';

const DisappointedAnimation = ({ children }) => {
  const { width, height } = usePixi();
  const containerRef = useRef();
  const app = useApp();
  const [desaturation, setDesaturation] = useState(0);
  const [oscillation, setOscillation] = useState(0);
  const [rotation, setRotation] = useState(0);

  useEffect(() => {
    const container = containerRef.current;
    const filter = new AdjustmentFilter();
    container.filters = [filter];

    const animate = (delta) => {
      // Increase desaturation to simulate color fading
      setDesaturation(prev => Math.min(prev + 0.01 * delta, 1));
      filter.saturation = 1 - desaturation;

      // Apply slight oscillation
      setOscillation(prev => prev + 0.05 * delta);
      container.x = width / 2 + Math.sin(oscillation) * 10;
      
      // Apply slight rotation
      setRotation(prev => prev + 0.01 * delta);
      container.rotation = Math.sin(rotation) * 0.1;
    };

    app.ticker.add(animate);

    return () => {
      app.ticker.remove(animate);
    };
  }, [app, desaturation, oscillation, rotation, width]);

  return (
    <Container ref={containerRef} x={width / 2} y={height / 3}>
      {children}
    </Container>
  );
};

export default DisappointedAnimation;