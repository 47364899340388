import { Menu, MenuButton, MenuList, MenuItem, IconButton } from "@chakra-ui/react"
import Icon from "components/Icon"

// options [{label, action, icon}]

const MoreOptionsMenu = ({options}) => {
  return (
    <Menu>
      <MenuButton
        as={IconButton}
        aria-label='Options'
        icon={<Icon boxSize="30px" icon="mingcute:more-2-fill" color="white" />}
        variant='outline'
        bg="black"
        borderRadius="50px"
      />
      <MenuList>
        {options.map((option) => <MenuItem fontWeight="bold" _hover={{bg: "sea"}} icon={<Icon boxSize="30px" icon={option.icon}/>} key={option.label} onClick={option.action}>{option.label}</MenuItem>)}
      </MenuList>
    </Menu>
  )
}

export default MoreOptionsMenu