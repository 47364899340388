import React from 'react';
import {
  Modal as ChakraModal,
  ModalContent,
  ModalCloseButton,
  ModalOverlay,
} from '@chakra-ui/react';

function Modal({
  bg,
  color,
  children,
  onClose,
  isOpen,
  overlay = false,
  p,
  ...props
}) {
  return (
    <ChakraModal
      onClose={onClose}
      isOpen={isOpen}
      motionPreset="scale"
      isCentered
      {...props}
    >
      {overlay && <ModalOverlay />}
      <ModalContent bg={bg} color={color} p={p}>
        <ModalCloseButton />

        {children}
      </ModalContent>
    </ChakraModal>
  );
}

export default Modal;
