import React from 'react';
import { Textarea } from '@chakra-ui/react';

function TextArea({ ...props }) {
  return (
    <Textarea
      p="7"
      borderColor="white"
      borderRadius="5"
      borderWidth="3px"
      bg="#ffffff77"
      {...props}
    />
  );
}

export default TextArea;
