import React, { useState, useEffect } from 'react';
import { Text } from '@pixi/react';
import { TextStyle } from 'pixi.js';
import PropTypes from 'prop-types';
import { useMediaQuery } from '@chakra-ui/react';
import usePixi from 'hooks/usePixi';

const LetterByLetterText = ({ text, color, fontSize }) => {
  const [isLargerThan800] = useMediaQuery('(min-width: 800px)');
  const [displayedText, setDisplayedText] = useState('');
  const {width} = usePixi()

  useEffect(() => {
    setDisplayedText('');
    let index = 0;

    const displayLetterByLetter = () => {
      if (index < text.length + 5) {
        setDisplayedText(text.slice(0, index+1));
        index = index + 5;
        setTimeout(displayLetterByLetter, 100);
      }
    };

    displayLetterByLetter();
  }, [text]);

  return (
    <Text
      anchor={0.5}
      text={displayedText}
      style={
        new TextStyle({
          align: 'center',
          fill: ["black", "black"],
          fontSize: isLargerThan800 ? 25 : 15,
          letterSpacing: 5,
          wordWrap: true,
          wordWrapWidth: width / 2,
          fontFamily:'sans-serif'
        })
      }
      y={window.innerHeight / 3}
      x={width/2 }
    />
  );
};

LetterByLetterText.propTypes = {
  text: PropTypes.string.isRequired,
  color: PropTypes.string,
  fontSize: PropTypes.number,
};

LetterByLetterText.defaultProps = {
  color: 'black',
  fontSize: 36,
};

export default LetterByLetterText;
