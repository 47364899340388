import { useCallback, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import useOptions from 'hooks/useOptions';

const useTts = (text, onEnd) => {
  const synth = useRef(window.speechSynthesis);
  const { options: userOptions } = useSelector(state => state.user);
  const { getOption } = useOptions();
  const volume = useMemo(() => {
    return getOption('volume') ?? userOptions.volume ?? 50;
  }, [userOptions, getOption]);
  const [hasPlayed, setHasPlayed] = useState("")

  const play = useCallback(
    customText => {
      if (!volume) return
      if (!text && !customText) return;
      console.log("ICI", customText, hasPlayed)
      if (customText === hasPlayed || text === hasPlayed) return
      const utterThis = new SpeechSynthesisUtterance(customText || text);
      utterThis.lang = 'fr-FR';
      if (onEnd) {
        utterThis.onend = onEnd;
      }
      console.log("VOLUME TSS", volume, userOptions.volume)
      utterThis.volume = (userOptions.volume ?? volume) / 100;
      synth.current.speak(utterThis);
      setHasPlayed(text || customText)
    },
    [text, onEnd, volume, userOptions.volume, hasPlayed]
  );

  return [play];
};

export default useTts;
