import { ReceivingModal } from './ReceivingModal';
import { useEffect, useState } from 'react';
import { useDisclosure } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import useSocket from '../../hooks/useSocket';
import ImmunityBracelet from '../Interface/ImmunityBracelet';

function ReceivingBraceletModalContainer() {
  const { myId } = useSelector(state => state.game);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const socket = useSocket();

  const [infos, setInfos] = useState({ name: '', message: '' });

  useEffect(() => {
    if (!socket) return;
    socket.on('receivingBracelet', ({ from, fishes, message, to }) => {
      if (to !== myId) return;
      setInfos({ from, message });
      onOpen();
    });
  }, [socket, onOpen, myId]);

  return (
    <ReceivingModal
      {...infos}
      type="bracelet"
      onClose={onClose}
      isOpen={isOpen}
    >
      <ImmunityBracelet
        style={{ height: 'auto', width: '30%' }}
        fontSize="6xl"
        value={1}
      />
    </ReceivingModal>
  );
}

export default ReceivingBraceletModalContainer;
