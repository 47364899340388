import React from 'react';
import MotionInput from '../MotionBox/MotionInput';

function Input({ ...props }) {
  return (
    <MotionInput
      whileFocus={{ scale: 1.2 }}
      p="7"
      borderColor="white"
      borderRadius="5"
      borderWidth="3px"
      bg="#ffffff77"
      {...props}
    />
  );
}

export default Input;
