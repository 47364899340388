import {useEffect} from "react"
import { Button, Text, VStack, HStack } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useNumberInput } from '@chakra-ui/react';

const IncrementalOption = ({ title, defaultValue, min, max, step, optionKey, onChange, isPremium = true }) => {
  const { user } = useSelector(state => state.user);

  const openUpgradePageTab = () => {
    window.open('/upgrade', '_blank');
  };
  const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } =
    useNumberInput({
      step,
      defaultValue,
      min,
      max,
    });


  const inc = user?.premium || !isPremium
    ? getIncrementButtonProps()
    : { onClick: openUpgradePageTab };
  const dec = user?.premium || !isPremium
    ? getDecrementButtonProps()
    : { onClick: openUpgradePageTab };
  const inputProps = getInputProps({
    onChange: () => {return}
  });

   useEffect(() => {
    if (!inputProps?.value) return
    console.log("BOUCLEINFINE ,")
    onChange({ [optionKey]: inputProps.value });
    // eslint-disable-next-line 
  }, [inputProps?.value, optionKey]);

  return (
    <VStack>
      <Text fontSize="md" fontWeight="bold">{title}</Text>
      <HStack placeSelf="center" placeContent="center">
        <Button {...dec}>-</Button>
        <Text w="30%" textAlign="center" fontSize="3xl" fontWeight="700" minW="fit-content">{inputProps?.value}</Text>
        <Button {...inc}>+</Button>
      </HStack>
    </VStack>
  );
};

export default IncrementalOption;
