import React, { useEffect, useState } from 'react';
import { tutoData } from '../constants/tuto';
import { VStack, GridItem, Image, Progress, Text } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import Button from '../components/Button';
import PixiCanvas from 'components/PixiCanvas';
import Animations from 'animations/Animations';
import { ANNOUNCE } from 'constants';
import { AnimatedSprite } from '@pixi/react';
import normal from "svg/animator/normal.svg"
import normalEyesLeft from "svg/animator/normal-eyes-left.svg"
import normalEyesRight from "svg/animator/normal-eyes-right.svg"
import LetterByLetterText from 'components/Announcement/LetterByLetterText';

const Tuto = ({
  id,
  setDisplay,
  timeout = 20000,
  canSkip = false,
  force = false,
}) => {
  // skip tuto if option is unchecked
  const { options } = useSelector(state => state.game);
  const tuto = (options.showTuto || force) && tutoData?.[id];
  const updateTimerFrequency = 300;
  const [currentSlide, setCurrentSlide] = useState(0);
  const [currentText, setCurrentText] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [faces] =  useState([normal, normalEyesLeft, normalEyesRight])

  useEffect(() => {
    setTimeout(
      () => {
        setDisplay(false);
      },
      tuto ? timeout : 0
    );
  }, [timeout, setDisplay, tuto]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide(prevSlide => (prevSlide + 1) % tuto?.images?.length);
    }, timeout / tuto?.images?.length);

    const intervalText = setInterval(() => {
      setCurrentText(prevText => (prevText + 1) % tuto?.texts?.length);
    }, timeout / tuto?.texts?.length);

    const timer = setInterval(() => {
      setCurrentTime(time => time + updateTimerFrequency);
    }, updateTimerFrequency);

    return () => {
      clearInterval(interval);
      clearInterval(intervalText);
      clearInterval(timer);
    };
  }, [tuto?.images?.length, tuto?.texts?.length, timeout]);
  if (!tuto) return;


  return (
    <VStack
      position="fixed"
      top={0}
      left={0}
      zIndex="2"
      textAlign="center"
      align="center"
      pt="5"
      bg="gold"
      justify="center"
      h='100%'
      w="100%"
      maxW="100%"
      transition="1s ease"
      m="0"
      overflow="hidden"
      spacing={0}
    >
        <Text fontSize="5xl" textAlign="center" fontWeight="bold" my={10}>
          {id}
        </Text>
        <Image
          border="2px black"
          src={tuto.images[currentSlide]}
          alt={`Slide ${currentSlide + 1}`}
          maxH={"20%"}
        />
        {canSkip && (
          <Button bg="sky" color="white" onClick={() => setDisplay(false)}>
            Passer les explications
          </Button>
        )}
        <GridItem
          p="5"
          colSpan={4}
          w="500px"
          justifySelf="center"
          alignSelf="center"
        >
          <Progress
            hasStripe
            colorScheme="loadingBar"
            size="sm"
            value={(currentTime / timeout) * 100}
            borderRadius="15px"
          />
        </GridItem>
      <PixiCanvas>
        <Animations animation={ANNOUNCE.ANIMATION.WALKING}>
          <AnimatedSprite
            images={faces}
            width={250}
            height={250}
            anchor={{ x: 0.5, y: 0.5 }}
            isPlaying={true}
            animationSpeed={0.01}
          />
        </Animations>
        <LetterByLetterText text={tuto.texts[currentText]} />
      </PixiCanvas>
      </VStack>
  );
};

export default Tuto;
