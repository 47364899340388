import { initializeApp } from 'firebase/app';
// import { getAnalytics } from 'firebase/analytics';
// Remove GoogleAuthProvider if not using Google Auth
import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
} from 'firebase/auth';
import {
  getFirestore,
  query,
  getDocs,
  collection,
  where,
  addDoc,
} from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import firebaseErrorSwitch from './utils';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

// Remove if not using Google Auth
const googleProvider = new GoogleAuthProvider();
const signInWithGoogle = async (onError = alert) => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    const user = res.user;
    const q = query(collection(db, 'users'), where('uid', '==', user.uid));
    const docs = await getDocs(q);
    if (docs.docs.length === 0) {
      await addDoc(collection(db, 'users'), {
        uid: user.uid,
        name: user.displayName,
        authProvider: 'google',
        email: user.email,
        premium: false,
        avatar: null,
      });
    }
  } catch (err) {
    console.error(err);
    onError(firebaseErrorSwitch(err));
  }
};

const logInWithEmailAndPassword = async (email, password, onError = alert) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (err) {
    console.error(err);
    onError(firebaseErrorSwitch(err));
  }
};

const registerWithEmailAndPassword = async (
  name,
  email,
  password,
  onError = alert
) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;
    await addDoc(collection(db, 'users'), {
      uid: user.uid,
      name,
      authProvider: 'local',
      email,
      premium: false,
      avatar: null,
    });
  } catch (err) {
    console.error(err);
    onError(firebaseErrorSwitch(err));
  }
};

const sendPasswordReset = async (email, onError = alert, onSuccess = alert) => {
  try {
    await sendPasswordResetEmail(auth, email);
    onSuccess('Password reset link sent!');
  } catch (err) {
    console.error(err);
    onError(firebaseErrorSwitch(err));
  }
};

const logout = () => {
  signOut(auth);
  window.location.reload();
};

export {
  auth,
  db,
  storage,
  signInWithGoogle,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordReset,
  logout,
};
