import React, { useState, useEffect } from 'react';
import Modal from './Modal';
import useSocket from '../../hooks/useSocket';
import { useDisclosure, ModalHeader, Text, Box, Flex, Image } from '@chakra-ui/react';

import { useSelector } from 'react-redux';
import useTts from '../../hooks/useTts';
import { ITEMS } from 'constants/items';
import AnimatedNumber from 'components/ui/AnimatedNumber';

function ReceivingItemsModalContainer() {
  const { myId } = useSelector(state => state.game);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const socket = useSocket();

  const [infos, setInfos] = useState({ item: undefined, count:0, name: '', message: '' });

  useEffect(() => {
    if (!socket) return;
    socket.on('receivingItems', ({ from, item, count, message, to }) => {
      if (to !== myId) return;
      setInfos({ item, from, message, count });
      onOpen();
    });
  }, [socket, onOpen, myId]);

  return <ReceivingModal {...infos} onClose={onClose} isOpen={isOpen} />;
}

function ReceivingModal({
  from,
  item,
  count,
  message,
  onClose,
  isOpen,
  children,
}) {
  const [play] = useTts(`Message de ${from} : ${message}`);

  useEffect(() => {
    if (message) {
      play();
    }
  }, [play, message]);

  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      bg="gold"
      size="lg"
      closeOnOverlayClick={false}
    >
      <ModalHeader fontSize="5xl" align="center" color="vegetation">
        Cadeau de{' '}
        <Box as="span" fontWeight="800">
          {from}
        </Box>
      </ModalHeader>
      {children ? (
        children
      ) : (
        <Flex direction="column" align="center">
          <Image h="fit-content" maxH="10vh" w="auto" src={ITEMS?.[item]?.image} alt={ITEMS?.[item]?.name} />
          <AnimatedNumber value={count} color="sky" fontWeight="extrabold" fontSize="4xl" />
        </Flex>
      )}
      <Text placeSelf="center" fontSize="3xl" fontStyle="italic">
        "{message}"
      </Text>
    </Modal>
  );
}

export { ReceivingModal };

export default ReceivingItemsModalContainer;
