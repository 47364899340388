import { Text, HStack, VStack } from '@chakra-ui/react';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import Avatar from 'components/Avatar';

const PlayerScore = () => {
  const { me, players } = useSelector(state => state.game);
  const [topThreePlayers, setTopThreePlayers] = useState([])

  const sortedPlayers = useMemo(() => players.filter(p => p.canPlay && p.alive).sort((p1, p2) => (p1.score > p2.score ? -1 : 1)), [players]);


  useEffect(() => {
    // Extract the top 3 players
    const tmpTopThreePlayers = sortedPlayers.slice(0, 3);

    // Check if the current player is among the top 3 players
    const isCurrentPlayerInTopThree = tmpTopThreePlayers.some(player => player.id === me.id);

    // If the current player is not in the top 3, but playing add them as the fourth player
    if (!isCurrentPlayerInTopThree && sortedPlayers.some(p => p.id === me.id)) {
      tmpTopThreePlayers.push(me);
    }
    setTopThreePlayers(tmpTopThreePlayers)
  }, [sortedPlayers, me])


  return (
    <HStack position="relative" gap={12}>
      {topThreePlayers.map((player) => (
        <VStack color={player.id === me.id ? "vegetation" : "sky"} key={player.id}>
          <Avatar
            forceAvatar={player?.avatar}
            name={player.name}
            boxSize={20}
          />
          <Text fontWeight="extrabold" fontSize="2xl">
            {player?.score || 0}
          </Text>
        </VStack>
      ))}
    </HStack>
  );
};

export default PlayerScore;
