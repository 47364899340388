import React from 'react';
import Modal from './Modal';
import { ModalHeader, Flex } from '@chakra-ui/react';
import Button from '../Button/Button';
import usePlayerItems from '../../hooks/usePlayerItems';
import useSocket from '../../hooks/useSocket';
import { useSelector } from 'react-redux';
import { ReactComponent as Bracelet } from '../../svg/Bracelet.svg';

function UseBraceletModal({ isOpen, onClose, max }) {
  const { myId, gameId } = useSelector(state => state.game);
  const {
    items: { bracelets },
    updateItems,
  } = usePlayerItems();
  const socket = useSocket();

  const handleUse = () => {
    updateItems({ bracelets: bracelets - 1 });
    socket.emit('useBracelet', {
      id: myId,
      gameId,
    });
    onClose();
  };

  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      bg="primary"
      size="md"
      closeOnOverlayClick={false}
    >
      <ModalHeader fontSize="2xl" align="center" color="white">
        Utiliser un Bracelet ?
      </ModalHeader>
      <Flex align="center" justify="center">
        <Bracelet />
      </Flex>
      <Flex p="2">
        <Button onClick={onClose}>Non</Button>
        <Button onClick={handleUse} bg="sky" color="white">
          Oui
        </Button>
      </Flex>
    </Modal>
  );
}

export default UseBraceletModal;
