import Modal from './Modal';
import {
  ModalHeader,
  ModalBody,
  VStack,
  Text,
  Divider,
  HStack,
} from '@chakra-ui/react';
import Icon from 'components/Icon';
import userTitles from 'constants/userTitles';

function NewTitleModal({ onClose, isOpen, titles }) {
  return (
    <Modal
      onClose={onClose}
      // isOpen={isOpen}
      isOpen={isOpen}
      size="2xl"
      overlay
    >
      <ModalHeader bg="gold" fontSize="3xl" align="center">
        Félicitations, nouveaux titres débloqués !
      </ModalHeader>
      <ModalBody bg="gold" p="8">
        {titles.map(title => (
          <VStack key={title} mb="3">
            <HStack>
              <Text fontWeight="bold" fontSize="2xl">
                {userTitles[title].name}
              </Text>
              <Icon
                boxSize="7"
                color={userTitles[title].color}
                icon={userTitles[title].icon}
              />
            </HStack>
            <HStack>
              <Text fontWeight="bold" color="vegetation" fontSize="xl">
                {userTitles[title].conditionText}
              </Text>
              <Icon boxSize="8" color="vegetation" icon="foundation:checkbox" />
            </HStack>
            <Divider borderColor="black" />
          </VStack>
        ))}
      </ModalBody>
    </Modal>
  );
}

export default NewTitleModal;
