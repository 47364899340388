import { useState, useCallback } from 'react';

const useRerender = () => {
  const [rerenderKey, setRerenderKey] = useState(0);

  const rerender = useCallback(() => {
    setRerenderKey(prevKey => prevKey + 1);
  }, []);

  return [rerender, rerenderKey];
};

export default useRerender;
