import React, { useEffect, useMemo, useState } from 'react';
import { GAMES } from '../constants';
import Archery from './Archery/Archery';
import Relais from './Relais/Relais';
import Tuto from './Tuto';
import MoreOrLess from './MoreOrLess';
import Dodge from './Dodge';
import Memory from './Memory';
import { Box, Flex, Text } from '@chakra-ui/react';
import useSocket from 'hooks/useSocket';
import { useSelector } from 'react-redux';
import BlindRun from 'games/BlindRun';
import BlindMaze from 'games/Battleships';
import OddOneOut from 'games/OddOneOut';
import CosmosCombo from 'games/CosmosCombo';
import Defender from 'games/Defender';
import WhackAMole from 'games/WhackAMole';

import trialMusic from "sounds/music/trial.mp3"
import useSound from 'hooks/useSound';


const Games = ({ game }) => {
  const { gameId, me, isReady } = useSelector(state => state.game);

  const noMusicGames = [GAMES.MEMORY]

  const [displayTuto, setDisplayTuto] = useState(true);
  const [playMusic, {stop}] = useSound(trialMusic);

  const socket = useSocket();

  useEffect(() => {
    if (displayTuto) return;
    // important to emit firstId in case of reconnection or else the same player will be ready twice
    socket.emit('game-ready', { gameId, myId: me?.firstId });
  }, [socket, displayTuto, gameId, me?.firstId]);

  useEffect(() => {
    console.log("nomusic", noMusicGames, game);
    if (noMusicGames.includes(game) || !me.alive || !me.canPlay) return
    playMusic()
    return () => stop()
    // eslint-disable-next-line
  }, [playMusic, stop, game])

  const gameDisplay = useMemo(() => {
    if (!isReady) return <WaitingScreen />;
    console.log('GAME', game);
    switch (game) {
      case GAMES.WHACKAMOLE:
        return <WhackAMole />;
      case GAMES.DEFENDER:
        return <Defender />;
      case GAMES.ARCHERY:
        return <Archery />;
      case GAMES.RELAIS:
        return <Relais />;
      case GAMES.MORE_OR_LESS:
        return <MoreOrLess />;
      case GAMES.DODGE:
        return <Dodge />;
      case GAMES.MEMORY:
        return <Memory />;
      case GAMES.BLIND_RUN:
        return <BlindRun />;
      case GAMES.BATTLESHIPS:
        return <BlindMaze />;
      case GAMES.ODD_ONE_OUT:
        return <OddOneOut />;
      case GAMES.COSMOS_COMBO:
        return <CosmosCombo />;
      default:
        return <Archery />;
    }
  }, [game, isReady]);

  if (displayTuto)
    return <Tuto id={game} setDisplay={setDisplayTuto} canSkip={me.alive} />;

  return (
    <Box w="100%" h="100%">
      {gameDisplay}
    </Box>
  );
};

const WaitingScreen = () => (
  <Flex
    style={{
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'black',
    }}
    opacity="0.2"
    zIndex="2"
    pointerEvents="none"
    textAlign="center"
    justif="center"
    align="center"
    p="5"
  >
    <Text fontWeight="bold" w="100%" fontSize="2xl" color="white">
      En attente des autres joueurs ...
    </Text>
  </Flex>
);

export default Games;
