import { useToast } from '@chakra-ui/react';

const useError = (callback = () => {}) => {
  const toast = useToast();
  const onError = error => {
    toast({
      position: 'top',
      description: error,
      status: 'error',
      duration: 9000,
      isClosable: true,
    });
    callback();
  };
  return [onError];
};

export default useError;
