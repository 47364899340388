import { updateUser } from 'myFirebase/user';
import FirebaseUser, { UserStats } from 'types/FirebaseUser';

const updateUserStats = (user: FirebaseUser, stats: UserStats) => {
  console.log('utilisateur update ses stats', user);
  console.log('stats de la partie', stats);

  const updatedStats: Partial<UserStats> = {}; // Utilisez Partial<UserStats> pour indiquer que toutes les propriétés ne sont pas requises

  // Parcourez chaque propriété de stats
  for (const key of Object.keys(stats) as Array<keyof UserStats>) {
    if (Object.prototype.hasOwnProperty.call(stats, key)) {
      // Assurez-vous que la propriété est présente dans user.stats et qu'elle est un nombre
      if (typeof stats[key] === 'number') {
        // Ajoutez la valeur de stats à la valeur actuelle de user.stats
        updatedStats[key] = user?.stats?.[key]
          ? user.stats[key] + stats[key]
          : stats[key];
      }
    }
  }

  console.log('Stats mises à jour :', updatedStats);

  // Mettez à jour les statistiques de l'utilisateur dans Firebase
  updateUser(user.ref, { stats: { ...user?.stats, ...updatedStats } });
};

export default updateUserStats;
