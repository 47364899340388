import React, { useState, useEffect, useMemo } from 'react';

import {
  Box,
  useDisclosure,
  Text,
} from '@chakra-ui/react';


import Palm from "sprites/trees/palm.png"
import Tree from "sprites/trees/normal.png"
import DeadTree from "sprites/trees/dead.png"
import FruitTree from "sprites/trees/fruit.png"


import { ReactComponent as BraceletSvg } from 'svg/Bracelet.svg';

import { animate } from 'motion';

import discover from 'sounds/discover.mp3';
import forest from "sounds/music/forest.mp3"
import chop from "sounds/chop.mp3"
import fireSound from "sounds/fire-power.mp3"

import usePlayerItems from 'hooks/usePlayerItems';
import { useDispatch, useSelector } from 'react-redux';
import { ELEM } from 'constants';
import {
  getForestArray,
} from 'redux/features/gameSlice';
import HintModal from 'components/Modal/HintModal';
import successPercent from "utils/successPercent"
import {FireModal} from "components/Interface/Fire"
import useSound from 'hooks/useSound';
import BraceletCanvas from './BraceletCanvas';
import { ITEM_TYPE } from 'constants/items';
import useLocalStorage from 'hooks/useLocalStorage';
import Tuto from 'games/Tuto';
import { LOCATIONS } from 'constants';

function Bracelet({ CustomSvg, customSound, onFound, isOrientation=false, foundKnife=false }) {
  const { myTeam, forestLocation, forestArray, isDemo} = useSelector(
    state => state.game
  );
  const dispatch = useDispatch();
  const [canClick, setCanClick] = useState(true)
  const [play] = useSound(customSound || discover);
  const [playChop] = useSound(chop);
  const [playFire] = useSound(fireSound);
  const [playMusic, {stop}] = useSound(forest);
  const { updateItems, items } = usePlayerItems();
  const {isOpen: isOpenFindFire, onOpen: onOpenFindFire, onClose: onCloseFindFire} = useDisclosure()

  const debounceTime = useMemo(() => items?.goldAxe ? 200 : 500, [items?.goldAxe])

  useEffect(() => {
    playMusic()
    return () => stop()
  }, [playMusic, stop])

  const {
    isOpen: isOpenHint,
    onOpen: onOpenHint,
    onClose: onCloseHint,
  } = useDisclosure();
  const [hint, setHint] = useState('');

  const ElemMapping = {
    [ELEM.PALM]: Palm,
    [ELEM.NORMAL_TREE]: Tree,
    [ELEM.FRUIT_TREE]: FruitTree,
    [ELEM.DEAD_TREE]: DeadTree,
  };


  const handleFoundBracelet = () => {
    onFound ? onFound() : updateItems({ bracelets: items?.bracelets + 1 });
    play();
    const animation = animate(
      '#BraceletSvgAnimation',
      {
        opacity: [1, 1, 0],
        scale: [1, 10, 12],
        rotate: [0, 85, 100],
        filter: ['brightness(0)', 'brightness(2)', 'brightness(0)'],
      },
      {
        duration: 3,
        offset: [0, 0.85],
      }
    );

    animation.finished.then(() => {
      // animation.cancel()
    });
  };

  useEffect(() => {
    if (isOrientation) {

      return
    }
    dispatch(getForestArray(myTeam.braceletObj.locations[forestLocation]));
    // eslint-disable-next-line
  }, [myTeam.braceletObj, forestLocation, isOrientation]);

  const handleFoundHint = (pos, location) => {
    const tmpHint = myTeam.braceletObj.locations[location][pos]?.hint;
    if (tmpHint) {
      setHint(tmpHint.hint);
      onOpenHint();
    }
  };

  const debounceClick = (power=false) => {
    if (power) {
      playFire()

    } else {
      playChop()
    }
    setCanClick(false)
    setTimeout(() => {
      setCanClick(true)
    }, debounceTime)

    // not the cleanest code to do it here but better optimized than in each element
    if (successPercent(1, 300)) {
      onOpenFindFire()
      updateItems({fire: items?.fire + 1})
    }
    // not the cleanest code to do it here but better optimized than in each element
    else if (successPercent(50)) {
      updateItems({wood: items?.wood + 1}, ITEM_TYPE.COLLECTIBLES)
    }
  }

  const [displayTuto, setDisplayTuto] = useLocalStorage('bracelet-tuto', true);
  if (isDemo && displayTuto)
    return (
      <Tuto
        canSkip
        timeout={8000}
        id={LOCATIONS.ACTIVITY.BRACELET}
        setDisplay={setDisplayTuto}
      />
    );

  return (
    <>
      <HintModal isOpen={isOpenHint} onClose={onCloseHint} hint={hint} />
      <FireModal isOpen={isOpenFindFire} onClose={onCloseFindFire} discover/>
      <BraceletCanvas
        goldAxe={items?.goldAxe}
        forestLocation={forestLocation}
        handleFoundBracelet={handleFoundBracelet}
        handleFoundHint={handleFoundHint}
        debounceClick={debounceClick}
        canClick={canClick && !foundKnife}
        elements={forestArray.map((elem, index) => ({ ...elem, image: ElemMapping[elem.type], indexPos: index }))} />
      <Box
        style={{
          width: '50px',
          pointerEvents: 'none',
          opacity: 0,
          position: 'absolute',
          top: '50%',
          left: '47%',
        }}
        id="BraceletSvgAnimation"
        zIndex={2}
      >
        {CustomSvg ? (
          <CustomSvg style={{ width: '100%', height: '100%' }} />
        ) : (
          <BraceletSvg style={{ width: '100%', height: '100%' }} />
        )}
      </Box>

      <Text userSelect="none" pointerEvents="none" mt="5" fontWeight="bold" fontSize="5xl" p="5"  color="white" align="center">ZONE {forestLocation}</Text>
    </>
  );
}


export default Bracelet;
