import React, { useEffect, useMemo, useState } from 'react';
import Modal from './Modal';
import {
  ModalHeader,
  HStack,
  Flex,
  ModalBody,
  Box,
} from '@chakra-ui/react';
import { ReactComponent as SlotSvg } from 'svg/slot-machine.svg';
import Button from '../Button/Button';
import usePlayerItems from 'hooks/usePlayerItems';
import Icon from 'components/Icon';
import { randomIntFromInterval } from 'utils/random';

const SYMBOLS = ["openmoji:goldfish", "iconoir:fishing", "twemoji:trophy", "lucide:shuffle", "ion:caret-back-sharp", "dashicons:unlock", "mingcute:wave-fill", "game-icons:fishing-pole"];

function SlotMachineModal({ isOpen, onClose, onValidate }) {
  const { updateItems, items } = usePlayerItems();

  const [symbols, setSymbols] = useState(SYMBOLS.slice(0, 3));
  const [isSpinning, setIsSpinning] = useState(false);
  const [clickedOnce, setClickedOnce] = useState(false);
  const canClick = useMemo(() => items?.coins > 0 && !isSpinning, [items,  isSpinning]) || false;

  const handleValidateSlot = () => {
    if (!canClick || isSpinning) return;
    updateItems({coins: items.coins - 1})
    setClickedOnce(true)
    setIsSpinning(true);
    const timer = setTimeout(() => {
      setIsSpinning(false);
    }, 3000);
    return () => clearTimeout(timer);
  };

  useEffect(() => {
    if (isSpinning) {
      const interval = setInterval(() => {
      const tmpSymbols = [SYMBOLS[randomIntFromInterval(0, SYMBOLS.length -1)], SYMBOLS[randomIntFromInterval(0, SYMBOLS.length -1)], SYMBOLS[randomIntFromInterval(0, SYMBOLS.length -1)]];
        setSymbols(tmpSymbols);
      }, 200);
      return () => clearInterval(interval);
    } else {
      if (!clickedOnce) return
      console.log("result", symbols)
      if (symbols[0] === symbols[1] && symbols[1] === symbols[2]) {
        console.log('win 100');
        updateItems({coins: items.coins + 100})
        return
      }
      if (symbols[0] === symbols[1] || symbols[0] === symbols[2] || symbols[1] === symbols[2]) {
        console.log('win 3');
        updateItems({coins: items.coins + 3})
      }
    }
    // eslint-disable-next-line
  }, [isSpinning]);

  return (
    <Modal onClose={onClose} isOpen={isOpen} bg="red" size="md" zIndex={1}>
      <ModalHeader fontSize="2xl" align="center" color="white">
        Machine à sous
      </ModalHeader>
      <ModalBody position="relative">
        <Flex justify="center" align="center">
          <SlotSvg cursor="pointer" onClick={handleValidateSlot} style={{ height: "40vh", width: 'auto' }} />
        </Flex>
        <Flex justify="center">
          <Button bg="green" color="white" disabled={!canClick} onClick={handleValidateSlot}>
            Mettre 1 jeton
          </Button>
        </Flex>
        <HStack pr="10%" spacing={10} opacity={clickedOnce ? "1" : "0"} justify="center" left="50%" top="45%" transform="translate(-50%, -50%)" right={0} w="100%"  position="absolute" minH="5vh">
          {symbols.map((symbol, index) => (
            <Box key={index} bg="#f5f7fa" p={"1px"}>
              <Icon key={index} icon={symbol} />
            </Box>
          ))}
        </HStack>
      </ModalBody>
    </Modal>
  );
}

export default SlotMachineModal;
