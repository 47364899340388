import React, { useMemo } from 'react';
import Canvas from 'components/Canvas/Canvas';

import Title from 'components/Title/Title';

import { useSelector } from 'react-redux';

import Interface from 'components/Interface/Interface';

import { LOCATIONS } from '../constants';
import Camp from 'components/Camp/Camp';
import Fishing from 'components/Camp/Fishing';
import { BackToCampButton } from 'components/BackButton';
import Bracelet from 'components/Camp/Bracelet';
import Vote from 'components/Vote/Vote';
import Games from 'games/Games';
import SemiFinal from 'games/SemiFinal/SemiFinal';
import Final from 'games/Final';
import TieBreak from 'games/TieBreak/TieBreak';
import Campfire from 'components/Camp/Campfire';
import Option from 'components/Option';
import VolumeOption from 'components/Option/VolumeOption';
import EliminatedVilla from 'components/Camp/EliminatedVilla';
import Chat from 'components/Modal/Chat';
import SadOverlay from 'games/SadOverlay';
import Piouteaux from 'games/Piouteaux';
import { Flex } from '@chakra-ui/react';
import SpectatorCount from "components/Option/SpectatorCount"
import Announcement from 'components/Announcement';
import Crafting from 'components/Camp/Crafting';
import AnimatedItem from 'animations/AnimatedItem';

const Start = () => {
  const { location, me } = useSelector(state => state.game);

  const displayBackButton = useMemo(
    () =>
      [
        LOCATIONS.ACTIVITY.FISHING,
        LOCATIONS.ACTIVITY.INVENTORY,
        LOCATIONS.ACTIVITY.BRACELET,
        LOCATIONS.ACTIVITY.CAMPFIRE,
      ].includes(location),
    [location]
  );

  return (
    <>
      <Canvas>
        <Location />
      </Canvas>
      <Title position="absolute" top="0" w="100%">
        {me?.alive ? location : me?.spectator ? "SPECTATEUR" : 'JURY FINAL'}
      </Title>
      <Interface />
      {displayBackButton && (
        <BackToCampButton
          color={location === LOCATIONS.ACTIVITY.CAMPFIRE ? 'white' : 'auto'}
        />
      )}
      <Flex w="100%" justify="end">
      {!me?.alive && (
        <Chat
          text={me?.spectator ? "Discussion feu de camps" :"Discussion Jury"}
          canWrite={!me?.spectator}
          bg="red"
        />
      )}
      </Flex>
      <Option />
      <SpectatorCount/>
      <VolumeOption/>
      <SadOverlay />
      <AnimatedItem/>
    </>
  );
};

const Location = () => {
  const { location, currentGame, me } = useSelector(state => state.game);
  // return <EliminatedVilla/>
  switch (location) {
    case LOCATIONS.ANNOUNCEMENT:
      return <Announcement />;
    case LOCATIONS.GAME:
      return <Games game={currentGame} />;
    case LOCATIONS.ACTIVITY.FISHING:
      if (!me.alive && !me.spectator) return <EliminatedVilla />;
      return <Fishing />;
    case LOCATIONS.ACTIVITY.BRACELET:
      if (!me.alive && !me.spectator) return <EliminatedVilla />;
      return <Bracelet />;
    case LOCATIONS.ACTIVITY.INVENTORY:
      if (!me.alive && !me.spectator) return <EliminatedVilla />;
      return <Crafting />;
    case LOCATIONS.ACTIVITY.CAMPFIRE:
      if (!me.alive && !me.spectator) return <EliminatedVilla />;
      return <Campfire />;
    case LOCATIONS.CAMP:
      if (!me.alive && !me.spectator) return <EliminatedVilla />;
      return <Camp />;
    case LOCATIONS.VOTE:
      return <Vote />;
    case LOCATIONS.SEMI_FINAL:
      return <SemiFinal />;
    case LOCATIONS.PIOUTEAUX:
      return <Piouteaux />;
    case LOCATIONS.FINAL:
      return <Final />;
    case LOCATIONS.TIE_BREAK:
      return <TieBreak />;
    default:
      return <Vote />;
  }
};

export default Start;
