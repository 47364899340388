import FirebaseUser from 'types/FirebaseUser';

type Title = {
  name: string;
  condition: (user: FirebaseUser) => boolean;
  conditionText: string;
  hide?: boolean;
  color?: string; // icon color only for now
  icon?: string;
  percent?: (user: FirebaseUser) => number;
};

type Titles = {
  [key: string]: Title;
};

const userTitles: Titles = {
  NEW_USER: {
    name: "Graine d'aventurier",
    condition: user => !!user, // il suffit d’avoir un compte
    conditionText: 'Avoir crée un compte',
    icon: 'mdi:seed-outline',
    color: 'white',
  },
  CHALLENGER_BEGINNER: {
    name: 'Coureur du dimanche',
    condition: user =>
      !!user?.stats?.total_game_won && user.stats.total_game_won > 10,
    conditionText: 'Remporter au moins 10 épreuves',
    icon: 'mingcute:run-fill',
    color: 'black',
    percent: user =>
      !!user?.stats?.total_game_won ? user.stats.total_game_won / 10 : 0,
  },
  CHALLENGER_GOOD: {
    name: 'Sportif Amateur',
    condition: user =>
      !!user?.stats?.total_game_won && user.stats.total_game_won > 25,
    conditionText: 'Remporter au moins 25 épreuves',
    icon: 'ic:baseline-sports-martial-arts',
    color: 'black',
    percent: user =>
      !!user?.stats?.total_game_won ? user.stats.total_game_won / 25 : 0,
  },
  // number of fishes you got
  FISHERMAN: {
    name: 'Apprenti pêcheur',
    condition: user =>
      !!user?.stats?.total_fishes && user.stats.total_fishes > 50,
    conditionText: 'Récupérer plus de 50 poissons',
    icon: 'iconoir:fishing',
    color: 'black',
    percent: user =>
      !!user?.stats?.total_fishes ? user.stats.total_fishes / 50 : 0,
  },

  // number of forest_search
  SEARCHER: {
    name: 'Chercheur hors paire',
    condition: user =>
      !!user?.stats?.total_forest_search &&
      user.stats.total_forest_search > 200,
    conditionText: 'Fouiller plus de 200 éléments en forêt',
    icon: 'bi:search',
    color: 'black',
    percent: user =>
      !!user?.stats?.total_forest_search
        ? user.stats.total_forest_search / 200
        : 0,
  },
  SEARCHER_TOO_MUCH: {
    name: 'Ca fait beaucoup là non ?',
    condition: user =>
      !!user?.stats?.total_forest_search &&
      user.stats.total_forest_search > 10000,
    conditionText: 'Fouiller plus de 10 000 éléments en forêt',
    icon: 'game-icons:wood-pile',
    color: 'black',
    percent: user =>
      !!user?.stats?.total_forest_search
        ? user.stats.total_forest_search / 10000
        : 0,
  },

  // tie break
  UNLUCKY: {
    name: 'Super spectateur',
    condition: user =>
      !!user?.stats?.total_tie_break_lose &&
      user.stats.total_tie_break_lose >= 5,
    conditionText: 'Piocher 5 fois la boule noire',
    icon: 'ic:baseline-chair',
    color: 'black',
    percent: user =>
      !!user?.stats?.total_tie_break_lose
        ? user.stats.total_tie_break_lose / 5
        : 0,
  },

  // number of games played
  NO_LIFE: {
    name: 'No Life',
    condition: user =>
      !!user?.stats?.total_games && user.stats.total_games > 100,
    conditionText: 'Jouer 100 parties',
    icon: 'fluent-emoji:person-mage',
    color: 'black',
    percent: user =>
      !!user?.stats?.total_games ? user.stats.total_games / 100 : 0,
  },

  // number of games won
  NEW_CHAMPION: {
    name: 'Champion novice',
    condition: user =>
      !!user?.stats?.total_final_won && user.stats.total_final_won >= 1,
    conditionText: 'Remporter 1 saison',
    icon: 'game-icons:podium-winner',
    color: 'black',
    percent: user =>
      !!user?.stats?.total_final_won ? user.stats.total_final_won / 1 : 0,
  },
  OFTEN_CHAMPION: {
    name: 'Champion habitué',
    condition: user =>
      !!user?.stats?.total_final_won && user.stats.total_final_won >= 5,
    conditionText: 'Remporter 5 saison',
    icon: 'game-icons:podium-winner',
    color: 'vegetation',
    percent: user =>
      !!user?.stats?.total_final_won ? user.stats.total_final_won / 5 : 0,
  },
  CONFIRMED_CHAMPION: {
    name: 'Champion Confirmé',
    condition: user =>
      !!user?.stats?.total_final_won && user.stats.total_final_won >= 10,
    conditionText: 'Remporter 10 saison',
    icon: 'game-icons:podium-winner',
    color: 'sea',
    percent: user =>
      !!user?.stats?.total_final_won ? user.stats.total_final_won / 10 : 0,
  },

  // RATION WIN LOSE

  // 10 games moins de 2 victoires
  LOSER: {
    name: 'Perdant professionnel',
    condition: user =>
      !!user?.stats?.total_games && user.stats.total_games  >= 10 && user.stats.total_final_won <= (user?.stats?.total_games / 5),
    conditionText: "Gagner moins d'1/5 des parties jouées",
    icon: 'ion:sad',
    color: 'black',
    hide:true
  },
  // 10 games plus de 8 victoires
  WINNER: {
    name: 'Né pour gagner',
    condition: user =>
      !!user?.stats?.total_games && user.stats.total_games  >= 10 && user.stats.total_final_won >= (user?.stats?.total_games * 4/5),
    conditionText: "Gagner au moins 4/5 des parties jouées",
    icon: 'twemoji:trophy',
    hide:true
  },


  // BRACELETS
  HAPPY_BIRTHDAY: {
    name: 'Joyeux anniversaire',
    condition: user =>
      !!user?.stats?.total_bracelets_sent &&
      user.stats.total_bracelets_sent >= 1,
    conditionText: 'Offrir un bracelet à un joueur',
    icon: 'mingcute:birthday-2-line',
    hide: true,
    percent: user =>
      !!user?.stats?.total_bracelets_sent
        ? user.stats.total_bracelets_sent / 1
        : 0,
  },


  // OTHER
  PREMIUM: {
    name: 'Poisson doré',
    condition: user => !!user?.premium,
    conditionText: 'Être membre premium',
    icon: 'openmoji:goldfish',
  },

  // OTHERS FOR FUN
  PIOURIXX: {
    name: 'Personnage suspect',
    condition: user =>
      !!user?.name && user.name.toLowerCase().includes('piourixx'),
    conditionText: "S'appeler PiouRixx",
    hide: true, // on affiche pas le conditionText du coup
    icon: 'tabler:brand-among-us',
  },
  GIULFEU: {
    name: 'Petite ******',
    condition: user =>
      !!user?.name && user.name.toLowerCase().includes('giulfeuyt'),
    conditionText: "S'appeler giulfeuYT",
    hide: true,
    icon: 'game-icons:devil-mask',
  },
  DARC_CIAD: {
    name: 'Le tryhardeur',
    condition: user =>
      !!user?.name && user.name.toLowerCase().includes('darc ciad'),
    conditionText: "S'appeler darc ciad",
    hide: true,
    icon: 'game-icons:strong-man',
  },
};

export default userTitles;
