import { useToast } from '@chakra-ui/react';

const useSuccess = (callback = () => {}) => {
  const toast = useToast();
  const onSuccess = success => {
    toast({
      position: 'top',
      description: success,
      status: 'success',
      duration: 9000,
      isClosable: true,
    });
    callback();
  };
  return [onSuccess];
};

export default useSuccess;
