import React, { useMemo, useState, useEffect } from 'react';

import { Flex, HStack, Text, useDisclosure } from '@chakra-ui/react';
import { ReactComponent as Villa } from 'svg/villa.svg';

import useSocket from '../../hooks/useSocket';
import useSound from "hooks/useSound"
import { useSelector } from 'react-redux';
import { PlayersInLocation } from './Camp';
import MotionBox from '../MotionBox';
import Button from 'components/Button';
import Icon from 'components/Icon';
import SelectPlayerModal from 'components/Modal/SelectPlayerModal';
import HowManyBetModal from 'components/Modal/HowManyBetModal';
import casinoMusic from "sounds/music/casino.mp3"
import SlotMachineModal from 'components/Modal/SlotMachineModal';
import usePlayerItems from 'hooks/usePlayerItems';

function EliminatedVilla() {
  const socket = useSocket();
  const { myId, gameId, players, me } = useSelector(state => state.game);
  const { items, updateItems } = usePlayerItems();
  const {
    isOpen: isOpenSelect,
    onOpen: onOpenSelect,
    onClose: onCloseSelect,
  } = useDisclosure();
  const {
    isOpen: isOpenBet,
    onOpen: onOpenBet,
    onClose: onCloseBet,
  } = useDisclosure();
  const {
    isOpen: isOpenSlot,
    onOpen: onOpenSlot,
    onClose: onCloseSlot,
  } = useDisclosure();
  const {
    isOpen: isOpenBracelet,
    onOpen: onOpenBracelet,
    onClose: onCloseBracelet,
  } = useDisclosure();
  const [selectPlayer, setSelectedPlayer] = useState();

  const [canBet, setCanBet] = useState(true);

  const playersInLocation = useMemo(
    () => players?.filter(player => !player.alive),
    [players]
  );

  const [playMusic, {stop}] = useSound(casinoMusic);
  useEffect(() => {
    playMusic()
    return () => stop()
  }, [playMusic, stop])

  const handleSelectedPlayer = ({ to }) => {
    console.log(myId, 'je vais miser sur', to);
    setSelectedPlayer(to);
    onCloseSelect();
    onOpenBet();
  };

  const handleValidateBet = value => {
    socket.emit('sendBet', {
      on: selectPlayer,
      bet: value,
      from: myId,
      gameId,
    });
    setCanBet(false);
  };

  const handleValidateGiveBracelet = ({ to: toBracelet, message: messageBracelet }) => {
    updateItems({ bracelets: items.bracelets - 1 });
    socket.emit('sendBracelet', {
      from: myId,
      to: toBracelet,
      message: messageBracelet,
      gameId,
    });
  };

  return (
    <Flex
      direction="column"
      justify="center"
      textAlign="center"
      align="center"
      w="100%"
      h="100%"
    >
      {/* <Text fontWeight="bold" mt="5" textAlign="center" w="100%">Les joueurs sont à : {location}</Text> */}
      <Flex justify="center">
        <MotionBox
          initial={{ filter: 'brightness(0.7)' }}
          animate={{
            filter: ['brightness(0.8)', 'brightness(1.2)', 'brightness(0.9)'],
          }}
          transition={{
            ease: 'easeInOut',
            repeat: Infinity,
            repeatType: 'reverse',
            repeatDelay: 0.1,
            duration: 2,
          }}
        >
          <Villa
            transition="all 0.5s ease-in-out"
            style={{ height: '40vh', width: 'auto' }}
          />
        </MotionBox>
      </Flex>
      <PlayersInLocation players={playersInLocation} />
      <Text my="5" fontWeight="extrabold" fontSize="xl">
        Vous avez été éliminé, mais le jeu n'est pas terminé, vous pouvez encore
        réintégrer l'aventure
      </Text>
      <HStack>
      <Button
        isDisabled={!items?.bracelets}
        onClick={onOpenBracelet}
        bg="gold"
        color="white"
        rightIcon={<Icon boxSize="7" icon="streamline:give-gift" />}
      >
        Offrir un bracelet ({items?.bracelets})
      </Button>

      <Button
        isDisabled={!me.coins || !canBet}
        onClick={onOpenSelect}
        bg="sky"
        color="white"
        rightIcon={<Icon boxSize="7" icon="mdi:casino-chip" />}
      >
        Miser
      </Button>
      <Button
        // isDisabled={!me.coins}
        onClick={onOpenSlot}
        bg="red"
        color="white"
        rightIcon={<Icon boxSize="7" icon="fluent-emoji-high-contrast:slot-machine" />}
      >
        Machine à sous
      </Button>
      </HStack>

      <Text
        fontWeight="bold"
        stroke="5px "
        fontSize="xl"
        textAlign="center"
        w="100%"
      >
        Faites vos jeux, qui sera le prochain éliminé ?
      </Text>
      <SelectPlayerModal
        title="Misez sur le joueur qui pourrait partir ce soir"
        isOpen={isOpenSelect}
        onClose={onCloseSelect}
        noInput
        onValidate={handleSelectedPlayer}
        forcePlayers={players.filter(player => !player.palm && player.alive)}
      />
      <HowManyBetModal
        onClose={onCloseBet}
        onValidate={handleValidateBet}
        isOpen={isOpenBet}
        max={me?.coins || 0}
      />
      <SlotMachineModal isOpen={isOpenSlot} onClose={onCloseSlot} />
      <SelectPlayerModal
        forcePlayers={players.filter(player => player.alive)}
        onValidate={handleValidateGiveBracelet}
        title="Faites don d'un bracelet que vous avez gardé"
        isOpen={isOpenBracelet}
        onClose={onCloseBracelet}
      />
    </Flex>
  );
}

export default EliminatedVilla;
