import React from 'react';
import { Grid, GridItem, HStack, useDisclosure } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import Lifebar from './Lifebar';
import EmeraldPalm from './EmeralPalm';
import ImmunityBracelet from './ImmunityBracelet';
import FishFood from './FishFood';
import TeamPlayersStatModal from '../Modal/TeamPlayersStatModal';
import Coin from './Coins';
import { LOCATIONS } from 'constants';
import Fire from 'components/Interface/Fire';
import Icon from 'components/Icon';
import { TEAMS } from 'constants';
import useSocket from 'hooks/useSocket';
import IconButton from 'components/Button/IconButton';
import InventoryModal from 'components/Modal/InventoryModal';

function Interface() {
  const { me, location, gameId, myId } = useSelector(state => state.game);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { isOpen: isOpenInventory, onClose: onCloseInventory, onOpen:OnOpenInventory } = useDisclosure();
  const socket = useSocket()

  const canOpenInventoryLocations=[LOCATIONS.ACTIVITY.CAMPFIRE, LOCATIONS.ACTIVITY.FISHING, LOCATIONS.ACTIVITY.BRACELET, LOCATIONS.ACTIVITY.INVENTORY, LOCATIONS.CAMP]

  const handleChangeTeam = () => {
    socket.emit("changeTeam", {gameId, myId})
  }

  return (
    <Grid
      templateColumns="repeat(9, 1fr)"
      w="100%"
      maxW="100vw"
      position="absolute"
      top="0"
      left="0"
      p="2"
      pt="3"
    >
      {me?.alive ? (
        <>
          <GridItem
            colSpan={3}
            alignSelf="baseline"
            justifySelf="center"
            w="100%"
          >
            <Lifebar value={me?.lifeBar} />
          </GridItem>
          <GridItem
            cursor="pointer"
            onClick={onOpen}
            colSpan={3}
            alignSelf="center"
            justifySelf="center"
          >
          <IconButton boxSize={14} px="0" m="0" minW="fit-content" onClick={onOpen} icon="fluent:people-team-16-filled" iconColor={`${me?.team}Team`} bg="gold" />
          </GridItem>
          <GridItem alignSelf="baseline" justifySelf="center" colSpan={1}>
            <EmeraldPalm hasOne={me?.palm} />
          </GridItem>
          <GridItem alignSelf="baseline" justifySelf="center" colSpan={1}>
          {location === LOCATIONS.ACTIVITY.BRACELET ||location === LOCATIONS.ACTIVITY.CAMPFIRE ? 
            <Fire value={me?.fire} /> : location === LOCATIONS.ACTIVITY.FISHING ? 
            <FishFood value={me?.fishes} /> : <ImmunityBracelet value={me?.bracelets}/>
          }
          </GridItem>
          <GridItem alignSelf="baseline" justifySelf="center" colSpan={1}>
            <IconButton disabled={!canOpenInventoryLocations.includes(location)} boxSize={10} px="0" m="0" minW="fit-content" onClick={OnOpenInventory} icon="game-icons:locked-chest" iconColor="black" bg="gold" />
          </GridItem>
        </>
      ) : me.spectator ? 
          <GridItem
            colSpan={10}
            alignSelf="center"
            justifySelf="center"
          >
            <HStack>
            <IconButton boxSize={14} px="0" m="0" minW="fit-content" onClick={onOpen} icon="fluent:people-team-16-filled" iconColor={`${me?.team}Team`} bg="gold" />
             {me?.team && me.team !== TEAMS.BLACK && <Icon icon="eva:swap-outline" onClick={handleChangeTeam} cursor="pointer" />}
            </HStack>
          </GridItem>
       : (
        <>
          <GridItem
            p="5"
            w="100%"
            colSpan={8}
            alignSelf="baseline"
            justifySelf="center"
          ></GridItem>
          <GridItem alignSelf="baseline" colSpan={1}>
            <Coin value={me?.coins} />
          </GridItem>
        </>
      )}

      {/* modal (not really interface) */}
      <TeamPlayersStatModal isOpen={isOpen} onClose={onClose} />
      <InventoryModal isOpen={isOpenInventory} onClose={onCloseInventory} />
    </Grid>
  );
}

export default Interface;
