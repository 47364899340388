import { Text, HStack, VStack } from '@chakra-ui/react';
import React from 'react';
import TeamIcon from './TeamIcon';
import { useSelector } from 'react-redux';
import { TEAMS } from '../../constants';

const TeamScores = () => {
  const { teams } = useSelector(state => state.game);
  return (
    <HStack position="relative" gap={12}>
      <VStack gap={10}>
        <TeamIcon color="redTeam" />
        <Text color="sky" fontWeight="extrabold" fontSize="2xl">
          {teams[TEAMS.RED]?.score || 0}
        </Text>
      </VStack>
      <VStack gap={10}>
        <TeamIcon color="blueTeam" />
        <Text color="sky" fontWeight="extrabold" fontSize="2xl">
          {teams[TEAMS.BLUE]?.score || 0}
        </Text>
      </VStack>
    </HStack>
  );
};

export default TeamScores;
