import React from 'react';
import { Flex, Image } from '@chakra-ui/react';
import useSound from '../../hooks/useSound';

const MemoryButton = ({ imageSrc, soundSrc, type, onClick, disabled }) => {
  const [play] = useSound(soundSrc);

  const handleClick = () => {
    if (disabled) return;
    console.log('click');
    play();
    onClick && onClick(type);
  };

  return (
    <Flex
      onClick={handleClick}
      w={{ base: '40%', md: '20%' }}
      h="10vh"
      cursor={disabled ? 'unset' : 'pointer'}
      overflow="hidden"
      rounded="25px"
      transition="transform 0.2s ease-in-out"
      _hover={{ transform: 'scale(1.05)' }}
      bg="white"
      alignItems="center"
      justify="center"
      opacity={disabled ? '0.5' : '1'}
      m="2"
      p="1"
    >
      <Image src={imageSrc} h="90%" maxW="100%" alt={type} />
    </Flex>
  );
};

export default MemoryButton;
