import React from 'react';
import Player from '../Player/Player';

function PlayersAbsolute({
  canDelete = false,
  players,
  facingRight = false,
  displayCondition = () => true,
}) {
  return (
    <>
      {players.map(
        (player, index) =>
          displayCondition(player) && (
            <Player
              canDelete={canDelete}
              key={player.id}
              {...player}
              facingRight={player.facingRight ?? facingRight ?? index % 2}
              avatar={player?.avatar}
              title={player?.title}
            />
          )
      )}{' '}
    </>
  );
}

export default PlayersAbsolute;
