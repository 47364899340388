import React, { useState, useEffect, useRef, useMemo } from 'react';
import { VStack } from '@chakra-ui/react';
import PixiCanvas from 'components/PixiCanvas';
import Mole from './Mole';
import PlayerScore from 'components/Player/PlayerScore';
import TeamScores from 'components/Teams/TeamScores';
import { useSelector } from 'react-redux';
import useSocket from 'hooks/useSocket';
import AxeElement from 'components/Camp/Bracelet/AxeElement';
import hammerSvg from "svg/hammer.svg"
import usePixi from 'hooks/usePixi';
import useSound from 'hooks/useSound';

import popSound from "sounds/pop.mp3"
import rightSound from 'sounds/lucky.mp3';

const numMoles = 9;


function WhackAMole() {
  const [moles, setMoles] = useState(Array(numMoles).fill(false));
  const [speed, setSpeed] = useState(800);
  const {width, height} = usePixi()
  const { me, myId, gameId, reunification } = useSelector(state => state.game);
  const socket = useSocket();
  const canvasRef = useRef();
  const [playPop] = useSound(popSound);
  const [playRight] = useSound(rightSound);

  const positions =  useMemo(() => ([
    { x: width/3, y: height/3 },
    { x: width/2, y: height/3 },
    { x: 2*width/3, y: height/3 },
    { x: width/3, y: height/2 },
    { x: width/2, y: height/2 },
    { x: 2*width/3, y: height/2 },
    { x: width/3, y: 2*height/3 },
    { x: width/2, y: 2*height/3 },
    { x: 2*width/3, y: 2*height/3 },
  ]), [width, height] )

  // to trigger the error / success animation

  const showRandomMole = () => {
    const newMoles = Array(numMoles).fill(false);
    const randomIndex = Math.floor(Math.random() * numMoles);
    newMoles[randomIndex] = true;
    setMoles(newMoles);
  };

  const handleMoleClick = (index) => {
    if (!me.canMove || !me.canPlay) return;
    if (moles[index]) {
      playRight()
      socket.emit('touch-target', { gameId, myId });
      setSpeed(speed - 10);
      showRandomMole();
    }
  };

  const handleMiss = (e) => {
    if (!me.canMove || !me.canPlay) return;
    playPop()


    const rect = canvasRef.current.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;


    let hit = false;
    for (let i = 0; i < moles.length; i++) {
      const position = positions[i];

      if (moles[i] &&
        x >= position.x - 35 && x <= position.x + 35 &&
        y >= position.y - 35 && y <= position.y + 35) {
        hit = true;
        handleMoleClick(i);
        break;
      }
    }

    if (!hit) {
      socket.emit('shoot-arrow', { gameId, myId });
    }
  };

  useEffect(() => {
    if (!me.canMove || !me.canPlay) return;
    const interval = setInterval(showRandomMole, speed);

    const handleCanvasClick = (e) => {
      handleMiss(e);
    };

    const canvasElement = canvasRef.current;

    if (canvasElement) {
      canvasElement.addEventListener('click', handleCanvasClick);
    }

    return () => {
      clearInterval(interval);
      if (canvasElement) {
        canvasElement.removeEventListener('click', handleCanvasClick);
      }
    };
    // eslint-disable-next-line
  }, [speed, me]);

  return (
    <VStack spacing={4}>
      {reunification ? <PlayerScore /> : <TeamScores />}
      <PixiCanvas customRef={canvasRef} cursor="pointer">
        {moles.map((mole, index) => (
          <Mole key={index} index={index} visible={mole} positions={positions} />
        ))}
        <AxeElement image={hammerSvg} />
      </PixiCanvas>
    </VStack>
  );
}

export default WhackAMole;
