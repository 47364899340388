import React, { useMemo, useState, useEffect } from 'react';

import { Flex, Text, useDisclosure } from '@chakra-ui/react';
import { ReactComponent as Fire } from '../../svg/ActivityCampfire.svg';
import campMusic from 'sounds/music/campfire.mp3'

import successPercent from "utils/successPercent"

import useSocket from 'hooks/useSocket';
import usePlayerItems from 'hooks/usePlayerItems';
import useSound from "hooks/useSound"
import { useSelector } from 'react-redux';
import { LOCATIONS } from '../../constants';
import { PlayersInLocation } from './Camp';
import MotionBox from '../MotionBox';
import Chat from '../Modal/Chat';
import Button from "components/Button"
import Icon from "components/Icon"

import {FireModal} from "components/Interface/Fire"
import SelectPlayerModal from 'components/Modal/SelectPlayerModal';
import InventoryModal from 'components/Modal/InventoryModal';
import useLocalStorage from 'hooks/useLocalStorage';
import Tuto from 'games/Tuto';

function Campfire() {
  const socket = useSocket();

  const { myId, gameId, myTeam, me, isDemo } = useSelector(state => state.game);
  const [isDisabled, setIsDisabled] = useState(false);
  const [playMusic, {stop}] = useSound(campMusic);
  const {isOpen: isOpenFindFire, onOpen: onOpenFindFire, onClose: onCloseFindFire} = useDisclosure()
  const {isOpen: isOpenSearchBag, onOpen: onOpenSearchBag, onClose: onCloseSearchBag} = useDisclosure()
  const { updateItems, items } = usePlayerItems();


  useEffect(() => {
    playMusic()
    return () => stop()
  }, [playMusic, stop])

  const playersInLocation = useMemo(
    () =>
      myTeam?.players?.filter(
        player => player.location === LOCATIONS.ACTIVITY.CAMPFIRE
      ),
    [myTeam]
  );

  const [searchBagId, setSearchBagId] = useState(null)
  const [percentCanSearchBag, setPercentSearchBag] = useState(successPercent(10))
  const canSearchBag = useMemo(() => playersInLocation?.length === 1 && percentCanSearchBag , [playersInLocation, percentCanSearchBag])
  const handleValidateSearchBag = ({ to }) => {
    setSearchBagId(to)
  };

  useEffect(() => {
    if (!canSearchBag && searchBagId) {
      setSearchBagId(null)
      setPercentSearchBag(false)
      socket.emit('sendChat', {
        from: myId,
        team: myTeam.name,
        gameId,
        message: "Tu m'as surpris alors que je fouillais un sac ..."
      });
    }
  }, [canSearchBag, gameId, myId, myTeam.name, searchBagId, socket])

  const handleClickFire = () => {
    setIsDisabled(true);
    setTimeout(() => {
      setIsDisabled(false);
    }, 2000);
    console.log('sendfire');
    socket.emit('sendFire', {
      from: me.firstId,
      team: myTeam.name,
      gameId,
    });

    if (successPercent(5)) {
      onOpenFindFire()
      updateItems({ fire: items?.fire + 1 })
    }
  };

  const [displayTuto, setDisplayTuto] = useLocalStorage('campfire-tuto', true);
  if (isDemo && displayTuto)
    return (
      <Tuto
        canSkip
        timeout={15000}
        id={LOCATIONS.ACTIVITY.CAMPFIRE}
        setDisplay={setDisplayTuto}
      />
    );

  return (
    <Flex
      direction="column"
      justify="center"
      textAlign="center"
      align="center"
      w="100%"
      h="100%"
    >
      <Flex
        justify="center"
        cursor={isDisabled ? 'auto' : 'pointer'}
        onClick={!isDisabled ? handleClickFire : undefined}
        filter={isDisabled ? 'grayscale(100%)' : 'unset'}
      >
        <MotionBox
          initial={{ filter: 'brightness(0.7)', scale: 0.9 }}
          animate={{
            scale: [0.95, 1.05, 1],
            filter: ['brightness(0.8)', 'brightness(1.2)', 'brightness(0.9)'],
          }}
          transition={{
            ease: 'easeInOut',
            repeat: Infinity,
            repeatType: 'reverse',
            repeatDelay: 0.1,
            duration: 4,
          }}
        >
          <Fire
            transition="all 0.5s ease-in-out"
            style={{ height: '40vh', width: 'auto' }}
          />
        </MotionBox>
      </Flex>
      <PlayersInLocation players={playersInLocation} />
      <Text my="5" color="white" fontWeight="extrabold" fontSize="xl">
        {isDisabled
          ? "+2 de vie pour toute l'équipe sauf vous"
          : "Cliquez sur le feu pour redonner un peu de vie à toute l'équipe (sauf vous)"}
      </Text>
      <Text mt="5" textAlign="center" w="100%" color="white">
        Partagez votre état d'ésprit avec les autres candidats
      </Text>
      <Chat />
      <Button
        bg="black"
        color="white"
        rightIcon={<Icon icon="mdi:robber" />}
        fontSize="lg"
        p="2"
        zIndex="2"
        transition="opacity 2s ease-in-out"
        opacity={canSearchBag && !searchBagId ? 1 : 0}
        cursor={canSearchBag && !searchBagId ? "pointer" : "unset"}
        onClick={canSearchBag && !searchBagId ? onOpenSearchBag : undefined}
      >
        Fouiller le sac d'un candidat
      </Button>
      <FireModal isOpen={isOpenFindFire} onClose={onCloseFindFire} discover />
      <SelectPlayerModal
        onValidate={handleValidateSearchBag}
        title="Quel sac voulez vous fouiller ?"
        isOpen={isOpenSearchBag && canSearchBag}
        onClose={onCloseSearchBag}
        noInput
      />
      <InventoryModal isOpen={!!searchBagId && canSearchBag} onClose={() => setSearchBagId(undefined)}  playerId={searchBagId} />
    </Flex>
  );
}

export default Campfire;
