export type UserStats = {
  total_fishes: number;
  total_fishes_sent: number;
  total_life_restored: number;
  total_bracelets: number;
  total_bracelets_used: number;
  total_bracelets_sent: number;
  total_fire_sent: number;
  total_game_won: number;
  total_messages_sent: number;
  total_forest_search: number;
  total_vote_received: number;
  total_tie_break_lose: number;
  total_coins: number;
  total_final_won: number;
  total_games: number;
};

enum AuthProvider {
  GOOGLE = 'google',
  LOCAL = 'local',
}

type FirebaseUser = {
  authProvider: AuthProvider;
  name: string;
  email: string;
  premium: boolean;
  uid: string; // id for auth
  ref: string; // id for update user data
  avatar?: string;
  stats?: UserStats;
  currentTitle?: string;
  titles?: string[];
};

export default FirebaseUser;
