import Modal from './Modal';
import { ModalHeader, Grid, GridItem } from '@chakra-ui/react';
import Player from '../Player/Player';

import { useSelector } from 'react-redux';
import TeamIcon from '../Teams/TeamIcon';
import Lifebar from '../Interface/Lifebar';
import { noDoublonPlayers } from '../../utils/noDoublonPlayers';
import { useState } from 'react';
import InventoryModal from './InventoryModal';
import IconButton from 'components/Button/IconButton';

function TeamPlayersStatModal({ isOpen, onClose = () => {} }) {
  const { myTeam, myId, me } = useSelector(state => state.game);
  const [selectedPlayerId, setSelectedPlayerId] = useState(undefined)

  if (!myTeam) return


  return (
    <Modal
      onClose={() => {
        onClose();
      }}
      isOpen={isOpen}
      bg="loser"
      size="lg"
      isCentered={false}
    >
      <ModalHeader fontSize="2xl" align="center" color="white">
        <TeamIcon color={myTeam?.name || 'black'} />
      </ModalHeader>
      <Grid
        templateColumns={`repeat(2, 30%)`}
        w="100%"
        alignItems="center"
        justifyContent="center"
        p="1"
      >
        {myTeam?.players
          ? noDoublonPlayers(myTeam.players)
              .filter(({ id }) => id !== myId)
              .map(player => <PlayerStat onClick={() => setSelectedPlayerId(player?.id)} spectator={me.spectator} key={player?.id} player={player} />)
          : null}
      </Grid>
      <InventoryModal playerId={selectedPlayerId} isOpen={!!selectedPlayerId} onClose={() => setSelectedPlayerId(undefined)} />
    </Modal>
  );
}

function PlayerStat({ onClick, player, spectator=false }) {

  return (
    <GridItem colSpan={1} m="3">
      <Player
        h="auto"
        w="auto"
        position="initial"
        name={player?.name}
        avatar={player?.avatar}
        title={player?.title}
      />
      <GridItem colSpan={3} alignSelf="center" justifySelf="center" w="100%" textAlign="center">
        <Lifebar value={player.lifeBar} />
        {spectator && <IconButton boxSize={8} px="0" mx="0" minW="fit-content" onClick={onClick} icon="game-icons:locked-chest" iconColor="black" bg="gold" />}
            
      </GridItem>
    </GridItem>
  );
}

export default TeamPlayersStatModal;
