import Fish from 'svg/Fish.svg';
import GrilledFish from 'svg/grilledFish.svg';
import Bracelet from 'svg/Bracelet.svg';
import Palm from 'svg/Palm.svg';
import Axe from 'svg/axe.svg';
import GoldAxe from 'svg/gold-axe.svg';
import Net from 'svg/fish-net.svg';
import Wood from 'svg/wood.svg';
import Web from 'svg/web.svg';

// you also need to declare these inthe back
export enum ITEM_NAME {
  FISHES = "fishes",
  BRACELETS = "bracelets",
  PALM = "palm",
  FIRE = "fire",

  // Collectibles
  WOOD = "wood",
  WEB = "web",
  
  // Craft items
  NET = "net",
  AXE = "axe",
  GOLD_AXE = "goldAxe",
  GRILLED_FISH = "grilledFish",
}

export enum ITEM_TYPE {
  COLLECTIBLES = "collectibles",
  CRAFT_ITEMS = "craftItems"
}

type Item = {
  name: ITEM_NAME;
  canEat?: boolean;
  canGive?: boolean;
  canCraft?: boolean;
  image?: any;
  type?: ITEM_TYPE;
  hp?: number;
}

type Items = {
  [key in ITEM_NAME]: Item;
};


const ITEMS: Items = {
  [ITEM_NAME.PALM]: {
    name: ITEM_NAME.PALM,
    image:Palm,
  },
  [ITEM_NAME.FISHES]: {
    name: ITEM_NAME.FISHES,
    canEat: true,
    canGive: true,
    image: Fish,
    hp: 4,
  },
  [ITEM_NAME.GRILLED_FISH]: {
    name: ITEM_NAME.GRILLED_FISH,
    canCraft: true,
    canEat: true,
    canGive: true,
    image: GrilledFish,
    type: ITEM_TYPE.CRAFT_ITEMS,
    hp:50,
  },
  [ITEM_NAME.NET]: {
    name: ITEM_NAME.NET,
    canGive: true,
    canCraft: true,
    image: Net,
    type: ITEM_TYPE.CRAFT_ITEMS
  },
  [ITEM_NAME.AXE]: {
    name: ITEM_NAME.AXE,
    image: Axe,
    type: ITEM_TYPE.CRAFT_ITEMS
  },
  [ITEM_NAME.GOLD_AXE]: {
    name: ITEM_NAME.GOLD_AXE,
    image: GoldAxe,
    type: ITEM_TYPE.CRAFT_ITEMS,
    canCraft: true,
  },
  [ITEM_NAME.BRACELETS]: {
    name: ITEM_NAME.BRACELETS,
    canGive: true,
    image: Bracelet
  },
  [ITEM_NAME.FIRE]: {
    name: ITEM_NAME.FIRE,
  },
  [ITEM_NAME.WOOD]: {
    name: ITEM_NAME.WOOD,
    canGive: true,
    image: Wood,
    type: ITEM_TYPE.COLLECTIBLES
  },
  [ITEM_NAME.WEB]: {
    name: ITEM_NAME.WEB,
    canGive: true,
    image: Web,
    type: ITEM_TYPE.COLLECTIBLES
  },
}


type Ingredient = {
  item: Item;
  quantity: number;
}
type Recipes = {
  [key in ITEM_NAME]?: Ingredient[];
};

const RECIPES: Recipes = {
  [ITEM_NAME.GRILLED_FISH]: [
    {
      item: ITEMS[ITEM_NAME.FISHES],
      quantity: 1
    },
    {
      item: ITEMS[ITEM_NAME.FIRE],
      quantity: 1
    },
  ],
  [ITEM_NAME.NET]: [
    {
      item: ITEMS[ITEM_NAME.WEB],
      quantity: 10,
    },
  ],
  [ITEM_NAME.GOLD_AXE]: [
    {
      item: ITEMS[ITEM_NAME.WEB],
      quantity: 1,
    },
    {
      item: ITEMS[ITEM_NAME.WOOD],
      quantity: 75,
    },
    {
      item: ITEMS[ITEM_NAME.AXE],
      quantity: 1,
    },
  ],
}

export {ITEMS, RECIPES};

