import React, { useEffect } from 'react';
import Modal from './Modal';
import { ModalHeader, Flex, Text } from '@chakra-ui/react';
import Button from '../Button/Button';
import { ReactComponent as Hint } from '../../svg/hint.svg';
import { svgSize } from '../Interface/constants';
import hintSound from '../../sounds/hint.mp3';
import useSound from '../../hooks/useSound';

function HintModal({ isOpen, onClose, hint = 'indice' }) {
  const [play, { stop }] = useSound(hintSound);
  useEffect(() => {
    if (isOpen) play();
    return () => {
      stop();
    };
  }, [isOpen, play, stop]);

  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      bg="primary"
      size="md"
      closeOnOverlayClick={false}
    >
      <ModalHeader fontSize="2xl" align="center" color="white">
        Vous venez de trouver un indice sur les bracelets :
      </ModalHeader>
      <Flex
        direction="column"
        p="3"
        fontSize="xl"
        align="center"
        justify="center"
        textAlign="center"
      >
        <Hint style={{ height: svgSize }} />
        <Text p={3}>{hint}</Text>
      </Flex>
      <Flex align="center" justify="center">
        <Button onClick={onClose}>OK!</Button>
      </Flex>
    </Modal>
  );
}

export default HintModal;
