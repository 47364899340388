import React, { useEffect, useState } from 'react';
import { Progress, Grid, GridItem } from '@chakra-ui/react';
import Icon from '../Icon/Icon';
import MotionBox from 'components/MotionBox';

const Lifebar = ({ value = 100 }) => {
  const [animation, setAnimation] = useState({});

  useEffect(() => {
    if (value > 50) {
      setAnimation({
        opacity:1,
        scale: [1, 1.1, 1],
        transition: { duration: 0.5, repeat: 1, repeatType: "loop" },
      });
    } else if (value > 20) {
      setAnimation({
        opacity:1,
        x: [0, -2, 2, -2, 2, 0],
        transition: { duration: 0.5, repeat: 2, repeatType: "loop" },
      });
    } else {
      setAnimation({
        opacity: [0, 1, 0],
        transition: { duration: 1, repeat: Infinity, repeatType: "loop" },
      });
    }
  }, [value]);

  return (
    <MotionBox
      initial={{ scale: 1 }}
      animate={animation}
      key={value}
    >
      <Grid templateColumns="repeat(5, 1fr)">
        <GridItem colSpan={4} w="100%" justifySelf="center" alignSelf="center">
          <Progress
            colorScheme={value > 30 ? "progressBar" : "red"}
            size="md"
            value={value}
            bg={"#76CBE544"}
            borderRadius="15px"
          />
        </GridItem>
        <GridItem colSpan={1} justifySelf="start" alignSelf="center">
          <Icon
            color={value > 30 ? "primary" : "red"}
            icon={value > 30 ? "fluent:heart-12-filled" : "fluent:heart-pulse-error-20-filled"}
            boxSize={6}
            display="flex"
          />
        </GridItem>
      </Grid>
    </MotionBox>
  );
};

export default Lifebar;
