import React, { useEffect, useState } from 'react';
import { ReactComponent as Palm } from '../../svg/Palm.svg';
import { svgSize } from './constants';
import MotionBox from 'components/MotionBox';
import useSound from 'hooks/useSound';
import win from 'sounds/win.mp3';
import lose from 'sounds/lose.mp3';
import { useSelector } from 'react-redux';
import { LOCATIONS } from 'constants';

const EmeraldPalm = ({ hasOne = false }) => {
  const { options } = useSelector(state => state.user);
  const { location } = useSelector(state => state.game);
  const [playWin, {stop: stopWin}] = useSound(win);
  const [playLose, {stop: stopLose}] = useSound(lose);
  const [playedSound, setPlayedSound] = useState(false)
  const [animation, setAnimation] = useState({});

  useEffect(() => {
    if (![LOCATIONS.CAMP, LOCATIONS.ACTIVITY.BRACELET, LOCATIONS.ACTIVITY.FISHING, LOCATIONS.ACTIVITY.INVENTORY, LOCATIONS.ACTIVITY.CAMPFIRE].includes(location)) {
      setAnimation({});
      setPlayedSound(false)
      return;
    }
    if (playedSound) return

    if (hasOne) {
      playWin({ forceSoundEnabled: options.sound });
      setTimeout(() => {
        setPlayedSound(true)
      }, 1000)
      setAnimation({
        opacity: 1,
        scale: [0, 1.2, 1],
        rotate: [0, 15, -15, 0],
        transition: { duration: 1 },
      });
    } else {
      playLose({ forceSoundEnabled: options.sound });
      setTimeout(() => {
        setPlayedSound(true)
      }, 1000)
      setAnimation({
        opacity: [0, 0.3],
        x: [0, -10, 10, 0],
        scale: [0.8, 1],
        transition: { duration: 1 },
      });

      return () => {
        stopWin()
        stopLose()
      }
    }
  }, [playWin, playLose, hasOne, location, options.sound, stopWin, stopLose, playedSound]);

  return (
    <MotionBox
      opacity={hasOne ? '1' : '0.3'}
      key={hasOne}
      mt="2"
      direction="column"
      align="center"
      zIndex="2"
      animate={animation}
    >
      <Palm style={{ height: svgSize }} />
    </MotionBox>
  );
};

export default EmeraldPalm;
