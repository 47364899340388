import { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useNavigate } from 'react-router-dom';
import {
  auth,
  registerWithEmailAndPassword,
  signInWithGoogle,
} from '../../myFirebase';
import { Box, FormControl, FormLabel, Checkbox } from '@chakra-ui/react';
import Link from '../Link/Link';
import Input from '../Input/Input';
import Button from '../Button/Button';
import Form from '../Form/Form';
import useError from '../../hooks/useError';

function Register({redirect=""}) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [agreedToTerms, setAgreedToTerms] = useState(false); // Nouveau state pour gérer l'acceptation des CGU
  const [user, loading] = useAuthState(auth);
  const navigate = useNavigate();
  const [onError] = useError();
  const register = () => {
    if (!name) alert('Please enter name');
    registerWithEmailAndPassword(name, email, password, onError);
  };

  useEffect(() => {
    if (loading) return;
    if (user) {
      if (redirect) {
        window.location.replace(redirect)
        return
      }
      navigate(`/`);
    }
  }, [user, loading, navigate, redirect]);

  return (
    <>
      <Form
        onSubmit={e => {
          e.preventDefault();
          register();
        }}
      >
        <FormControl isRequired>
          <FormLabel>Username</FormLabel>
          <Input
            type="text"
            value={name}
            onChange={e => setName(e.target.value)}
            placeholder="Username"
            autoComplete="username"
            autoFocus
          />
        </FormControl>
        <FormControl isRequired>
          <FormLabel>Email</FormLabel>
          <Input
            value={email}
            onChange={e => setEmail(e.target.value)}
            placeholder="E-mail Address"
            type="email"
            autoComplete="email"
          />
        </FormControl>
        <FormControl isRequired>
          <FormLabel>Password</FormLabel>
          <Input
            type="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
            placeholder="Password"
            autoComplete="current-password"
            minLength="6"
          />
        </FormControl>
        <Checkbox
          isChecked={agreedToTerms}
          onChange={e => setAgreedToTerms(e.target.checked)}
          my="4"
          size="lg"
          bg="white"
          p="5"
          colorScheme="green"
          isInvalid={!agreedToTerms}
          w="100%"
          border="1px solid black"
        >
          J'ai lu et j'accepte les{' '}
          <Link target="_blank" color="blue.500" as={Link} to="/conditions">
            Conditions Générales d'Utilisation
          </Link>
        </Checkbox>
        <Button w="100%" my="4" type="submit" isDisabled={!agreedToTerms}>
          Créer
        </Button>
      </Form>
      <Button
        my="3"
        bg="#4285f4"
        color="white"
        onClick={() => signInWithGoogle(onError)}
        isDisabled={!agreedToTerms} // Désactive le bouton si les CGU ne sont pas acceptées
      >
        Créer avec Google
      </Button>
      <Box fontSize="2xl">
        Déjà enregistré ? <Link to={`/login${redirect ? `?redirect=${redirect}` : ""}`}>Se connecter</Link>
      </Box>
    </>
  );
}
export default Register;
