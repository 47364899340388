import React, { useState, useEffect, useReducer } from 'react';

import { Grid, GridItem, Box, Image } from '@chakra-ui/react';

import PalmTree from './forestAssets/palmTree.svg';
import Rock from './forestAssets/rock.svg';
import Trees from './forestAssets/trees.svg';

import { ReactComponent as BraceletSvg } from '../../svg/Bracelet.svg';

import { randomInArray } from '../../utils/random';
import { animate } from 'motion';

import discover from '../../sounds/discover.mp3';

import usePlayerItems from '../../hooks/usePlayerItems';
import useSound from '../../hooks/useSound';

// if force = true it means there must be a bracelet / item hidden if false there might be nothing to be found

function OldBracelet({
  CustomSvg,
  customSound,
  onFound,
  canClick = true,
  force = false,
}) {
  const [forestArray, setForestArray] = useState([]);
  const [play] = useSound(customSound || discover);
  const { updateItems, items } = usePlayerItems();

  const gridW = 25;
  const gridH = 10;

  const newForest = () => {
    const nElem = gridW * gridH;
    // if not force there is a chance there is no item at all
    const nChance = force ? nElem - 1 : nElem + 100;
    const randomElem = Math.floor(Math.random() * nChance);

    let tmpForestArray = [];
    for (let elem = 0; elem < nElem; elem++) {
      const randomId = '_' + Math.random().toString(36);
      tmpForestArray.push({ id: randomId, hasBracelet: elem === randomElem });
    }
    setForestArray(tmpForestArray);
  };

  const handleFoundBracelet = () => {
    onFound ? onFound() : updateItems({ bracelets: items?.bracelets + 1 });
    play();
    const animation = animate(
      '#BraceletSvgAnimation',
      {
        opacity: [1, 1, 0],
        scale: [1, 10, 12],
        rotate: [0, 85, 100],
        filter: ['brightness(0)', 'brightness(2)', 'brightness(0)'],
      },
      {
        duration: 3,
        offset: [0, 0.85],
      }
    );

    animation.finished.then(() => {
      newForest();
      // animation.cancel()
    });
  };

  useEffect(() => {
    newForest();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Grid
        templateColumns={`repeat(${gridW}, 1fr)`}
        templateRows={`repeat(${gridH}, 1fr)`}
        h="100%"
        w="100%"
        alignItems="center"
      >
        {canClick &&
          forestArray.map(bracelet => (
            <ForestElement
              key={bracelet.id}
              hasBracelet={bracelet.hasBracelet}
              handleFoundBracelet={handleFoundBracelet}
              Elem={randomInArray([Trees, PalmTree, Rock])}
            />
          ))}
      </Grid>

      <Box
        style={{
          width: '50px',
          pointerEvents: 'none',
          opacity: 0,
          position: 'absolute',
          top: '50%',
          left: '47%',
        }}
        id="BraceletSvgAnimation"
      >
        {CustomSvg ? (
          <CustomSvg style={{ width: '100%', height: '100%' }} />
        ) : (
          <BraceletSvg style={{ width: '100%', height: '100%' }} />
        )}
      </Box>
    </>
  );
}

function ForestElement({ hasBracelet = false, handleFoundBracelet, Elem }) {
  const [isClicked, clicked] = useReducer(() => true, false);

  const handleClick = () => {
    clicked();
    if (hasBracelet) {
      handleFoundBracelet();
    } else {
    }
  };

  return (
    <GridItem
      textAlign="center"
      onClick={isClicked ? null : handleClick}
      rowSpan={1}
      colSpan={1}
      cursor="pointer"
      _hover={{ bg: 'gold' }}
      h="100%"
      maxH="100%"
      maxW="100%"
      opacity={isClicked ? 0 : 1}
      transition="all 0.5s ease-in-out"
      bg={process.env.REACT_APP_DEV && hasBracelet ? 'blue' : 'auto'}
    >
      <Image src={Elem} />
    </GridItem>
  );
}

export default OldBracelet;
