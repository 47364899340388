import { useState, useEffect } from 'react';
import useSocket from '../../hooks/useSocket';
import {
  Grid,
  GridItem,
  Text,
  Button,
  VStack,
  Box,
  Heading,
} from '@chakra-ui/react';
import Icon from 'components/Icon';

function Rooms({handleJoin}) {
  const socket = useSocket();
  const [rooms, setRooms] = useState([]);

  useEffect(() => {
    if (!socket) return;

    socket.on('rooms', newRooms => {
      setRooms(newRooms);
    });

    return () => {
      socket.off('rooms');
    };
  }, [socket]);

  const joinRoom = (gameId, maxPlayers, isPublic) => {
    if (maxPlayers <= 1 || !isPublic) {
      // Do not allow joining if maxPlayers is reached or room is not public
      return;
    }

    // Navigate to the join page
    handleJoin && handleJoin(gameId)
  };

  if (!rooms.length) return;

  return (
    <VStack
      w="100%"
      m="2"
      filter="drop-shadow(-21.6355px 37.5774px 2.27742px rgba(0, 0, 0, 0.25));"
      spacing={3}
      p="5"
      bg="gold"
      border="1px solid black"
    >
      <Heading textAlign="center" mb="4" as="h2" size="lg">
        Ces parties vont commencer
      </Heading>
      {rooms.map(room => (
        <Box
          w="100%"
          opacity={room?.options?.isPublic ? '' : '0.8'}
          px="4"
          bg="whitesmoke"
          key={room.gameId}
          borderWidth="1px"
          borderRadius="md"
        >
          <Text fontWeight="bold">
            Créée par {room.host}
          </Text>
          <Grid
            flexWrap="wrap"
            fontSize="2xl"
            templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' }}
            alignItems="center"
            textAlign="center"
          >
            <GridItem px="5">
              <Text>
                Partie {room.options.isPublic ? 'publique' : 'privée'}{' '}
              </Text>
              <Icon
                icon={
                  room?.options?.isPublic
                    ? 'material-symbols:public'
                    : 'material-symbols:lock-outline'
                }
              />
            </GridItem>
            <GridItem>
              <Text>
                {room.nPlayers}/{room.options.maxPlayers} joueurs
              </Text>
            </GridItem>
            <GridItem>
              <Button
                m="2"
                fontSize="2xl"
                onClick={() =>
                  joinRoom(
                    room.gameId,
                    room.options.maxPlayers,
                    room.options.isPublic
                  )
                }
                disabled={
                  room.nPlayers >= room.options.maxPlayers ||
                  !room.options.isPublic
                }
                bg="vegetation"
                color="white"
              >
                Rejoindre
              </Button>
            </GridItem>
          </Grid>
        </Box>
      ))}
    </VStack>
  );
}

export default Rooms;
