import { GAMES, LOCATIONS } from '.';

export const tutoData = {
  [GAMES.ARCHERY]: {
    images: ['./tutos/archery/archery-1.png', './tutos/archery/archery-2.png'],
    texts: [
      'Dans cette épreuve, il vous faut appuyer sur le bouton pour tirer des flêches et tenter de toucher la cible. Chaque cible touchée rapporte 1 point',
      "Mais attention chaque flêche tirée fait perdre de la vie. En équipe si votre vie tombe à 0 vous ferez gagner l'adversaire. En solo vous devrez attendre la fin du temps sans bouger. Gérez bien vos actions !",
    ],
  },
  [GAMES.RELAIS]: {
    images: ['./tutos/relais/relais-1.jpg', './tutos/relais/relais-2.jpg'],
    texts: [
      "Dans cette épreuve, il vous faut appuyer sur le bouton pour faire courrir votre personnage afin d'effecteur un aller-retour et passer le relais à un membre votre équipe",
      "Attention, chaque clic vous fera perdre de la vie, si votre vie tombe à 0 c'est l'équipe adverse qui gagne. Gérez bien vos actions !",
    ],
  },
  [GAMES.MORE_OR_LESS]: {
    images: [
      './tutos/more-or-less/more-or-less-1.jpg',
      './tutos/more-or-less/more-or-less-2.jpg',
    ],
    texts: [
      'Dans cette épreuve vous devrez équilibrer les deux balances. Cliquez sur un objet pour le poser',
      'Aidez vous des indications visuelles pour savoir si vous êtes trop au dessus ou trop en dessous',
    ],
  },
  [GAMES.DODGE]: {
    images: [
      './tutos/dodge/dodge-1.jpg',
      './tutos/dodge/dodge-2.jpg',
      './tutos/dodge/dodge-3.jpg',
      './tutos/relais/relais-2.jpg',
    ],
    texts: [
      'Dans cette épreuve vous devez éviter les différents obstacles qui vont apparaître ! ',
      'Choisissez bien entre les lignes adjacentes pour survivre le plus longtemps possible',
      'Vous pouvez bouger de haut en bas en utilisant les boutons ou les flêches de votre clavier',
      'Attention chaque mouvement vous fera perdre un peu de vie, si vous êtes à court de vie vous serez considéré comme touché',
    ],
  },
  [GAMES.MEMORY]: {
    images: [
      './tutos/memory/memory-1.jpg',
      './tutos/memory/memory-2.jpg',
      './tutos/camp/camp-6.jpg',
    ],
    texts: [
      'Dans cette épreuve de mémoire, soyez attentif aux différents symboles qui vont apparaître ',
      'Si vous avez bien retenu, appuyez dans le bon ordre sur les différents éléments',
      'Vous pouvez vous tromper et recommencer dans le temps imparti. Si vous réussissez, vous accédez à la difficulté supérieur !',
    ],
  },
  [GAMES.BLIND_RUN]: {
    images: [
      './tutos/blindrun/blindrun-1.jpg',
      './tutos/blindrun/blindrun-2.jpg',
    ],
    texts: [
      "L'un des joueurs de votre équipe est aveuglé, guidez le vers le drapeau !",
      'Si vous êtes le joueur aveuglé, suivez les instructions de votre équipe',
    ],
  },
  [GAMES.BATTLESHIPS]: {
    images: [
      './tutos/battleships/battleships-1.jpg',
      './tutos/battleships/battleships-2.jpg',
      './tutos/battleships/battleships-3.jpg',
    ],
    texts: [
      'Placez votre joueur sur une case libre dans le cadrillage',
      'Attendez votre tour puis visez une case adverse',
      "Si vous êtes touché ou que vous n'avez plus de vie à force de tirer vous ne pourrez plus attaquer. Tentez de décimer l'équipe adverse !",
    ],
  },
  [GAMES.ODD_ONE_OUT]: {
    images: ['./tutos/odd-one-out/odd-one-out-1.jpg'],
    texts: [
      'Clique simplement sur le personnage qui a une différence avec les autres dans le temps imparti !',
    ],
  },
  [GAMES.COSMOS_COMBO]: {
    images: [
      './tutos/cosmos-combo/cosmos-combo-1.jpg',
      './tutos/cosmos-combo/cosmos-combo-2.jpg',
      './tutos/cosmos-combo/cosmos-combo-3.jpg',
    ],
    texts: [
      'Le but est de trouver la combinaison secrète, commensez par sélectionner un des enclos',
      'Retenez pour chaque enclos la position et le nombre correspondant (par exemple ici on sait que le 3eme chiffre est 2)',
      "Une fois tous les chiffres réuni tentez votre chance en tappant la combinaison",
    ],
  },
  [GAMES.DEFENDER]: {
    images: ['./tutos/defender/defender-1.jpg', './tutos/archery/archery-2.png'],
    texts: [
      'Dans cette épreuve, aidez vous de votre souris / écran tactil pour bouger la main, arrêter les rochers et augmenter votre score.',
      "Mais attention chaque arrêt raté fait perdre de la vie. En équipe si votre vie tombe à 0 vous ferez gagner l'adversaire. En solo vous devrez attendre la fin du temps sans bouger.",
    ],
  },
  [GAMES.WHACKAMOLE]: {
    images: ['./tutos/whackamole/whackamole-1.jpg', './tutos/archery/archery-2.png'],
    texts: [
      'Dans cette épreuve, vous devez tapper le plus vite possible sur les taupes qui appairaissent',
      "Mais attention chaque mauvais clic fait perdre de la vie. En équipe si votre vie tombe à 0 vous ferez gagner l'adversaire. En solo vous devrez attendre la fin du temps sans bouger.",
    ],
  },

  [LOCATIONS.CAMP]: {
    images: [
      './tutos/camp/camp-1.jpg',
      './tutos/camp/camp-2.jpg',
    ],
    texts: [
      'Sur le camp vous allez avoir le choix entre différentes activités avant le prochain conseil',
      "Vous pouvez également consulter l'état de votre équipe ainsique votre inventaire",
    ],
  },
  [LOCATIONS.ACTIVITY.BRACELET]: {
    images: [
      './tutos/bracelet/bracelet-1.jpg',
      './tutos/bracelet/bracelet-2.jpg',
    ],
    texts: [
      'Cliquez sur un arbre pour le couper !',
      "Cliquez sur une souche pour vous déplacer",
    ],
  },
  [LOCATIONS.ACTIVITY.FISHING]: {
    images: [
      './tutos/fishing/fishing-1.jpg',
    ],
    texts: [
      'Cliquez pour poser un filet et attraper un poisson !',
    ],
  },
  [LOCATIONS.ACTIVITY.INVENTORY]: {
    images: [
      './tutos/inventory/inventory-1.jpg',
    ],
    texts: [
      'Cliquez sur un des objets à droite pour le fabriquer !',
    ],
  },
  [LOCATIONS.ACTIVITY.CAMPFIRE]: {
    images: [
      './tutos/campfire/campfire-1.jpg',
      './tutos/campfire/campfire-2.jpg',
    ],
    texts: [
      "Cliquez sur le feu pour donner de l'énergie à toute l'équipe (sauf vous)",
      "Discutez avec les autres joueurs présent au feu de camp"
    ],
  },
  [LOCATIONS.SEMI_FINAL]: {
    images: [
      './tutos/semi-final/semi-final-1.jpg',
      './tutos/semi-final/semi-final-2.jpg',
      './tutos/semi-final/semi-final-3.jpg',
    ],
    texts: [
      "C'est l'heure de la demi finale ! Vous devez fouiller la forêt afin de trouver un poignard au plus vite",
      "Les trois premiers joueurs à récupérer leur poignard participeront à l'épreuve finale",
      "Restez le plus longtemps en équilibre à l'aide des flêches, si vous gagnez vous choisirez l'autre finaliste !",
    ],
  },
  [LOCATIONS.FINAL]: {
    images: [
      './tutos/final/final-1.jpg',
      './tutos/final/final-2.jpg',
      './tutos/final/final-3.jpg',
      './tutos/final/final-4.jpg',
    ],
    texts: [
      "C'est enfin l'heure de la finale !",
      'Les deux joueurs écrivent un discours pour essayer de vous convaincre de voter pour eux',
      "Votez pour la personne que vous voulez voir remporter l'aventure !",
    ],
  },
};
