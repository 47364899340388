import React, { useEffect, useState, useRef, useCallback } from 'react';
import fishAnimations from './fishAnimations.json';
import usePixi from 'hooks/usePixi';
import FishElement from './FishElement';
import PixiCanvas from 'components/PixiCanvas';
import FishNetElement from './FishNetElement';
import * as PIXI from 'pixi.js';
import successPercent from 'utils/successPercent';

const getRandomFish = () => {
  const fishTypes = Object.keys(fishAnimations);

  // Définir une probabilité de tirage pour chaque poisson en fonction de sa rareté
  for(const fishType of fishTypes) {
    const animationData = fishAnimations[fishType];
    if (successPercent(animationData.rarity)) return fishType
  }
  // if no fish
  return "jellyfish"
};

const FishingCanvas = ({onFish, netCount = 0}) => {
  const { width, height } = usePixi();
  const [fishes, setFishes] = useState([]);
  const [currentNetIndex, setCurrentNetIndex] = useState(0); // Index du filet actuel
  const [nets, setNets] = useState(
    Array(netCount).fill(null).map(() => ({
      isPlaced: false,
      position: { x: window.innerWidth / 2, y: 0 },
    }))
  );
  const fishPositions = useRef({});

  useEffect(() => {
    const spawnFish = () => {
      const newFishes = [];

      const fishCount = 1 + Math.floor(Math.random() * 6); // Nombre de poissons entre 1 et 5

      for (let i = 0; i < fishCount; i++) {
        const fishType = getRandomFish();
        const animationData = fishAnimations[fishType];
        const frameCount = animationData.frameCount;
        const x = Math.random() * width / 3; // Position aléatoire sur l'écran
        const y = 50 + Math.random() * (height - 100); // Position aléatoire sur l'écran
        const animationSpeed = Math.floor((0.05 + Math.random() * 0.1) * 100) / 100; // Vitesse d'animation aléatoire entre 0.05 et 0.15
        const speed = 1 + Math.random() * 5; // Vitesse de déplacement aléatoire entre 1 et 5

        const spriteImage = require(`../../../${animationData.imagePath}`);


        newFishes.push({
          key: `${fishType}-${i}-${Math.random() * 100}`, // Clé unique pour chaque élément
          spriteImage,
          frameCount: frameCount,
          initialX: x,
          initialY: y,
          animationSpeed: animationSpeed,
          speed: speed,
          type: fishType,
        });
      }

      setFishes(newFishes);
    };

    setTimeout(() => {
      spawnFish();
    }, 1000)

    const interval = setInterval(spawnFish, 10000); // Faire apparaître de nouveaux poissons toutes les 10 secondes

    return () => clearInterval(interval);
  }, [width, height]);

  const handleDisappear = (fishKey) => {
    setFishes((prevFishes) => prevFishes.filter(fish => fish.key !== fishKey));
  };

  const handleCatch = useCallback(
    (netIndex, fishKey, fishType) => {
      console.log(`Caught a ${fishType}`);
      setFishes((prevFishes) => prevFishes.filter((fish) => fish.key !== fishKey));
      onFish(fishType);
      setNets((prevNets) => {
        const newNets = [...prevNets];
        newNets[netIndex].isPlaced = false;
        setCurrentNetIndex(netIndex)
        return newNets;
      });
    },
    [onFish]
  );

  const handleFishUpdate = ({ key, x, y, type }) => {
    fishPositions.current[key] = { x, y, type };
  };

 const handleNetPositionChange = (index, x, y) => {
    setNets((prevNets) => {
      const newNets = [...prevNets];
      newNets[index].position = { x, y };
      return newNets;
    });
  };

  useEffect(() => {
    const detectCollision = () => {
      nets.forEach((net, index) => {
        if (net.isPlaced) {
          const netBounds = new PIXI.Rectangle(net.position.x - 25, net.position.y - 25, 50, 50);
          for (const fishKey in fishPositions.current) {
            const { x, y } = fishPositions.current[fishKey];
            const fishBounds = new PIXI.Rectangle(x - 25, y - 25, 50, 50);
            if (netBounds.intersects(fishBounds)) {
              handleCatch(index, fishKey, fishPositions.current[fishKey].type);
              delete fishPositions.current[fishKey];
            }
          }
        }
      });
    };

    const interval = setInterval(detectCollision, 100);

    return () => clearInterval(interval);
  }, [nets, handleCatch]);


  return (
    <PixiCanvas cursor={nets.some((net) => !net.isPlaced) ? 'pointer' : 'unset'}>
      {nets.map((net, index) => (
        <FishNetElement
          key={index}
          isCurrent={index === currentNetIndex}
          isPlaced={net.isPlaced} // Seul le filet actuel peut être placé
          setIsPlaced={(isPlaced) => setNets((prevNets) => {
            const newNets = [...prevNets];
            newNets[index].isPlaced = isPlaced;
            if (isPlaced) {
              setCurrentNetIndex(prev => (prev+1)%netCount)
            }
            if (!isPlaced) {
              setCurrentNetIndex(index)
            }
            return newNets;
          })}
          onPositionChange={(x, y) => handleNetPositionChange(index, x, y)}
        />
      ))}
      {fishes.map((fish) => (
        <FishElement
          key={fish.key}
          spriteImage={fish.spriteImage}
          frameCount={fish.frameCount}
          initialX={fish.initialX}
          initialY={fish.initialY}
          animationSpeed={fish.animationSpeed}
          speed={fish.speed}
          onDisappear={() => handleDisappear(fish.key)}
          onUpdate={({ x, y }) => handleFishUpdate({ key: fish.key, x, y, type: fish.type })}
          fishType={fish.type}
        />
      ))}
    </PixiCanvas>
  );
};

export default FishingCanvas;
