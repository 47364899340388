import React, { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useNavigate } from 'react-router-dom';
import { auth, sendPasswordReset } from '../myFirebase';
import Link from '../components/Link/Link';
import { Flex, FormControl, FormLabel, Box } from '@chakra-ui/react';
import Form from '../components/Form/Form';
import Input from '../components/Input/Input';
import Title from '../components/Title/Title';
import Button from '../components/Button/Button';
import useError from '../hooks/useError';
import useSuccess from '../hooks/useSuccess';

function ResetPassword() {
  const [email, setEmail] = useState('');
  const [user, loading] = useAuthState(auth);
  const [onError] = useError(() => {
    setEmail('');
  });
  const [onSuccess] = useSuccess(() => {
    setEmail('');
  });
  const navigate = useNavigate();
  useEffect(() => {
    if (loading) return;
    if (user) navigate('/');
  }, [user, loading, navigate]);
  return (
    <Flex direction="column" justify="space-around" align="center" h="80vh">
      <Title>Reset Password</Title>
      <Form
        onSubmit={e => {
          e.preventDefault();
          sendPasswordReset(email, onError, onSuccess);
        }}
      >
        <FormControl isRequired>
          <FormLabel>Email</FormLabel>
          <Input
            autoFocus
            type="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
            placeholder="example@mail.fr"
            autoComplete="email"
          />
        </FormControl>
        <Button w="100%" my="4" type="submit">
          Send password reset email
        </Button>
      </Form>
      <Box>
        Don't have an account? <Link to="/signup">Register</Link> now.
      </Box>
    </Flex>
  );
}
export default ResetPassword;
