import { Graphics, Text } from "@pixi/react";
import { FOREST_LOCATIONS } from "constants";
import { useMemo, useState } from "react";

const LocationButtons = ({ location, onClick }) => {
  const neighbors = {
    [FOREST_LOCATIONS.CENTER]: [
      { location: FOREST_LOCATIONS.NORTH, pos: 0 },
      { location: FOREST_LOCATIONS.EAST, pos: 1 },
      { location: FOREST_LOCATIONS.SOUTH, pos: 2 },
      { location: FOREST_LOCATIONS.WEST, pos: 3 },
    ],
    [FOREST_LOCATIONS.NORTH]: [{ location: FOREST_LOCATIONS.CENTER, pos: 2 }],
    [FOREST_LOCATIONS.SOUTH]: [{ location: FOREST_LOCATIONS.CENTER, pos: 0 }],
    [FOREST_LOCATIONS.EAST]: [{ location: FOREST_LOCATIONS.CENTER, pos: 3 }],
    [FOREST_LOCATIONS.WEST]: [{ location: FOREST_LOCATIONS.CENTER, pos: 1 }],
  };

  return (
    <>
      {neighbors[location].map((neighbor, index) => (
        <LocationButton
          key={neighbor.pos}
          pos={neighbor.pos}
          location={neighbor.location}
          onClick={onClick}
        />
      ))}
    </>
  );
};
const LocationButton = ({ location, pos = 3, onClick }) => {
  const [hovered, setHovered] = useState(false);

  const handlePointerOver = () => {
    setHovered(true);
  };

  const handlePointerOut = () => {
    setHovered(false);
  };

  const handleClick = () => {
    onClick(location, pos)
  };

  const x = useMemo(() => {
    switch (pos) {
      // droite
      case 1:
        return 2650
      // gauche
      case 3:
        return -200
      // haut et bas
      default:
        return 2500 / 2
    }
  }, [pos])
  let buttonWidth = pos === 0 || pos === 2 ? 500 : 300;
  let buttonHeight = pos === 0 || pos === 2 ? 100 : 500;
  const y = useMemo(() => {
    switch (pos) {
      // en haut
      case 0:
        return -100
      // en bas
      case 2:
        return 1100
      // droite et gauche
      default:
        return 1100 / 2
    }
  }, [pos])

  const buttonStyle = {
    fill: "black",
    fontSize: 50,
    fontWeight: 'bold',
    align: 'center',
  };


  return (
    <>
      <Graphics
        draw={g => {
          g.clear();
          g.beginFill("#FFD700");
          g.drawRoundedRect(x - buttonWidth / 2, y - buttonHeight / 2, buttonWidth, buttonHeight, 10);
          g.endFill();
          if (hovered) {
            g.lineStyle(2, 0xFFFFFF);
            g.drawRoundedRect(x - buttonWidth / 2, y - buttonHeight / 2, buttonWidth, buttonHeight, 10);
          }
        }}
        interactive={true}
        pointerdown={handleClick}
        pointerover={handlePointerOver}
        pointerout={handlePointerOut}
        cursor="pointer"
      />
      <Text
        style={buttonStyle}
        text={location}
        x={x}
        y={y}
        anchor={0.5}
        interactive={true}
        pointerdown={handleClick}
        pointerover={handlePointerOver}
        pointerout={handlePointerOut}
        cursor="pointer"
      />
    </>

  );
};


export default LocationButtons