import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import useSocket from '../../hooks/useSocket';
import { Flex, Image, Text, VStack } from '@chakra-ui/react';

import BraceletSvg from 'svg/Bracelet.svg';
import FishSvg from 'svg/Fish.svg';
import PalmSvg from 'svg/Palm.svg';
import BagSvg from 'svg/ActivityInventory.svg';
import HintSvg from 'svg/hint.svg';
import BucketSvg from 'svg/bucket.svg';

import Feedback from '../Feedback';
import GameHelper from '../../components/GameHelper/GameHelper';
import useRerender from '../../hooks/useRerender';
import Input from 'components/Input';
import Icon from 'components/Icon';
import Button from 'components/Button';
import shuffleArray from 'utils/shuffleArray';


const elements = [BraceletSvg, FishSvg, PalmSvg, BagSvg, HintSvg, BucketSvg]

function CosmosCombo() {
  const socket = useSocket()

  const { gameId, myId, me, randomNumber } = useSelector(
    state => state.game
  );


  const generateEnclosureArray = (number) => {
    if (!number || number <=0 ) return []
    const numberArray = number.toString().split("")
    const tmpEnclosureArray = numberArray.map((n, index) => ({number: n, position: index+1}))
    console.log("before shuffle", tmpEnclosureArray);
    shuffleArray(tmpEnclosureArray)
    console.log("after shuffle", tmpEnclosureArray);
    return tmpEnclosureArray
  }

  const enclosureArray = useMemo(() => generateEnclosureArray(randomNumber), [randomNumber])

  // to triger the error / success animation
  const [rerender, rerenderKey] = useRerender();
  const [isError, setIsError] = useState(null);

  const [currentEnclosure, setCurrentEnclosure] = useState()

  const [number, setNumber] = useState("")

  // game helper state
  const [displayTuto, setDisplayTuto] = useState(0)

  const handleChange = (e) => {
    e.preventDefault()
    setNumber(e.target.value)
  }

  const handleBack = () => {
    setNumber("")
    setCurrentEnclosure()
  }

  const handleMistake = () => {
    setNumber("");
    setIsError(true);
    rerender();
  };
  const handleSubmit = (e) => {
    e.preventDefault()
    socket.emit('more-or-less-guess', {
      gameId,
      myId,
      guess: number,
    });
    if (parseInt(number) === parseInt(randomNumber)) {
      return
    }
    handleMistake()
  }


  return (
    <VStack h="100%">
      {!currentEnclosure ? 
      <>
      <GameHelper
        canDisplay={me.canPlay & me.canMove && me.alive && displayTuto ===0}
          text="Sélectionne un enclos à observer"
          onClose={() => setDisplayTuto(1)}
      >
        <Flex justify="space-around" flexWrap="wrap">
          {enclosureArray?.map(({number, position}) => <EnclosureIcon setCurrentEnclosure={setCurrentEnclosure} number={number} position={position} key={position} />)}
        </Flex>
      </GameHelper>
          <GameHelper maxW="80%" w="500px" canDisplay={me.canPlay & me.canMove && me.alive && displayTuto === 2}
            text="Propose la bonne combinaison"
            onClose={() => setDisplayTuto(3)}
          >
            <form onSubmit={handleSubmit}>
              <Input isDisabled={!me.canPlay || !me.alive || !me.canMove} bg="black" color="gold" placeholder="Entrer le code secret" value={number} onChange={handleChange} type="number" />
              <Button isDisabled={!me.canPlay || !me.alive || !me.canMove} bg="black" color="gold" type='submit' w="100%" rightIcon={<Icon icon="dashicons:unlock"/>}>Dévérouiller</Button>
            </form>
          </GameHelper>
        </> :
        <GameHelper
          canDisplay={me.canPlay & me.canMove && me.alive && displayTuto === 1}
          text="Retiens le nombre d'élément correspondant au numéro de l'enclos"
          onClose={() => setDisplayTuto(2)}
        >
          <Enclosure {...currentEnclosure} onBack={handleBack} />
        </GameHelper>
      }
      <Feedback key={rerenderKey} isError={isError} />
    </VStack>
  );
}

const EnclosureIcon = ({ number, position, setCurrentEnclosure }) => {
  return <Icon onClick={() => setCurrentEnclosure({ number, position })} cursor="pointer" _hover={{ color: "vegetation" }} boxSize={{ base: "100px", md: "300px" }} icon="mdi:farm" />
}

const Enclosure = ({ number, position, onBack }) => {
  const elementImage =  useMemo(() => elements[position-1], [position])

  return <VStack mt={5} h="100%" w="100%" spacing={5}>
    <Text fontWeight="bold" fontSize="5xl">{position}</Text>
    <Flex minW="50%" minH="50%" border="1px dashed black" flexWrap="wrap" justify="center" align="center">
      {[...Array(parseInt(number))].map((e, i) => (
        <Image key={i} maxH="90px" w="auto" h="auto" src={elementImage} />
      ))}

    </Flex>
    <Button onClick={onBack} bg="black" color="white" leftIcon={<Icon icon="ion:caret-back-sharp" />} size="lg" fontSize="3xl">Voir les autres enclos</Button>
  </VStack>
}

export default CosmosCombo;
