import React from 'react';
import { Button as ChakraButton } from '@chakra-ui/react';
import MotionBox from '../MotionBox/MotionBox';

function GameButton({ children, ...props }) {
  return (
    <MotionBox
      // whileTap={{ opacity: 0, scale: 0.8 }}
      whileHover={{ opacity: 0.8 }}
      textAlign="center"
      w="100%"
    >
      <ChakraButton
        m="3"
        borderRadius="50px"
        filter="drop-shadow(-21.6355px 37.5774px 2.27742px rgba(0, 0, 0, 0.25));"
        fontSize="5xl"
        color="sky"
        bg="white"
        p="8"
        bottom="0"
        w="80vw"
        {...props}
      >
        {children}
      </ChakraButton>
    </MotionBox>
  );
}
export { GameButton };
export default GameButton;
