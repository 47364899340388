import React from 'react';
import Player from '../Player/Player';
import { Flex } from '@chakra-ui/react';
import TeamIcon from './TeamIcon';
import { noDoublonPlayers } from '../../utils/noDoublonPlayers';

function Team({ team, facingRight, color, ...props }) {
  const { players } = team;
  return (
    <Flex direction="column" w="100%" my="5" alignItems="center" {...props}>
      <TeamIcon color={color} alignSelf="center" />
      <Flex>
        {noDoublonPlayers(players).map((player, index) => (
          <Player
            key={player.id}
            name={player.name}
            id={player.id}
            facingRight={facingRight}
            position="unset"
            avatar={player?.avatar}
            title={player?.title}
          />
        ))}
      </Flex>
    </Flex>
  );
}

export default Team;
