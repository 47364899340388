import { doc, updateDoc } from 'firebase/firestore';
import { db } from 'myFirebase';
import firebaseErrorSwitch from 'myFirebase/utils';

export const updateUser = async (ref, updateObj, onUpdate, onError = alert) => {
  try {
    console.log('ref', ref);
    await updateDoc(doc(db, 'users', ref), updateObj);
    onUpdate && onUpdate(updateObj);
  } catch (err) {
    console.error(err);
    onError(firebaseErrorSwitch(err));
  }
};
