import React, { useMemo } from 'react';
import { Flex, Text, Grid, GridItem } from '@chakra-ui/react';

import { LOCATIONS } from '../../constants';

import { ReactComponent as ActivityBracelet } from '../../svg/ActivityBracelet.svg';
import { ReactComponent as ActivityFishing } from '../../svg/ActivityFishing.svg';
import { ReactComponent as ActivityCraft } from '../../svg/ActivityCraft.svg';
import { ReactComponent as ActivityCampfire } from '../../svg/ActivityCampfire.svg';

import MotionBox from '../MotionBox/MotionBox';
import useChangeLocation from '../../hooks/useChangeLocation';
import { useSelector } from 'react-redux';
import Avatar from '../Avatar/Avatar';
import Tuto from '../../games/Tuto';
import useLocalStorage from 'hooks/useLocalStorage';


function Camp() {
  const { isDemo } = useSelector(state => state.game);
  const [displayTuto, setDisplayTuto] = useLocalStorage('camp-tuto', true);
  if (isDemo && displayTuto)
    return (
      <Tuto
        canSkip
        timeout={8000}
        id={LOCATIONS.CAMP}
        setDisplay={setDisplayTuto}
      />
    );

  return (
    <Grid
      templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(5, 1fr)' }}
      w="100%"
    >
      <GridItem colSpan={{ base: 'auto', md: 5 }}>
        <MotionBox
          whileHover={{
            scale: 1.2,
            filter: 'brightness(0)',
            transition: {
              duration: 1,
              repeat: Infinity,
              repeatType: 'reverse',
            },
          }}
        >
          <CampCategory
            name="Bracelet"
            SvgComponent={ActivityBracelet}
            location={LOCATIONS.ACTIVITY.BRACELET}
          />
        </MotionBox>
      </GridItem>
      <GridItem
        colStart={{ base: 'auto', md: 2 }}
        colEnd={{ base: 'auto', md: 2 }}
      >
        <MotionBox
          whileHover={{
            scale: 1.2,
            filter: 'brightness(1.5)',
            type: 'spring',
            transition: {
              duration: 1.5,
              repeat: Infinity,
              repeatType: 'reverse',
            },
          }}
        >
          <CampCategory
            name="Feu de camp"
            SvgComponent={ActivityCampfire}
            location={LOCATIONS.ACTIVITY.CAMPFIRE}
          />
        </MotionBox>
      </GridItem>
      <GridItem
        colStart={{ base: 'auto', md: 4 }}
        colEnd={{ base: 'auto', md: 4 }}
      >
        <MotionBox
          whileHover={{
            scale: 1.2,
            filter: 'brightness(1.5)',
            type: 'spring',
            transition: {
              duration: 1.5,
              repeat: Infinity,
              repeatType: 'reverse',
            },
          }}
        >
          <CampCategory
            name="Bricolage"
            SvgComponent={ActivityCraft}
            location={LOCATIONS.ACTIVITY.INVENTORY}
          />
        </MotionBox>
      </GridItem>
      <GridItem colSpan={{ base: 'auto', md: 5 }}>
        <MotionBox
          whileHover={{
            scale: 1,
            rotate: 50,
            transition: {
              duration: 0.5,
              repeat: Infinity,
              repeatType: 'reverse',
            },
          }}
        >
          <CampCategory
            name="Pêche"
            SvgComponent={ActivityFishing}
            location={LOCATIONS.ACTIVITY.FISHING}
          />
        </MotionBox>
      </GridItem>
    </Grid>
  );
}

function CampCategory({
  name,
  SvgComponent,
  location = LOCATIONS.CAMP,
  isDisabled = false,
}) {
  const { myTeam, me, } = useSelector(state => state.game);

  const changeLocation = useChangeLocation();

  const handleClick = () => {
    if (me.spectator) return
    changeLocation(location);
  };

  const playersInLocation = useMemo(
    () => myTeam?.players?.filter(player => player.location === location),
    [myTeam, location]
  );
  
  if (!myTeam) return

  return (
    <Flex
      cursor="pointer"
      direction="column"
      align="center"
      onClick={!isDisabled ? handleClick : undefined}
      filter={isDisabled ? 'grayscale(100%)' : 'unset'}
    >
      <Text
        textAlign="center"
        fontSize={{ base: '1xl', md: '5xl' }}
        color="black"
        fontFamily='"Irish Grover", cursive"'
        style={{ opacity: '0.5' }}
      >
        {name}
      </Text>
      {SvgComponent && (
        <SvgComponent style={{ height: '12vh', width: 'unset' }} />
      )}

      <PlayersInLocation players={playersInLocation} />
    </Flex>
  );
}

export function PlayersInLocation({ players = [] }) {
  console.log('plauyers', players);
  return (
    <Flex>
      {players.map(player => (
        <Avatar
          forceAvatar={player?.avatar}
          name={player.name}
          key={player.id}
          boxSize={10}
        />
      ))}
    </Flex>
  );
}

export default Camp;
