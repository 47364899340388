import React from 'react';
import { Flex, Menu, MenuButton, IconButton, MenuList, MenuItem } from '@chakra-ui/react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { auth, logout } from '../../myFirebase';
import Link from '../Link/Link';
import Icon from 'components/Icon';

const ROUTES_WITH_HEADER = [
  '/login',
  '/signup',
  '/reset-password',
  '/about',
  '/conditions',
  '/privacy-policy',
  '/upgrade',
  '/profil',
  '/leaderboard',
  '/join',
];

function Header() {
  const [user] = useAuthState(auth);
  const { user: userRedux } = useSelector(state => state.user);
  const navigate= useNavigate()

  const location = useLocation();
  const shouldShowHeader =
    ROUTES_WITH_HEADER.some(path => location.pathname.startsWith(path)) ||
    location.pathname === '/';

  if (!shouldShowHeader) {
    return null;
  }

  return (
    <Flex
      justify="space-between"
      bg="vegetation"
      color="white"
      align="center"
      minH="10vh"
      fontSize="lg"
    >
      <Link
        color="white"
        ml="4"
        fontSize={{base: "md", md:"2xl"}}
        to="/"
        fontFamily='"Irish Grover", cursive'
      >
        Cozy Surviving
      </Link>
      <Flex align="center" justify="space-around" w={{base: "100%", md: "40%"}}>
        <Link color="white" to="/upgrade">
          Avantages premium
        </Link>
        <Link to="/about" color="white">
          A propos
        </Link>
        <Link to="/leaderboard">
          <Icon color="white" _hover={{ color: "gold" }} icon="fa6-solid:ranking-star" />
        </Link>
        {user ? (
          <Menu>
            <MenuButton
              as={IconButton}
              aria-label='Options'
              icon={<Icon icon="radix-icons:avatar" />}
              color="white"
              bg="transparent"
            />
            <MenuList color="black" py="0">
              <MenuItem onClick={() => navigate(`/profil/${userRedux?.ref}`)} icon={<Icon icon="radix-icons:avatar" />}>
                Profil
              </MenuItem>
              <MenuItem
                onClick={logout}
                bg="red"
                color="white"
                icon={<Icon icon={'mdi:exit-to-app'} />}
              >
                Se Déconnecter
              </MenuItem>
            </MenuList>
          </Menu>
        ) : (
          <Link color="white" to="/login">
          Se connecter
          </Link>
        )}
      </Flex>
    </Flex>
  );
}

export default Header;
