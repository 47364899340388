import React, { useCallback, useEffect, useState } from 'react';
import {
  Heading,
  Text,
  VStack,
  HStack,
  Flex,
  Image,
  Collapse,
  Progress,
  Box,
  Divider,
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { db } from 'myFirebase';
import { doc, getDoc } from 'firebase/firestore';
import Avatar from 'components/Avatar';
import Icon from 'components/Icon';
import userTitles from 'constants/userTitles';
import userStats from 'constants/userStats';
import Button from 'components/Button';
import useSuccess from 'hooks/useSuccess';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';

const Profil = () => {
  const { userRef } = useParams();
  const { user: userRedux } = useSelector(state => state.user);

  const [profileUser, setProfilUser] = useState(null);
  const [onSuccess] = useSuccess();

  const getProfilData = useCallback(async () => {
    const userSnap = await getDoc(doc(db, 'users', userRef));
    if (!userSnap.exists()) return;
    const tmpProfilUser = userSnap.data();
    setProfilUser(tmpProfilUser);
  }, [setProfilUser, userRef]);

  useEffect(() => {
    getProfilData();
  }, [getProfilData]);

  const getProfilLink = () => {
    const url = `${window.location.href}`;
    onSuccess(`Lien vers le profil copié: ${url}`);
    navigator.clipboard.writeText(url);
    return url;
  };

  if (!profileUser) return;
  return (
    <VStack mt={8} spacing={10}>
      <Helmet>
        <title>Profil de {profileUser?.name} - Cozy Surviving</title>
        <meta property="og:image" content={profileUser?.avatar} />
      </Helmet>
      <VStack mb="5">
        <Heading as="h1" size="3xl">
          {profileUser.name}
        </Heading>
        {profileUser?.currentTitle && (
          <HStack>
            <Text fontWeight="bold" fontSize="2xl">
              {userTitles[profileUser.currentTitle].name}
            </Text>
            <Icon
              boxSize="7"
              color={userTitles[profileUser.currentTitle].color}
              icon={userTitles[profileUser.currentTitle].icon}
            />
          </HStack>
        )}
        <Avatar forceAvatar={profileUser?.avatar} name={profileUser?.name} />
        <Button
          mb="8"
          onClick={getProfilLink}
          rightIcon={<Icon icon="uil:copy" />}
        >
          Partager ce profil
        </Button>
        {profileUser?.stats && <ProfileStats profileUser={profileUser} />}
        <ProfileTitles isMe={userRedux?.ref === userRef} profileUser={profileUser} />
      </VStack>
    </VStack>
  );
};
const ProfileStats = ({ profileUser }) => {
  const [show, setShow] = React.useState(false);

  const handleToggle = () => setShow(!show);
  return (
    <VStack
      filter="drop-shadow(-21.6355px 37.5774px 2.27742px rgba(0, 0, 0, 0.25));"
      w="70vw"
      spacing={5}
      bg="sand"
      border="1px solid black"
    >
      <HStack
        py="3"
        bg="gold"
        w="100%"
        cursor="pointer"
        onClick={handleToggle}
        placeContent="center"
      >
        <Heading as="h2" size="2xl">
          Statistiques
        </Heading>
        <Icon icon="mingcute:down-fill" />
      </HStack>
      <Collapse style={{ width: '100%', padding: '2%' }} in={show}>
        {Object.keys(userStats).map(key => {
          if (profileUser?.stats[key] !== undefined) {
            const { stat, bg, imageSrc, color = 'black' } = userStats[key];
            return (
              <Flex
                w="100%"
                borderRadius="lg"
                fontWeight="bold"
                key={key}
                bg={bg}
                color={color}
                p={4}
                align="center"
                justifyContent="space-between"
                mb={2}
              >
                <Text>{`${profileUser.stats[key]} ${stat}`}</Text>
                {imageSrc && <Image src={imageSrc} boxSize="50px" />}
              </Flex>
            );
          }
          return null;
        })}
      </Collapse>
    </VStack>
  );
};

const ProfileTitles = ({ profileUser, isMe=false }) => {
  return (
    <VStack
      m="5%"
      filter="drop-shadow(-21.6355px 37.5774px 2.27742px rgba(0, 0, 0, 0.25));"
      w="70vw"
      spacing={5}
      bg="gold"
      border="1px solid black"
    >
      <Heading mt="2" as="h2" size="2xl">
        Titres
      </Heading>
      <Divider borderColor="black" />
      {Object.keys(userTitles).map(titleKey => {
        const title = userTitles[titleKey];
        const hasTitle = profileUser.titles.includes(titleKey);
        const conditionText =
          title.hide && !hasTitle ? '???' : title.conditionText;
        const opacity = hasTitle ? 1 : 0.5;
        const filter = hasTitle ? 'none' : 'grayscale(100%)';

        return (
          <VStack
            w="80%"
            key={titleKey}
            opacity={opacity}
            filter={filter}
            spacing={4}
          >
            <HStack spacing={4}>
              <Text fontWeight="bold" fontSize="3xl">
                {title.name}
              </Text>
              <Icon icon={title.icon} boxSize="7" color={title.color} />
            </HStack>
            <HStack spacing={4}>
              {isMe && <Text fontSize="2xl">{conditionText}</Text>}
              {hasTitle && (
                <Icon
                  boxSize="8"
                  color="vegetation"
                  icon="foundation:checkbox"
                />
              )}
            </HStack>
            {title.percent && (
              <Box width="40%">
                <Progress
                  value={title.percent(profileUser) * 100}
                  colorScheme="green"
                />
              </Box>
            )}
            <Divider borderColor="black" />
          </VStack>
        );
      })}
    </VStack>
  );
};

export default Profil;
