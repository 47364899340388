import { useState, useEffect } from "react";
import { Flex, Slider, SliderFilledTrack, SliderThumb, SliderTrack, useColorModeValue } from "@chakra-ui/react";
import Icon from "components/Icon";
import useOptions from "hooks/useOptions";

const VolumeOption = () => {
  const { getOption, setOption } = useOptions();
  const [volume, setVolume] = useState(getOption('volume') ?? 50);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setOption('volume', volume);
    }, 300);

    return () => {
      clearTimeout(timeoutId);
    };
    // eslint-disable-next-line
  }, [volume]);

  const handleVolumeChange = (newVolume) => {
    console.log("volume actuel", newVolume);
    setVolume(newVolume);
  };

  const handleMuteClick = () => {
    setVolume(0);
  };

  return (
    <Flex position="absolute" left="50%" transform="translate(-50%, 0%);" bottom="0" p="5" mr="10" w="300px" maxW="50vw" zIndex={2}>
      <Icon
        boxSize="5"
        icon={volume ? 'mdi:volume-high' : 'mdi:volume-off'}
        onClick={handleMuteClick}
        cursor="pointer"
      />
      <Slider min={0} max={100} value={volume} onChange={handleVolumeChange} flex="1" colorScheme="blue">
        <SliderTrack bg={useColorModeValue("gray.200", "gray.600")}>
          <SliderFilledTrack bg={useColorModeValue("blue.400", "blue.300")} />
        </SliderTrack>
        <SliderThumb boxSize={6}>
          <Icon
            boxSize="5"
            icon={volume ? 'mdi:volume-high' : 'mdi:volume-off'}
          />
        </SliderThumb>
      </Slider>
    </Flex>
  );
};

export default VolumeOption;