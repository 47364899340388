import React, { useRef } from 'react';
import { db, storage } from '../../myFirebase';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { doc, updateDoc } from 'firebase/firestore';
import { Input, VStack } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { setAvatar } from '../../redux/features/userSlice';
import Button from '../Button';
import Icon from '../Icon';
import { useNavigate } from 'react-router-dom';

const AvatarUploader = ({ userId, isPremium }) => {
  const fileInputRef = useRef(null);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const resetFileInput = () => {
    // Réinitialise le champ de fichier
    fileInputRef.current.value = null;
  };

  const handleFileChange = async e => {
    const file = e.target.files[0];
    console.log('selected file', file.type);
    if (!file) {
      console.error('Aucun fichier sélectionné');
      return;
    }

    const avatarRef = ref(storage, `avatars/${userId}/avatar`);
    const resizedImage = await resizeImage(file);

    try {
      await uploadBytes(avatarRef, resizedImage).then(snapshot => {
        getDownloadURL(snapshot.ref).then(async downloadURL => {
          await updateDoc(doc(db, 'users', userId), {
            avatar: downloadURL,
          });
          dispatch(setAvatar(downloadURL));
        });
      });
    } catch (error) {
      console.error("Erreur lors du téléchargement de l'avatar :", error);
    }
  };

  const resizeImage = file => {
    return new Promise(resolve => {
      const reader = new FileReader();

      reader.onload = e => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');

          // Redimensionner l'image à une taille maximale, par exemple 300x300
          const maxSize = 400;
          let width = img.width;
          let height = img.height;

          if (width > height) {
            if (width > maxSize) {
              height *= maxSize / width;
              width = maxSize;
            }
          } else {
            if (height > maxSize) {
              width *= maxSize / height;
              height = maxSize;
            }
          }

          canvas.width = width;
          canvas.height = height;

          // Dessiner l'image redimensionnée sur le canvas
          ctx.drawImage(img, 0, 0, width, height);

          // Convertir le canvas en Blob
          canvas.toBlob(
            blob => {
              resolve(blob);
            },
            'image/png',
            0.8
          ); // Utilisez 'image/jpeg' ou 'image/png' en fonction de vos besoins
        };

        img.src = e.target.result;
      };

      reader.readAsDataURL(file);
    });
  };

  return (
    <VStack spacing={4}>
      <Input
        type="file"
        ref={fileInputRef}
        display="none"
        id="fileInput"
        onChange={handleFileChange}
      />
      <label htmlFor="fileInput">
        <Button
          as="div"
          cursor="pointer"
          bg="sea"
          color="white"
          rightIcon={<Icon icon="openmoji:goldfish" />}
          onClick={isPremium ? resetFileInput : () => navigate('/upgrade')}
        >
          Changer d'avatar
        </Button>
      </label>
    </VStack>
  );
};

export default AvatarUploader;
