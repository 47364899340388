import React from 'react';
import { IconButton as ChakraIconButton } from '@chakra-ui/react';
import Icon from '../Icon/Icon';

function IconButton({ icon, iconColor, boxSize, ...props }) {
  return (
    <ChakraIconButton
      m="3"
      borderRadius="50px"
      filter="drop-shadow(-21.6355px 37.5774px 2.27742px rgba(0, 0, 0, 0.25));"
      fontSize={{ base: 'xl', md: '3xl' }}
      color="sky"
      bg="white"
      p="7"
      minW={{ base: '80%', md: '200px' }}
      icon={<Icon icon={icon} color={iconColor} boxSize={boxSize} />}
      {...props}
    />
  );
}

export default IconButton;
