import React from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { ReactComponent as Bracelet } from '../../svg/Bracelet.svg';
import { svgSize } from './constants';

const ImmunityBracelet = ({ value = 0, fontSize = '2xl', ...props }) => {
  return (
    <Flex opacity={value ? '1' : '0.3'} direction="column" align="center">
      <Bracelet style={{ height: svgSize }} {...props} />
      <Text color="sand" fontWeight="extrabold" fontSize={fontSize}>
        {value}
      </Text>
    </Flex>
  );
};

export default ImmunityBracelet;
