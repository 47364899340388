import React, { useState } from 'react';
import { VStack } from '@chakra-ui/react';
import PixiCanvas from '../../components/PixiCanvas';
import RockAndHand from './RockAndHand';
import PlayerScore from 'components/Player/PlayerScore';
import TeamScores from 'components/Teams/TeamScores';
import { useSelector } from 'react-redux';
import useSocket from 'hooks/useSocket';

function Defender() {
  const [speed, setSpeed] = useState(5);
  const {me, myId, gameId, reunification } = useSelector(state => state.game);
  const socket = useSocket();

    const handleMiss = () => {
      if (!me.canMove || !me.canPlay) return;
      socket.emit('shoot-arrow', { gameId, myId });
    };

    const increaseScore = () => {
      if (!me.canMove || !me.canPlay) return;
      socket.emit('touch-target', { gameId, myId });
      setSpeed(speed + 0.5);
    };


  return (
    <VStack spacing={4}>
      {reunification ? <PlayerScore /> : <TeamScores />}
      <PixiCanvas>
        <RockAndHand speed={speed} onCatch={increaseScore} onMiss={handleMiss} canPlay={me.canPlay && me.canMove} />
      </PixiCanvas>
    </VStack>
  );
}

export default Defender;
