import React from 'react';
import { Text } from '@chakra-ui/react';
import MotionBox from '../MotionBox';

function Title({ children, ...props }) {
  return (
    <MotionBox
      initial={{ opacity: 0.05 }}
      animate={{ opacity: 0.3 }}
      style={{ pointerEvents: 'none' }}
      transition={{
        ease: 'linear',
        repeat: Infinity,
        duration: 5,
        repeatType: 'reverse',
        repeatDelay: 0.5,
      }}
      fontFamily='"Irish Grover", cursive'
    >
      <Text
        textAlign="center"
        fontSize={{ base: '5xl', md: '9xl' }}
        color="black"
        {...props}
      >
        {children}
      </Text>
    </MotionBox>
  );
}

export default Title;
