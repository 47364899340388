import React from 'react';
import { Flex } from '@chakra-ui/react';
import { ReactComponent as Fish } from '../../svg/Fish.svg';
import { svgSize } from './constants';
import AnimatedNumber from 'components/ui/AnimatedNumber';

const FishFood = ({ value = 0, fontSize = '2xl', ...props }) => {
  return (
    <Flex opacity={value ? '1' : '0.3'} direction="column" align="center">
      <Fish style={{ height: svgSize }} {...props} />
      <AnimatedNumber value={value} color="sky" fontWeight="extrabold" fontSize={fontSize}/>
    </Flex>
  );
};

export default FishFood;
