import React from 'react';
import { Flex, Box } from '@chakra-ui/react';
import waves from '../../svg/waves.svg';
import grass from '../../svg/grass-bg.svg';
import dirt from '../../svg/dirt-bg.svg';
import night from '../../svg/night-bg.svg';

import { useSelector } from 'react-redux';

import { GAMES, LOCATIONS } from '../../constants';
import MotionBox from '../MotionBox/MotionBox';

function Canvas({ children, ...props }) {
  const { location, currentGame } = useSelector(state => state.game);

  return (
    <Flex
      h="95vh"
      w="100%"
      maxW="100vw"
      direction="column"
      justify="center"
      align="center"
      // pointerEvents={!me || me?.alive ? "" : "none"}
    >
      <MotionBox
        initial={{ opacity: 1, height: '20vh', filter: 'brightness(1)' }}
        animate={{ opacity: 0, height: '50vh', filter: 'brightness(0)' }}
        transition={{
          ease: 'anticipate',
          repeat: Infinity,
          duration: 15,
        }}
        w="100%"
        maxW="100vw"
        position="absolute"
        bottom="0"
        style={{
          backgroundImage: `url(${waves})`,
          backgroundSize: 'cover',
        }}
      />
      <Box
        transition="all 0.8s ease-in-out"
        h={
          location === LOCATIONS.ACTIVITY.FISHING || location === LOCATIONS.PIOUTEAUX ||
          (location === LOCATIONS.GAME && currentGame === GAMES.BATTLESHIPS)
            ? '100vh'
            : '20vh'
        }
        w="100%"
        maxW="100vw"
        position="absolute"
        bottom="0"
        style={{
          backgroundImage: `url(${waves})`,
          backgroundSize: 'cover',
        }}
      />
      <Box
        transition="all 0.8s ease-in-out"
        h={
          location === LOCATIONS.ACTIVITY.BRACELET ||
          location === LOCATIONS.SEMI_FINAL
            ? '100vh'
            : '0vh'
        }
        w="100%"
        position="absolute"
        top="0"
        style={{
          backgroundImage: `url(${grass})`,
          backgroundSize: 'cover',
        }}
      />
      <Box
        transition="all 0.8s ease-in-out"
        h={location === LOCATIONS.FINAL ? '100vh' : '0vh'}
        w="100%"
        position="absolute"
        top="0"
        style={{
          backgroundImage: `url(/images/final-bg.png)`,
          backgroundSize: 'cover',
        }}
      />
      <Box
        transition="all 0.8s ease-in-out"
        h={location === LOCATIONS.VOTE ? '100vh' : '0vh'}
        w="100%"
        position="absolute"
        top="0"
        style={{
          backgroundImage: `url(${dirt})`,
          backgroundSize: 'cover',
        }}
      />
      <Box
        transition="all 0.8s ease-in-out"
        h="100vh"
        opacity={location === LOCATIONS.ACTIVITY.CAMPFIRE ? '100%' : '0%'}
        w="100%"
        position="absolute"
        top="0"
        style={{
          backgroundImage: `url(${night})`,
          backgroundSize: 'cover',
        }}
      />
      <Box w="90%" h="70%" position="relative" {...props}>
        {children}
      </Box>
    </Flex>
  );
}

export default Canvas;
