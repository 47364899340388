import React from 'react';
import MotionBox from '../../components/MotionBox';
import { ReactComponent as FingerSvg } from '../../svg/finger2.svg';

const FingerAnimation = ({ opacity = 1, rotation = 180 }) => {
  return (
    <MotionBox
      opacity={opacity}
      position="absolute"
      right="10%"
      top="7%"
      width="30%"
      h="30%"
      key={rotation + opacity}
      initial={{ y: 0, rotate: rotation }}
      animate={{
        y: [0, -20, 0, -20, 0], // Vous pouvez ajuster les valeurs et le nombre de points vers le haut ici
      }}
      transition={{ duration: 1, repeat: Infinity }}
    >
      {/* Remplacez l'URL ci-dessous par le chemin de votre fichier SVG */}
      <FingerSvg style={{ width: '100%', maxHeight: '100%' }} />
    </MotionBox>
  );
};
export default FingerAnimation;
