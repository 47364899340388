import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useSocket from '../../hooks/useSocket';

import Question from '../../svg/question-ball.svg';
import Black from '../../svg/black-ball.svg';
import White from '../../svg/white-ball.svg';
import { Flex, Image, Text } from '@chakra-ui/react';
import Player from '../../components/Player/Player';
import { animate } from 'motion';

import lucky from '../../sounds/lucky.mp3';
import unlucky from '../../sounds/unlucky.mp3';
import useSound from '../../hooks/useSound';

function TieBreak() {
  const { tieBreakBalls, gameId, myId, me, players } = useSelector(
    state => state.game
  );
  const [playerTurn, setPlayerTurn] = useState(null);
  const socket = useSocket();

  useEffect(() => {
    const player = players.find(p => p.canMove && p.alive);
    setPlayerTurn(player);
  }, [players]);

  const handleClick = ballIndex => {
    // if it's not my turn to take a ball
    if (!me.canMove) return false;
    console.log('ici', ballIndex);
    socket.emit('open-ball', { gameId, myId, ballIndex });
    return true;
  };
  return (
    <>
      <Flex w="100%" align="center" justify="center" wrap="wrap">
        {tieBreakBalls.map((tieBreakBall, index) => (
          <Ball
            index={index}
            onClick={() => handleClick(index)}
            ball={tieBreakBall}
          />
        ))}
      </Flex>
      <Flex w="100%" align="center" justify="center" wrap="wrap">
        {playerTurn && (
          <Player
            name={playerTurn.name}
            id={playerTurn.id}
            position="unset"
            avatar={playerTurn?.avatar}
            title={playerTurn?.title}
          />
        )}
        <Text color="sky" fontWeight="extrabold" fontSize="4xl">
          est en train de choisir une boule
        </Text>
      </Flex>
    </>
  );
}

function Ball({ ball, onClick, index }) {
  const [play] = useSound(ball.isBlack ? unlucky : lucky);
  const handleClick = () => {
    if (!onClick()) return;
    play();
    const animation = animate(
      `#BallAnimationSvg${index}`,
      {
        scale: [1, 1.5, 1],
        rotate: [0, 180, 0],
      },
      {
        duration: 1.5,
      }
    );

    animation.finished.then(() => {
      console.log('animation finished');
      // animation.cancel()
    });
  };
  return (
    <Image
      id={`BallAnimationSvg${index}`}
      cursor="pointer"
      onClick={!ball.isOpen && handleClick}
      p={3}
      maxW="20%"
      src={ball.isOpen ? (ball.isBlack ? Black : White) : Question}
    />
  );
}

export default TieBreak;
