import React, { useState } from 'react';
import { ReactComponent as KnifeSvg } from '../../svg/Knife.svg';
import knifeDiscover from '../../sounds/knife-discover.mp3';
import useSocket from '../../hooks/useSocket';
import { useSelector } from 'react-redux';
import { LOCATIONS } from '../../constants';
import Tuto from '../Tuto';
import Bracelet from 'components/Camp/Bracelet';

function SemiFinal() {
  const socket = useSocket();
  const { gameId, myId, me } = useSelector(state => state.game);

  const [foundKnife, setFoundKnife] = useState(false);
  const [displayTuto, setDisplayTuto] = useState(true);
  if (displayTuto)
    return <Tuto id={LOCATIONS.SEMI_FINAL} setDisplay={setDisplayTuto} force />;

  const handleFoundKnife = () => {
    setFoundKnife(true);
    // socket.emit('finalist', { gameId, myId });
    socket.emit('pole', { gameId, myId });
  };

  if (!me.alive) {
    return;
  }

  return (
    <>
      <Bracelet
        CustomSvg={KnifeSvg}
        customSound={knifeDiscover}
        onFound={handleFoundKnife}
        foundKnife={foundKnife}
      />
    </>
  );
}

export default SemiFinal;
