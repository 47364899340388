import React, { useMemo } from 'react';
import Modal from './Modal';
import {
  ModalHeader,
  useNumberInput,
  HStack,
  Button as ChakraButton,
  Flex,
} from '@chakra-ui/react';
import Input from '../Input/Input';
import Button from '../Button/Button';

function HowManyBetModal({ isOpen, onClose, max, onValidate }) {
  const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } =
    useNumberInput({
      step: 1,
      defaultValue: 0,
      min: 0,
      max: max || 0,
    });

  const inc = getIncrementButtonProps();
  const dec = getDecrementButtonProps();
  const input = getInputProps();

  const canClick =
    useMemo(() => input?.value > 0 && input?.value <= max, [max, input]) ||
    false;

  const handleValidateBet = () => {
    onValidate(input?.value || 0);
    onClose();
  };

  return (
    <>
      <Modal onClose={onClose} isOpen={isOpen} bg="sky" size="md">
        <ModalHeader fontSize="2xl" align="center" color="white">
          Combien de jetons ?
        </ModalHeader>
        <HStack maxW="100%" placeSelf="center" placeContent="center" p="5">
          <ChakraButton {...dec}>-</ChakraButton>
          <Input
            w="30%"
            {...input}
            textAlign="center"
            fontSize="3xl"
            fontWeight="700"
            max="12"
          />
          <ChakraButton {...inc}>+</ChakraButton>
        </HStack>
        <Flex justify="center">
          <Button disabled={!canClick} onClick={handleValidateBet}>
            Miser
          </Button>
        </Flex>
      </Modal>
    </>
  );
}

export default HowManyBetModal;
