import React, { useMemo, useState } from 'react';
import Modal from './Modal';
import { ModalHeader, Flex, Grid, GridItem } from '@chakra-ui/react';
import Input from '../Input/Input';
import Button from '../Button/Button';
import Player from '../Player/Player';

import { useSelector } from 'react-redux';
import { noDoublonPlayers } from '../../utils/noDoublonPlayers';

function SelectPlayerModal({
  isOpen,
  onClose = () => {},
  onValidate = () => {},
  displayCondition = player => true,
  title,
  forcePlayers,
  noInput = false,
}) {
  const [selectedPlayerId, setSelectedPlayerId] = useState(null);
  const [message, setMessage] = useState('');

  const handleChangeMessage = e => {
    setMessage(e.target.value);
  };

  const handleValidate = () => {
    onValidate({ to: selectedPlayerId, message });
    onClose();
    setSelectedPlayerId(null);
    setMessage('');
  };

  const { myTeam, myId } = useSelector(state => state.game);

  // we filter ourself out of the players
  const players =
    useMemo(
      () =>
        (forcePlayers
          ? noDoublonPlayers(forcePlayers)
          : noDoublonPlayers(myTeam?.players)
        )?.filter(player => {
          return displayCondition(player) && player?.id !== myId;
        }),
      [myTeam, myId, displayCondition, forcePlayers]
    ) || [];

  return (
    <Modal
      onClose={() => {
        onClose();
        setSelectedPlayerId(null);
      }}
      isOpen={isOpen}
      bg="primary"
      size="lg"
    >
      <ModalHeader fontSize="2xl" align="center" color="white">
        {title || 'Sélectionnez le joueur et ajoutez un petit message !'}
      </ModalHeader>
      <Grid
        templateColumns={`repeat(4, 20%)`}
        w="100%"
        alignItems="center"
        justifyContent="center"
        p="5"
      >
        {players.map(player => (
          <SelectPlayer
            key={player?.id}
            player={player}
            setSelectedPlayerId={setSelectedPlayerId}
            selectedPlayerId={selectedPlayerId}
          />
        ))}
      </Grid>
      <Flex direction="column" align="center">
        {!noInput && (
          <Input
            w="80%"
            textAlign="center"
            fontSize="3xl"
            fontWeight="700"
            maxLength={35}
            placeholder="Tu le mérites"
            value={message}
            onChange={handleChangeMessage}
          />
        )}
        <Button disabled={!selectedPlayerId} onClick={handleValidate}>
          Valider
        </Button>
      </Flex>
    </Modal>
  );
}

function SelectPlayer({ player, setSelectedPlayerId, selectedPlayerId }) {
  return (
    <GridItem
      _hover={{ bg: selectedPlayerId === player?.firstId ? 'vegetation' : 'gold' }}
      bg={selectedPlayerId === player?.firstId ? 'vegetation' : ''}
      cursor="pointer"
      colSpan={1}
      onClick={() => {
        setSelectedPlayerId(player?.firstId);
      }}
      m="1"
    >
      <Player
        h="auto"
        w="auto"
        position="initial"
        name={player?.name}
        avatar={player?.avatar}
        title={player?.title}
      />
    </GridItem>
  );
}

export default SelectPlayerModal;
