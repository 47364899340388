import { createSlice } from '@reduxjs/toolkit';
import { ELEM, FOREST_LOCATIONS, LOCATIONS } from '../../constants';
import applyChange from '../../utils/applyChange';
import { randomInArray } from '../../utils/random';

export const gameSlice = createSlice({
  name: 'counter',
  initialState: {
    isStart: false,
    isDemo: false,
    players: [],
    spectators: [],
    gameState: {},
    gameId: null,
    myId: null,
    me: null,
    myTeam: null,
    teams: {},

    votingTeam: null,
    reunification: false,
    location: LOCATIONS.GAME,
    forestLocation: FOREST_LOCATIONS.CENTER,
    currentGame: null,

    tieBreakPlayers: [],
    tieBreakBalls: [],

    options: { showTuto: true },

    // games

    isReady: false,
    // more or less game
    randomNumber: -1,

    // jump game and blindrun flag
    obstacles: [],

    // memory game
    securedPlayers: [],
    round: 0,
    randomElemArray: [],

    // only for front
    forestArray: [],
    power:null,
  },
  reducers: {
    updateGameState: (state, action) => {
      state.isStart = action.payload.isStart ?? state.isStart ?? false;
      state.isDemo = action.payload.isDemo ?? state.isDemo ?? false;
      applyChange(state.players, action.payload.players);
      applyChange(state.spectators, action.payload.spectators);
      state.gameId = action.payload.gameId ?? state.gameId;
      applyChange(state.gameState, action.payload.gameState);
      applyChange(state.teams, action.payload.teams);
      state.votingTeam = action.payload.votingTeam ?? state.votingTeam ?? null;
      state.reunification =
        action.payload.reunification || state.reunification || false;
      state.currentGame =
        action.payload.currentGame || state.currentGame || null;
      applyChange(state.tieBreakPlayers, action.payload.tieBreakPlayers);
      applyChange(state.tieBreakBalls, action.payload.tieBreakBalls);
      applyChange(state.options, action.payload.options);
      state.randomNumber =
        action.payload.randomNumber ?? state.randomNumber ?? -1;
      applyChange(state.obstacles, action.payload.obstacles);
      applyChange(state.randomElemArray, action.payload.randomElemArray);
      applyChange(state.securedPlayers, action.payload.securedPlayers);
      state.round = action.payload.round ?? state.round;

      state.isReady = action.payload.isReady ?? state.isReady ?? false;

      // Recherche du joueur actuel dans la liste des joueurs
      for (const player of state.players) {
        if (player.id === state.myId) {
          state.me = player;
          state.myTeam = state.teams?.[player.team];
          state.location = player?.location || LOCATIONS.GAME;
          state.forestLocation = player?.forestLocation || FOREST_LOCATIONS.CENTER;
          return // this ends the whole function careful
        }
      }
      // if not return means I'm a spectator
      for (const spectator of state.spectators) {
        if (spectator.id === state.myId) {
          state.myTeam = state.teams?.[spectator.team];
          state.me = spectator;
          state.location = spectator?.location || LOCATIONS.GAME;
          state.forestLocation = spectator?.forestLocation || FOREST_LOCATIONS.CENTER;
          return // this ends the whole function careful
        }
      }
    },
    updateFullGameState: (state, action) => {
      state.isStart = action.payload.isStart ?? false;
      state.isDemo = action.payload.isDemo ?? false;
      state.players = action.payload.players;
      state.spectators = action.payload.spectators;
      state.gameId = action.payload.gameId;
      state.gameState = action.payload.gameState || {};
      state.teams = action.payload.teams || {};
      state.votingTeam = action.payload.votingTeam || null;
      state.reunification = action.payload.reunification || false;
      state.currentGame = action.payload.currentGame || null;

      state.tieBreakPlayers = action.payload.tieBreakPlayers;
      state.tieBreakBalls = action.payload.tieBreakBalls;

      state.options = action.payload.options || { showTuto: true };

      state.randomNumber = action.payload.randomNumber || -1;
      state.obstacles = action.payload.obstacles || [];

      state.randomElemArray = action.payload.randomElemArray || [];
      state.securedPlayers = action.payload.securedPlayers || [];
      state.round = action.payload.round || 0;

      state.isReady = action.payload.isReady ?? false;

      // Recherche du joueur actuel dans la liste des joueurs
      for (const player of state.players) {
        if (player.id === state.myId) {
          state.me = player;
          state.myTeam = state.teams?.[player.team];
          state.location = player?.location || LOCATIONS.GAME;
          state.forestLocation = player?.forestLocation || FOREST_LOCATIONS.CENTER;
          return // this ends the whole function careful
        }
      }
      // if not return means I'm a spectator
      for (const spectator of state.spectators) {
        if (spectator.id === state.myId) {
          state.me = spectator;
          state.myTeam = state.teams?.[spectator.team];
          state.location = spectator?.location || LOCATIONS.GAME;
          state.forestLocation = spectator?.forestLocation || FOREST_LOCATIONS.CENTER;
          return // this ends the whole function careful
        }
      }
    },
    updateMyId: (state, action) => {
      state.myId = action.payload;
      for (const player of state.players) {
        if (player.id === state.myId) {
          state.me = player;
          state.myTeam = action.payload.teams?.[player.team];
          state.location = player?.location || LOCATIONS.GAME;
          state.forestLocation = player?.forestLocation || FOREST_LOCATIONS.CENTER;
          return
        }
      }

      // if not return means I'm a spectator
      for (const spectator of state.spectators) {
        if (spectator.id === state.myId) {
          state.me = spectator;
          state.myTeam = action.payload.teams?.[spectator.team];
          state.location = spectator?.location || LOCATIONS.GAME;
          state.forestLocation = spectator?.forestLocation || FOREST_LOCATIONS.CENTER;
          return
        }
      }
    },

    changeLocation: (state, location) => {
      // deprecated
      state.location = location;
    },

    changeLocationAction: (state, action) => {
      gameSlice.caseReducers.changeLocation(state, action.payload);
    },

    endGame: (state, action) => {
      // This one is not afecting the socket
      gameSlice.caseReducers.changeLocation(state, LOCATIONS.CAMP);
    },

    // CAMP

    updatePlayerItems: (state, action) => {
      const items = action.payload;
      state.me = { ...state.me, ...items };
    },


    // FOREST

    changeForestLocation: (state, location) => {
      // deprecated
      if (location === state.forestLocation) return;
      state.forestLocation = location;
      state.forestArray = [];
    },

    changeForestLocationAction: (state, action) => {
      // deprecated
      // only for front
      gameSlice.caseReducers.changeForestLocation(state, action.payload);
    },

    activePowerAction: (state, action) => {
      // only for front
      state.power = action.payload
    },

    cancelPowerAction: (state, action) => {
      // only for front
      state.power = null
    },

    getForestArray: (state, action) => {
      const tmpForest = [...action.payload];
      if (state.forestArray.length) {
        applyChange(state.forestArray, action.payload);
        return;
      }
      state.forestArray = tmpForest.map((elem, index) => {
        return {
          ...elem,
          id: `${state.forestLocation}-${index}`,
          type: elem.type || randomInArray([ELEM.PALM, ELEM.FRUIT_TREE, ELEM.NORMAL_TREE, ELEM.DEAD_TREE]),
        };
      });
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  updateGameState,
  updateFullGameState,
  updateMyId,
  endGame,
  changeLocationAction,
  updatePlayerItems,
  changeForestLocationAction,
  activePowerAction,
  cancelPowerAction,
  getForestArray,
} = gameSlice.actions;

export default gameSlice.reducer;
