import React, { useEffect, useRef, useState } from 'react';
import { Text } from '@chakra-ui/react';

const AnimatedNumber = ({ value, prevValue, duration = 1000, ...props }) => {
  const [displayValue, setDisplayValue] = useState(value);
  const previousValueRef = useRef(prevValue || value);

   useEffect(() => {
    const previousValue = previousValueRef.current;
    previousValueRef.current = value;

    let startTimestamp = null;
    const step = (timestamp) => {
      if (!startTimestamp) startTimestamp = timestamp;
      const progress = timestamp - startTimestamp;
      const progressPercentage = Math.min(progress / duration, 1);
      const currentValue = previousValue + (value - previousValue) * progressPercentage;
      setDisplayValue(Math.floor(currentValue));

      if (progress < duration) {
        window.requestAnimationFrame(step);
      }
    };

    window.requestAnimationFrame(step);
  }, [value, duration]);

  return (
    <Text {...props}>
      {displayValue}
    </Text>
  );
};

export default AnimatedNumber;