import React, { useEffect, useState, useRef } from 'react';
import * as PIXI from 'pixi.js';
import ElementPixi from 'components/PixiCanvas/ElementPixi';
import moleImage from 'svg/mole.svg';

const Mole = ({ index, visible, positions }) => {
  const position = positions[index];
  const [currentY, setCurrentY] = useState(position.y - 50); // Start with hidden position
  const requestRef = useRef();
  const moleRef = useRef(null);

  const animateY = (startY, endY, duration) => {
    const startTime = performance.now();

    const step = (currentTime) => {
      const elapsedTime = currentTime - startTime;
      const progress = Math.min(elapsedTime / duration, 1);
      const newY = startY + (endY - startY) * progress;

      setCurrentY(newY);

      if (progress < 1) {
        requestRef.current = requestAnimationFrame(step);
      }
    };

    requestRef.current = requestAnimationFrame(step);
  };

  const handleClick = () => {
    if (!visible) return
      animateY(currentY, position.y + 100, 10); // Animate to the hidden position (halfway out)
  }

  useEffect(() => {
    if (visible) {
      animateY(currentY, position.y, 600); // Animate to the visible position
    } else {
      animateY(currentY, position.y + 50, 100); // Animate to the hidden position (halfway out)
    }

    // Cleanup animation frame on unmount
    return () => cancelAnimationFrame(requestRef.current);
  }, [visible, position.y, currentY]);

  useEffect(() => {
    if (moleRef.current) {
      const mask = new PIXI.Graphics();
      mask.beginFill(0xffffff);

      if (visible) {
        mask.drawRect(position.x-100, position.y - 50, 200, 200); // Full image
      } else {
        mask.drawRect(position.x-100, position.y, 200, 50); // Half image
      }

      mask.endFill();
      moleRef.current.mask = mask;
    }
  }, [visible, position.x, position.y]);

  return (
    <ElementPixi
      customRef={moleRef}
      image={moleImage}
      x={position.x}
      y={currentY}
      interactive
      pointerdown={handleClick}
    />
  );
};

export default Mole;
