import { ANNOUNCE } from "constants";
import useSound from "./useSound";
import applause from "sounds/applause.mp3"
import boo from "sounds/boo.mp3"
import gasp from "sounds/gasp.mp3"
import { useMemo } from "react";


const useSoundMapping = () => {
  const [playApplause] = useSound(applause);
  const [playBoo] = useSound(boo);
  const [playGasp] = useSound(gasp);

  const soundMap = useMemo(() => ({
    [ANNOUNCE.SOUND.APPLAUSE] : playApplause,
    [ANNOUNCE.SOUND.BOO] : playBoo,
    [ANNOUNCE.SOUND.GASP] : playGasp,
  }), [playApplause, playBoo, playGasp])

  return soundMap

}

export default useSoundMapping