import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import useSocket from '../../hooks/useSocket';
import { Box, Flex, Image } from '@chakra-ui/react';
import FingerAnimation from './FingerAnimation';
import BucketSvg from '../../svg/bucket.svg';
import BraceletSvg from '../../svg/Bracelet.svg';
import FishSvg from '../../svg/Fish.svg';
import BagSvg from '../../svg/ActivityInventory.svg';
import HintSvg from '../../svg/hint.svg';
import BlackBallSvg from 'svg/black-ball.svg';

import MoreOrLessButton from './MoreOrLessButton';
import GameHelper from 'components/GameHelper/GameHelper';

const MAX_NUMBER = 100000;
const ELEMENTS = [
  { imageSrc: BagSvg, value: 10000 },
  { imageSrc: FishSvg, value: 1000 },
  { imageSrc: BraceletSvg, value: 100 },
  { imageSrc: BlackBallSvg, value: 10 },
  { imageSrc: HintSvg, value: 1 },
];

function MoreOrLess() {
  const { gameId, myId, randomNumber, me } = useSelector(state => state.game);
  const [tryValue, setTryValue] = useState(0);
  const [tryElems, setTryElems] = useState([]);
  const socket = useSocket();

  const handleClick = elem => {
    socket.emit('more-or-less-guess', {
      gameId,
      myId,
      guess: tryValue + elem.value,
    });
    setTryElems(elems => [...elems, elem]);
    setTryValue(val => val + elem.value);
  };

  const handleRemoveElem = index => {
    console.log(tryElems);
    console.log(tryValue);
    const tmpElems = tryElems;
    const removedElems = tmpElems.splice(index, 1);
    console.log('removed elemes', removedElems, tmpElems);
    socket.emit('more-or-less-guess', {
      gameId,
      myId,
      guess: tryValue - removedElems[0].value,
    });
    setTryElems(tmpElems);
    setTryValue(val => val - removedElems[0].value);
  };

  // Calcul de la position verticale des rectangles en fonction de la différence entre la valeur essayée et la valeur cible
  const diff = tryValue - randomNumber;
  const boxHeight = Math.min(Math.max(50 + (diff / MAX_NUMBER) * 100, 0), 100); // Réduit le pourcentage de la hauteur en fonction de la différence

  return (
    <>
      <Flex align="center" justify="center" h="70%" position="relative" mb="7">
        <Plank
          onClick={handleRemoveElem}
          elems={tryElems}
          transform="translate(0,-50%)"
          top={`${boxHeight}%`}
          left={0}
          transition="top 1s cubic-bezier(.47,1.64,.41,.8)"
        />
        <Plank
          bucket
          transform="translate(0,50%)"
          bottom={`${boxHeight}%`}
          right={0}
          transition="bottom 1s cubic-bezier(.47,1.64,.41,.8)"
        />
      </Flex>
      <GameHelper text="Cliquez sur un objet à poser sur la balance">
        <Flex
          justify="center"
          align="center"
          flexWrap="wrap"
          h="fit-content"
          w="100%"
          zIndex="5"
          textAlign="center"
          rounded={8}
        >
          {ELEMENTS.map(elem => (
            <MoreOrLessButton
              onClick={handleClick}
              key={elem.value}
              value={elem.value}
              imageSrc={elem.imageSrc}
              disabled={tryValue >= MAX_NUMBER || !me.canMove || !me.canPlay}
            />
          ))}
        </Flex>
      </GameHelper>
      <FingerAnimation
        opacity={tryValue ? 1 : 0}
        rotation={tryValue > randomNumber ? 180 : 0}
      />
    </>
  );
}

const Plank = ({ onClick, bucket = false, elems = [], ...props }) => {
  return (
    <Flex
      zIndex="1"
      justify="center"
      align="center"
      direction="column"
      w="45%"
      textAlign="center"
      position="absolute"
      {...props}
    >
      <GameHelper
        canDisplay={elems.length > 0}
        text="Cliquez sur un objet pour le retirer de la balance"
      >
        <Box h="90px" w="100%">
          {bucket && (
            <Image placeSelf="center" h="100%" w="100%" src={BucketSvg} />
          )}
          {!!elems.length && (
            <Flex
              h="100%"
              zIndex="1"
              justifyContent="center"
              wrap="wrap-reverse"
              overflow="visible"
              justify="start"
              w="100%"
            >
              {elems.map((tryElem, index) => (
                <Image
                  zIndex="1"
                  _hover={{ filter: 'grayscale(1)', opacity: 0.8 }}
                  cursor="pointer"
                  onClick={() => onClick(index)}
                  h="fit-content"
                  maxW="50%"
                  maxH="60px"
                  key={tryElem.imageSrc + index}
                  src={tryElem.imageSrc}
                />
              ))}
            </Flex>
          )}
        </Box>
        <Box w="100%" h="20px" bg="white" borderRadius="xl" />
      </GameHelper>
    </Flex>
  );
};
export default MoreOrLess;
