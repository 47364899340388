import React, { useMemo } from 'react';
import Modal from './Modal';
import {
  ModalHeader,
  ModalBody,
  VStack,
  Text,
  HStack,
  Image,
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import Player from '../Player/Player';
import MotionBox from '../MotionBox';
import computeTrophies from '../../utils/computeTrophies';

function StatsModal({ onClose, isOpen }) {
  const { players } = useSelector(state => state.game);
  const trophies = useMemo(
    () => (isOpen ? computeTrophies(players) : []),
    [players, isOpen]
  );

  return (
    <Modal
      onClose={onClose}
      // isOpen={isOpen}
      isOpen={isOpen}
      size="2xl"
      isCentered={false}
    >
      <ModalHeader bg="gold" fontSize="3xl" align="center">
        Distribution des trophées
      </ModalHeader>
      <ModalBody bg="sand" p="8">
        {trophies.map(trophy => (
          <TrophyCard key={trophy.title} {...trophy} />
        ))}
      </ModalBody>
    </Modal>
  );
}

function TrophyCard({
  player,
  title,
  trophySrc,
  stat,
  bg = 'gold',
  color = 'black',
}) {
  return (
    <VStack color={color} mb="8" p="4" bg={bg} borderRadius="2xl" spacing={5}>
      <Text fontWeight="bold" fontSize="3xl">
        {title}
      </Text>
      <HStack h="20vh" spacing={5}>
        <Player
          color={color}
          h="100%"
          position="initial"
          name={player?.name}
          avatar={player?.avatar}
          title={player?.title}
        />
        <MotionBox
          h="80%"
          initial={{ filter: 'brightness(0)', scale: 0.5 }}
          animate={{
            scale: [1.05, 1],
            filter: ['brightness(1.7)', 'brightness(1)'],
          }}
          transition={{
            type: 'spring',
            mass: 0.5,

            repeat: Infinity,
            repeatType: 'reverse',
            duration: 1,
          }}
        >
          <Image h="100%" w="auto" src={trophySrc} alt="trophy" />
        </MotionBox>
      </HStack>
      <Text fontWeight="bold" fontSize="xl">
        {stat}
      </Text>
    </VStack>
  );
}

export default StatsModal;
