import React from 'react';
import { Heading, Text, VStack, Container } from '@chakra-ui/react';
import Link from '../components/Link';
import { Link as ChakraLink } from '@chakra-ui/react';

const AboutPage = () => {
  return (
    <Container maxW="container.md" mt={8}>
      <VStack w="90%" align="start" spacing={6}>
        <Heading as="h1" size="xl">
          À Propos de Cozy Surviving
        </Heading>

        <Text>
          Bienvenue dans Cozy Surviving, une aventure en ligne avec vos amis
          pour briller en épreuve ou en stratégie.
        </Text>

        <Text>
          Cozy Surviving utilise la technologie des websockets pour offrir une
          expérience de jeu en temps réel. Plongez dans des interactions
          instantanées avec d'autres joueurs, que ce soit pour des combats
          épiques, des échanges commerciaux ou des quêtes en équipe.
        </Text>

        <Heading as="h2" size="lg">
          Authentification
        </Heading>

        <Text>
          Pour personnaliser votre expérience, Cozy Surviving propose une option
          d'authentification. Connectez-vous via Firebase pour changer votre
          avatar et profiter de fonctionnalités exclusives. Les informations
          collectées dépendent de votre méthode d'authentification.
        </Text>

        <Heading as="h2" size="lg">
          Stockage des Données
        </Heading>

        <Text>
          Vos données sont stockées en toute sécurité avec Firebase Auth et
          Firestore, garantissant la confidentialité de votre expérience de jeu.
        </Text>

        <Heading as="h2" size="lg">
          Aucun Cookie Intrusif
        </Heading>

        <Text>
          Cozy Surviving ne fait usage d'aucun cookie intrusif. Nous ne stockons
          que les informations nécessaires au bon fonctionnement du jeu et à
          votre confort d'utilisation.
        </Text>

        <Heading as="h2" size="lg">
          Conditions Générales
        </Heading>

        <Text>
          En jouant à Cozy Surviving, vous acceptez nos{' '}
          <Link color="blue.500" to="/conditions">
            conditions générales d'utilisation
          </Link>
          . Nous vous encourageons à les consulter attentivement.
        </Text>
      </VStack>
      <VStack py={3} spacing={4} align="start">
        <Heading as="h2" size="lg">
          Crédits
        </Heading>
        <Heading as="h3" size="md">
          Remerciements
        </Heading>

        <Text>
          Remerciements à toutes les personnes du{' '}
          <ChakraLink color="vegetation" href="https://discord.gg/TvDCnEU5gf">
            discord
          </ChakraLink>{' '}
          qui ont participé aux sessions de test et/ou aux messages de vote des
          bots mais également suggérés des améliorations / des épreuves :
        </Text>

        <Text>- Hapon</Text>
        <Text>- Jordan</Text>
        <Text>- Giulfeu</Text>
        <Text>- Cosmos</Text>
        <Text>- Spidercraft</Text>
        <Text>- Nabla</Text>
        <Text>- Fyrop</Text>
        <Text>- PiouRixx</Text>

        <Heading as="h3" size="md">
          Assets
        </Heading>
        <Text>
          <ChakraLink color="vegetation" href="https://www.dicebear.com/">
            Avatars
          </ChakraLink>
        </Text>
        <Text>
          <ChakraLink
            color="vegetation"
            href="https://pixabay.com/?utm_source=link-attribution&utm_medium=referral&utm_campaign=music&utm_content=96335"
          >
            Wining bracelet
          </ChakraLink>
        </Text>

        <Text>
          <ChakraLink
            color="vegetation"
            href="https://pixabay.com/sound-effects/winmutedguitar-39644/"
          >
            Bracelet en vote
          </ChakraLink>
        </Text>

        <Text>
          <ChakraLink
            color="vegetation"
            href="https://pixabay.com/sound-effects/id-43293/"
          >
            Pêche du poisson
          </ChakraLink>
        </Text>

        <Text>
          <ChakraLink
            color="vegetation"
            href="https://pixabay.com/sound-effects/woom-94361/"
          >
            Vote
          </ChakraLink>
        </Text>

        <Text>
          <ChakraLink
            color="vegetation"
            href="https://pixabay.com/sound-effects/?utm_source=link-attribution&utm_medium=referral&utm_campaign=music&utm_content=99782"
          >
            Wining knife (demi-finale)
          </ChakraLink>
        </Text>

        <Text>
          Boule noire: Sound Effect by{' '}
          <ChakraLink
            color="vegetation"
            href="https://pixabay.com/users/universfield-28281460/?utm_source=link-attribution&utm_medium=referral&utm_campaign=music&utm_content=126515"
          >
            UNIVERSFIELD
          </ChakraLink>{' '}
          from{' '}
          <ChakraLink
            color="vegetation"
            href="https://pixabay.com/sound-effects//?utm_source=link-attribution&utm_medium=referral&utm_campaign=music&utm_content=126515"
          >
            Pixabay
          </ChakraLink>
        </Text>

        <Text>
          Boule blanche: Sound Effect by{' '}
          <ChakraLink
            color="vegetation"
            href="https://pixabay.com/users/universfield-28281460/?utm_source=link-attribution&utm_medium=referral&utm_campaign=music&utm_content=132126"
          >
            UNIVERSFIELD
          </ChakraLink>{' '}
          from{' '}
          <ChakraLink
            color="vegetation"
            href="https://pixabay.com/sound-effects//?utm_source=link-attribution&utm_medium=referral&utm_campaign=music&utm_content=132126"
          >
            Pixabay
          </ChakraLink>
        </Text>

        <Text>
          Down Sound: Sound Effect by{' '}
          <ChakraLink
            color="vegetation"
            href="https://pixabay.com/users/universfield-28281460/?utm_source=link-attribution&utm_medium=referral&utm_campaign=music&utm_content=129254"
          >
            UNIVERSFIELD
          </ChakraLink>{' '}
          from{' '}
          <ChakraLink
            color="vegetation"
            href="https://pixabay.com/sound-effects//?utm_source=link-attribution&utm_medium=referral&utm_campaign=music&utm_content=129254"
          >
            Pixabay
          </ChakraLink>
        </Text>

        <Text>
          Up Sound: Sound Effect by{' '}
          <ChakraLink
            color="vegetation"
            href="https://pixabay.com/users/universfield-28281460/?utm_source=link-attribution&utm_medium=referral&utm_campaign=music&utm_content=153314"
          >
            UNIVERSFIELD
          </ChakraLink>{' '}
          from{' '}
          <ChakraLink
            color="vegetation"
            href="https://pixabay.com/sound-effects//?utm_source=link-attribution&utm_medium=referral&utm_campaign=music&utm_content=153314"
          >
            Pixabay
          </ChakraLink>
        </Text>
        <Text>
          Hint Sound : Sound Effect by{' '}
          <ChakraLink
            color="vegetation"
            href="https://pixabay.com/users/floraphonic-38928062/?utm_source=link-attribution&utm_medium=referral&utm_campaign=music&utm_content=172688"
            isExternal
          >
            floraphonic
          </ChakraLink>{' '}
          from{' '}
          <ChakraLink
            color="vegetation"
            href="https://pixabay.com/?utm_source=link-attribution&utm_medium=referral&utm_campaign=music&utm_content=172688"
            isExternal
          >
            Pixabay
          </ChakraLink>
        </Text>
        <Text>
          Final winner : Sound Effect from{' '}
          <ChakraLink
            color="vegetation"
            href="https://pixabay.com/?utm_source=link-attribution&utm_medium=referral&utm_campaign=music&utm_content=14800"
            isExternal
          >
            Pixabay
          </ChakraLink>
        </Text>
        <Text>
          Sad music : Sound Effect from{' '}
          <ChakraLink
            color="vegetation"
            href="https://pixabay.com/sound-effects/?utm_source=link-attribution&utm_medium=referral&utm_campaign=music&utm_content=72454"
            isExternal
          >
            Pixabay
          </ChakraLink>
        </Text>
        <Text>
          Coins : Sound Effect by{' '}
          <ChakraLink
            color="vegetation"
            href="https://pixabay.com/users/floraphonic-38928062/?utm_source=link-attribution&utm_medium=referral&utm_campaign=music&utm_content=183920"
            isExternal
          >
            floraphonic
          </ChakraLink>{' '}
          from{' '}
          <ChakraLink
            color="vegetation"
            href="https://pixabay.com/?utm_source=link-attribution&utm_medium=referral&utm_campaign=music&utm_content=183920"
            isExternal
          >
            Pixabay
          </ChakraLink>
        </Text>
        <Text>
          Forest : Music by{' '}
          <ChakraLink
            color="vegetation"
            href="https://pixabay.com/users/syouki_takahashi-3820204/?utm_source=link-attribution&utm_medium=referral&utm_campaign=music&utm_content=184304"
            isExternal
          >
            Syouki Takahashi
          </ChakraLink>{' '}
          from{' '}
          <ChakraLink
            color="vegetation"
            href="https://pixabay.com/?utm_source=link-attribution&utm_medium=referral&utm_campaign=music&utm_content=184304"
            isExternal
          >
            Pixabay
          </ChakraLink>
        </Text>
        <Text>
          Vote : Music by{' '}
          <ChakraLink
            color="vegetation"
            href="https://pixabay.com/users/natureseye-18615106/?utm_source=link-attribution&utm_medium=referral&utm_campaign=music&utm_content=4693"
            isExternal
          >
            Leigh Robinson
          </ChakraLink>{' '}
          from{' '}
          <ChakraLink
            color="vegetation"
            href="https://pixabay.com/?utm_source=link-attribution&utm_medium=referral&utm_campaign=music&utm_content=4693"
            isExternal
          >
            Pixabay
          </ChakraLink>
        </Text>
        <Text>
          Fishing : Music by{' '}
          <ChakraLink
            color="vegetation"
            href="https://pixabay.com/users/julia_music-43090885/?utm_source=link-attribution&utm_medium=referral&utm_campaign=music&utm_content=199729"
            isExternal
          >
            Júlia
          </ChakraLink>{' '}
          from{' '}
          <ChakraLink
            color="vegetation"
            href="https://pixabay.com/music/?utm_source=link-attribution&utm_medium=referral&utm_campaign=music&utm_content=199729"
            isExternal
          >
            Pixabay
          </ChakraLink>
        </Text>
        <Text>
          Campfire : Sound Effect from{' '}
          <ChakraLink
            color="vegetation"
            href="https://pixabay.com/sound-effects/?utm_source=link-attribution&utm_medium=referral&utm_campaign=music&utm_content=26034"
            isExternal
          >
            Pixabay
          </ChakraLink>
        </Text>
        <Text>
          Villa : Music by{' '}
          <ChakraLink
            color="vegetation"
            href="https://pixabay.com/users/music_for_videos-26992513/?utm_source=link-attribution&utm_medium=referral&utm_campaign=music&utm_content=165265"
            isExternal
          >
            Oleg Kyrylkovv
          </ChakraLink>{' '}
          from{' '}
          <ChakraLink
            color="vegetation"
            href="https://pixabay.com/music/?utm_source=link-attribution&utm_medium=referral&utm_campaign=music&utm_content=165265"
            isExternal
          >
            Pixabay
          </ChakraLink>
        </Text>

      </VStack>
      <VStack py={3} spacing={4} align="start">
        <Heading as="h2" size="lg">
          Contact
        </Heading>

        <Text>
          Twitter :{' '}
          <ChakraLink href="https://twitter.com/CozyCodeur" isExternal>
            @CozyCodeur
          </ChakraLink>
        </Text>

        <Text>
          Discord :{' '}
          <ChakraLink href="https://discord.gg/TvDCnEU5gf" isExternal>
            Serveur Discord
          </ChakraLink>
        </Text>

        <Text>
          Portfolio :{' '}
          <ChakraLink href="https://www.cozy-codeur.fr" isExternal>
            www.cozy-codeur.fr
          </ChakraLink>
        </Text>
      </VStack>
    </Container>
  );
};

export default AboutPage;
