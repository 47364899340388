import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
} from '@chakra-ui/react';
import { CopyIcon } from '@chakra-ui/icons';
import Icon from '../Icon';
import useQuit from 'hooks/useQuit';
import useSuccess from 'hooks/useSuccess';
import { useSelector } from 'react-redux';

function Option() {
  const [onSuccess] = useSuccess();
  const { gameId } = useSelector(state => state.game);
  const handleQuit = useQuit();
  const getInvitationLink = () => {
    const url = `${window.location.origin}/join/${gameId}`;
    onSuccess(`Le lien spectateur a été copié vous pouvez le partager ${url}`);
    navigator.clipboard.writeText(url);
    return url;
  };


  return (
    <Menu closeOnSelect={false}>
      <MenuButton
        as={IconButton}
        aria-label="Options"
        icon={<Icon boxSize="10" icon={'mdi:cog'} />}
        position="absolute"
        bg="white"
        bottom={2}
        left={2}
        zIndex={2}
      />
      <MenuList zIndex={2}>
        <MenuItem
          onClick={getInvitationLink}
          color="blue"
          icon={<CopyIcon />}
        >
          Copier le lien spectateur
        </MenuItem>
        <MenuItem
          onClick={handleQuit}
          color="red"
          icon={<Icon boxSize="5" icon={'mdi:exit-to-app'} />}
        >
          Quitter
        </MenuItem>
      </MenuList>
    </Menu>
  );
}

export default Option;
