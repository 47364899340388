import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  auth,
  logInWithEmailAndPassword,
  signInWithGoogle,
} from '../../myFirebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Box, FormControl, FormLabel } from '@chakra-ui/react';
import Link from '../Link/Link';
import Input from '../Input/Input';
import Button from '../Button/Button';
import Form from '../Form/Form';
import useError from '../../hooks/useError';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [user, loading] = useAuthState(auth);
  const navigate = useNavigate();
  const [onError] = useError(() => {
    setEmail('');
    setPassword('');
  });

  const [searchParams] = useSearchParams();
  const redirect = useMemo(() => searchParams.get("redirect") || "", [searchParams])

  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return;
    }
    if (user) {
      if (redirect) {
        window.location.replace(redirect)
        return
      }
      navigate(`/`);
    }
  }, [user, loading, navigate, redirect]);
  return (
    <>
      <Form
        onSubmit={e => {
          e.preventDefault();
          logInWithEmailAndPassword(email, password, onError);
        }}
      >
        <FormControl isRequired>
          <FormLabel htmlFor="email">Email</FormLabel>
          <Input
            autoFocus
            value={email}
            onChange={e => setEmail(e.target.value)}
            placeholder="example@mail.com"
            type="email"
            autoComplete="email"
          />
        </FormControl>
        <FormControl isRequired>
          <FormLabel htmlFor="password">Password</FormLabel>
          <Input
            type="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
            placeholder="Password"
            autoComplete="current-password"
          />
        </FormControl>
        <Button w="20%" my="4" type="submit">
          Se connecter
        </Button>
      </Form>
      <Button
        bg="#4285f4"
        color="white"
        onClick={() => signInWithGoogle(onError)}
      >
        Se connecter avec Google
      </Button>
      <Link fontSize="2xl" color="black" mt="3" to="/reset-password">Mot de passe oublié</Link>
      <Box fontSize="4xl">
        Pas encore de compte ? <Link to="/signup">Créer un compte</Link>
      </Box>
    </>
  );
}

export default Login;
