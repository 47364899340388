import React from 'react';
import Link from '../Link';
import Icon from '../Icon';

import { LOCATIONS } from '../../constants';
import { Button } from '@chakra-ui/react';
import useChangeLocation from '../../hooks/useChangeLocation';

function BackButton({
  children,
  to = '/',
  onClick,
  position = 'absolute',
  ...props
}) {
  return (
    <Link
      fontSize="3xl"
      {...props}
      position={position}
      top="1%"
      left="1%"
      to={to}
      onClick={onClick}
    >
      <Icon icon="ion:caret-back-sharp" boxSize="5" />
      {children || 'Back Home'}
    </Link>
  );
}

function BackToCampButton({ children, ...props }) {
  const changeLocation = useChangeLocation();

  const handleClick = () => {
    changeLocation(LOCATIONS.CAMP);
  };

  return (
    <Button
      onClick={handleClick}
      variant="link"
      fontSize="3xl"
      position="absolute"
      top="10%"
      left="1%"
      color="sky"
      {...props}
    >
      <Icon icon="ion:caret-back-sharp" boxSize="5" />
      {children || 'Retour à la plage'}
    </Button>
  );
}

export { BackToCampButton };
export default BackButton;
