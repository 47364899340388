import { useNavigate } from 'react-router-dom';

const useQuit = redirect => {
  const navigate = useNavigate();
  const handleQuit = () => {
    localStorage.removeItem('lastGameId');
    localStorage.removeItem('lastMyId');
    navigate(redirect || '/');
    window.location.reload();
  };

  return handleQuit;
};

export default useQuit;
