import { useEffect, useState } from 'react';
import Player from '../../components/Player/Player';
import winnerSound from '../../sounds/final-winner.mp3';
import MotionBox from '../../components/MotionBox';
import useSound from '../../hooks/useSound';
import { useSelector } from 'react-redux';
import updateUserStats from 'utils/updateUserStats';

const WinnerCelebration = ({ player }) => {
  const { user } = useSelector(state => state.user);
  const { me } = useSelector(state => state.game);
  const [play] = useSound(winnerSound, { interrupt: true });

  const [updatedStats, setUpdatedStats] = useState(false);

  useEffect(() => {
    if (!play) return;

    play();
  }, [play]);

  // new stats  to firebase

  useEffect(() => {
    if (updatedStats || !user || !me) return;
    let myStats = { total_games: 1 };
    if (me.id === player.id) {
      // if we are the final winner there's a special stat just for firebase
      myStats = { ...myStats, total_final_won: 1 };
    }

    myStats = { ...myStats, ...me.stats };
    updateUserStats(user, myStats);
    setUpdatedStats(true);
    // eslint-disable-next-line
  }, [me, user]);

  return (
    <MotionBox
      initial={{ opacity: 0, filter: 'brightness(0)', scale: 0.5 }}
      animate={{ opacity: 1, filter: 'brightness(1)', scale: 1 }}
      transition={{
        ease: 'anticipate',
        duration: 4,
      }}
      display="flex"
      justifyContent="center"
      w="100%"
    >
      <Player
        position="unset"
        name={player.name}
        avatar={player.avatar}
        x={10}
        boxSize="50vw"
      />
    </MotionBox>
  );
};

export default WinnerCelebration;
