import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { GameButton } from '../../components/Button';
import useSocket from '../../hooks/useSocket';
import { AnimatedSprite, Sprite } from '@pixi/react';
import PixiCanvas from '../../components/PixiCanvas';
import PlayerPixi from '../../components/PixiCanvas/PlayerPixi';

import arrow from '../../png/arrow.png';
import target from '../../png/target.png';
import targetImage from 'sprites/trials/archery/target.png';
import TeamScores from '../../components/Teams/TeamScores';
import PlayerScore from '../../components/Player/PlayerScore';
import GameHelper from '../../components/GameHelper/GameHelper';
import usePixi from 'hooks/usePixi';

import arrowSound from "sounds/arrow.mp3";
import targetSound from "sounds/target.mp3";
import * as PIXI from 'pixi.js';
import useSound from 'hooks/useSound';
import { VStack } from '@chakra-ui/react';

function Archery() {
  const [shoot, setShoot] = useState(false);
  const { width, height } = usePixi();

  const { me, gameId, myId, reunification } = useSelector(state => state.game);
  const socket = useSocket();

  const [playArrow] = useSound(arrowSound);
  const [playTarget] = useSound(targetSound);

  const handleShoot = () => {
    if (!me.canMove || !me.canPlay || shoot) return;
    setShoot(true);
    socket.emit('shoot-arrow', { gameId, myId });
    playArrow();
  };

  const handleArrowTouchTarget = () => {
    socket.emit('touch-target', { gameId, myId });
    playTarget();
  };

  const PLAYER_COORDS = { x: width / 4, y: height / 2 };

  return (
    <>
    <VStack>
      {reunification ? <PlayerScore /> : <TeamScores />}
    </VStack>
      <PixiCanvas>
        <PlayerPixi
          facingRight
          coords={PLAYER_COORDS}
          name={me.name}
          id={me.id}
          isMe={true}
          avatar={me?.avatar}
          title={me?.title}
        />
        <ArrowAndTarget
          move={shoot}
          player={{ ...me, ...PLAYER_COORDS }}
          onArrowEnd={() => setShoot(false)}
          onArrowTouchSocket={handleArrowTouchTarget}
          shoot={shoot}
        />
      </PixiCanvas>

      <GameHelper text="Cliquez ici pour tirer une flèche">
        <GameButton onClick={handleShoot}>Shoot an Arrow</GameButton>
      </GameHelper>
    </>
  );
}

const ArrowAndTarget = ({
  player,
  move = false,
  onArrowEnd,
  onArrowTouchSocket,
  shoot = false,
}) => {
  const { width } = usePixi();
  const speed = 30;
  const [arrowPos, setArrowPos] = useState({
    x: player.x + 100,
    y: player.y,
    width: 100,
    height: 60,
  });

  const updateArrow = () => {
    setArrowPos(pos => ({ ...pos, x: pos.x + speed }));
  };

  useEffect(() => {
    let gameLoop;
    if (move) {
      gameLoop = setInterval(updateArrow, 30);
    }
    return () => clearInterval(gameLoop);
  }, [move]);

  const resetArrow = () => {
    onArrowEnd();
    setArrowPos(pos => ({ ...pos, x: player.x + 100, y: player.y }));
  };

  useEffect(() => {
    if (arrowPos.x >= width) {
      resetArrow();
      return;
    }
    // eslint-disable-next-line
  }, [arrowPos.x, onArrowEnd, player]);

  const onArrowTouch = () => {
    onArrowTouchSocket();
    resetArrow();
  };

  return (
    <>
      <Sprite
        alpha={move ? 1 : 0}
        id="arrow"
        image={arrow}
        x={arrowPos.x}
        y={arrowPos.y}
        anchor={{ x: 0.5, y: 0.5 }}
        width={arrowPos.width || 100}
        height={arrowPos.height || 60}
      />
      <Target arrowPos={arrowPos} onArrowTouch={onArrowTouch} shoot={shoot} />
    </>
  );
};

const Target = ({ arrowPos, onArrowTouch, shoot = false }) => {
  const { width, height } = usePixi();
  const [speed, setSpeed] = useState(2);
  const [isVisible, setIsVisible] = useState(true);
  const [textures, setTextures] = useState([]);
  const [targetPos, setTargetPos] = useState({
    x: Math.min(width/2 + 250, width-70),
    y: height,
    direction: 1,
    width: 50,
    height: 50,
  });
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    const loadTextures = async () => {
      const texture = PIXI.BaseTexture.from(targetImage);
      const frameWidth = texture.width / 5; // Assuming 5 frames in the image
      const frameHeight = texture.height;

      const frames = [];
      for (let i = 0; i < 5; i++) {
        const frame = new PIXI.Texture(texture, new PIXI.Rectangle(i * frameWidth, 0, frameWidth, frameHeight));
        frames.push(frame);
      }
      setTextures(frames);
    };

    loadTextures();
    setTimeout(() => {
      // setDisplayTarget(true)
      loadTextures();
    }, 1000);
  }, []);

  useEffect(() => {
    setTargetPos(pos => ({
      ...pos,
      x: Math.min(width/2 + 250, width-70),
    }));
  }, [width]);

  const updateTarget = () => {
    setTargetPos(pos => ({ ...pos, y: pos.y + speed * pos.direction }));
  };

  const handleAnimation = () => {
    setIsPlaying(true);
    setTimeout(() => {
      setIsPlaying(false);
      setIsVisible(false);
      setTimeout(() => setIsVisible(true), 1000); // Hide target for 1 second
    }, 500); // Adjust the duration of the animation as needed
  };

  useEffect(() => {
    let gameLoop;
    gameLoop = setInterval(updateTarget, 10);
    return () => clearInterval(gameLoop);
    // eslint-disable-next-line
  }, [speed]);

  useEffect(() => {
    if (!shoot) return;
    const arrowHalfWidth = arrowPos.width / 2;
    const targetHalfWidth = targetPos.width / 2;
    const targetHalfHeight = targetPos.height / 2;

    if (
      arrowPos.x - arrowHalfWidth < targetPos.x + targetHalfWidth &&
      arrowPos.x + arrowHalfWidth > targetPos.x - targetHalfWidth &&
      arrowPos.y < targetPos.y + targetHalfHeight &&
      arrowPos.y > targetPos.y - targetHalfHeight
    ) {
      handleAnimation();
      onArrowTouch();
      setSpeed(speed => speed + 1);
    }
  }, [arrowPos, targetPos, onArrowTouch, shoot]);

  useEffect(() => {
    if (targetPos.y >= height) {
      setTargetPos(pos => ({ ...pos, direction: -1 }));
      return;
    }
    if (targetPos.y <= 0) {
      setTargetPos(pos => ({ ...pos, direction: 1 }));
      return;
    }
  }, [targetPos.y, height]);

  return (
        <AnimatedSprite
          id="target"
          textures={textures.length ? textures : undefined}
          images={textures.length ? undefined : [target]}
          x={targetPos.x}
          y={targetPos.y}
          anchor={{ x: 0.5, y: 0.5 }}
          width={targetPos.width}
          height={targetPos.height}
          isPlaying={isPlaying}
          initialFrame={0}
          animationSpeed={0.1} // Adjust the speed of the animation
          visible={isVisible}
        />
  );
};

export default Archery;
