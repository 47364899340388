import Modal from './Modal';
import { ModalHeader, } from '@chakra-ui/react';

import Inventory from 'components/Camp/Inventory';
import useSound from 'hooks/useSound';
import { useEffect } from 'react';

import closeSound from "sounds/close.mp3"
import openSound from "sounds/open.mp3"


function InventoryModal({ isOpen, playerId, onClose = () => {} }) {
  const [playOpen, {stop}] = useSound(openSound);
  const [playClose] = useSound(closeSound);
  
  useEffect(() => {
    if (isOpen) {
      playOpen()
    }

    return () => stop()
  }, [isOpen, playOpen, stop])
  

  return (
    <Modal
      onClose={() => {
        playClose()
        onClose();
      }}
      isOpen={isOpen}
      bg="black"
      size="xl"
      p="2"
    >
      <ModalHeader color="white" fontSize="3xl" align="center">
        Inventaire
      </ModalHeader>
      <Inventory playerId={playerId} itemsProps={{ style: { height: "5vh", margin: "5px" } }} />
    </Modal>
  );
}

export default InventoryModal;
