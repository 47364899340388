import React, { useMemo } from 'react';
import Register from '../components/Register/Register';
import Title from '../components/Title/Title';
import { Flex } from '@chakra-ui/react';
import { useSearchParams } from 'react-router-dom';

function SignUpPage() {
  const [searchParams] = useSearchParams();
 const redirect = useMemo(() => searchParams.get("redirect") || "", [searchParams])

  return (
    <Flex direction="column" justify="space-around" align="center" h="80vh">
      <Title>Créer un compte</Title>
      <Register redirect={redirect} />
    </Flex>
  );
}

export default SignUpPage;
