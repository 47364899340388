import React, {useEffect} from 'react';
import { Flex, Text, ModalHeader, useDisclosure } from '@chakra-ui/react';
import { ReactComponent as FireSvg } from 'svg/fire.svg';
import { svgSize } from './constants';
import MotionBox from 'components/MotionBox';
import { useDispatch } from 'react-redux';
import {
  activePowerAction,
  cancelPowerAction,
} from 'redux/features/gameSlice';
import Modal from 'components/Modal';
import Button from 'components/Button';
import fireSound from 'sounds/fire-power.mp3';
import useSound from 'hooks/useSound';

const Fire = ({ value = 0, fontSize = '2xl', canUse=true, ...props }) => {
  const dispatch = useDispatch()
  const {isOpen, onOpen, onClose} = useDisclosure()
  const handleUsePower = () => {
    dispatch(activePowerAction(true))
    onOpen()
  }
  return (
    <Flex cursor={value && canUse ? "pointer" : "auto"} onClick={value && canUse && handleUsePower} opacity={value ? '1' : '0.3'} direction="column" align="center">
      {value && canUse ? <MotionBox
        initial={{ scale: 0.9, rotate: 0 }}
        animate={{
          scale: [1, 1, 2.3, 1],
          rotate: [10, -10, 10, 10, -10, 10, 0],
        }}
        transition={{
          ease: 'easeInOut',
          repeat: Infinity,
          repeatDelay: 7,
          duration: 3,
        }}
      >
        <FireSvg style={{ height: svgSize }} {...props} />
      </MotionBox> : 
        <FireSvg style={{ height: svgSize }} {...props} />
      }
      <Text color="orange" fontWeight="extrabold" fontSize={fontSize}>
        {value}
      </Text>
      <FireModal isOpen={isOpen} onClose={onClose}/>
    </Flex>
  );
};

export function FireModal({ isOpen, onClose, discover=false }) {
  const dispatch = useDispatch()
  const handleCancelPower = () => {
    dispatch(cancelPowerAction())
    onClose()
  }
  const [play, { stop }] = useSound(fireSound);
  useEffect(() => {
    if (isOpen) play();
    return () => {
      stop();
    };
  }, [isOpen, play, stop]);

  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      bg="black"
      size="md"
      closeOnOverlayClick={false}
    >
      <ModalHeader fontSize="2xl" align="center" color="white">
        {discover ? "Vous obtenez une flamme, je me demande à quoi ça sert ..." : "Vous avez activé une flamme, cliquez sur un élément de la forêt pour l'utiliser"}
      </ModalHeader>
      <Flex
        direction="column"
        p="3"
        fontSize="xl"
        align="center"
        justify="center"
        textAlign="center"
      >
        <FireSvg style={{ height: svgSize }} />
      </Flex>
      <Flex align="center" justify="center">
        {!discover && <Button bg="sky" color="white" onClick={handleCancelPower}>Annuler</Button>}
        <Button onClick={onClose}>Ok !</Button>
      </Flex>
    </Modal>
  );
}

export default Fire;
