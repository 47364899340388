import { updatePlayerItems } from '../redux/features/gameSlice';
import { useDispatch, useSelector } from 'react-redux';
import useSocket from './useSocket';
import { useMemo } from 'react';
import { ITEM_TYPE } from 'constants/items';

export default function usePlayerItems() {
  const dispatch = useDispatch();
  const socket = useSocket();
  const { me, gameId, myId } = useSelector(state => state.game);

  const updateItems = (items, type) => {
    if (type === ITEM_TYPE.COLLECTIBLES) {
      updateCollectibles(items)
      return
    }
    if (type === ITEM_TYPE.CRAFT_ITEMS) {
      updateCraftItems(items)
      return
    }
    dispatch(updatePlayerItems(items));
    socket.emit('items', { items, gameId, myId });
  };

  const updateCraftItems = craftItems => {
    dispatch(updatePlayerItems(items));
    socket.emit('items', { items: { craftItems }, gameId, myId });
  };

  const updateCollectibles = collectibles => {
    dispatch(updatePlayerItems(items));
    socket.emit('items', { items: { collectibles }, gameId, myId });
  };

  const items = useMemo(() => ({
    fishes: me?.fishes,
    bracelets: me?.bracelets,
    palm: me?.palm,
    lifeBar: me?.lifeBar,
    fire: me?.fire,
    coins: me?.coins,

    ...me?.collectibles,
    ...me?.craftItems,
  }), [me]);

  return { updateItems, updateCraftItems, updateCollectibles, items };
}
