import React, { useCallback, useMemo } from 'react';
import {
  Image,
  Flex,
  Text,
  useMediaQuery,
  Box,
  IconButton,
  VStack,
  HStack,
} from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import { useSelector } from 'react-redux';
import useSocket from '../../hooks/useSocket';
import userTitles from 'constants/userTitles';
import Icon from 'components/Icon';

function Player({
  x,
  y,
  name,
  id,
  facingRight = false,
  avatar,
  canDelete = false,
  color = 'black',
  noUsername = false,
  title,
  ...props
}) {
  const { myId, gameId } = useSelector(state => state.game);
  const socket = useSocket();

  const isMe = useCallback(() => myId === id, [myId, id]);

  const [isLargerThan800] = useMediaQuery('(min-width: 800px)');
  const playerSize = isLargerThan800 ? '150px' : '80px';

  const handleDelete = () => {
    if (!id) return;
    socket.emit('deleteUser', gameId, id);
  };

  const avatarSrc = useMemo(() =>
    avatar
      ? avatar
      : `https://api.dicebear.com/7.x/adventurer/svg?seed=${name?.replaceAll(
        '?',
        ''
      )}`
    , [avatar, name])

  return (
    <Flex
      position="absolute"
      align="center"
      justify="center"
      direction="column"
      left={`${x}%`}
      bottom={`${y}%`}
      boxSize={playerSize}
      zIndex={isMe() ? 1 : 0}
      m="1"
      userSelect="none"
      transition="all 0.2s ease-in-out"
      {...props}
    >
      <VStack opacity={'0.8'} w="100%" spacing="0" borderRadius="lg">
        {!noUsername && (
          <Text
            color={isMe() ? 'vegetation' : color}
            fontWeight={isMe() ? '900' : 'bold'}
            fontSize={isMe() ? '2xl' : 'md'}
          >
            {name}
          </Text>
        )}
        {title && (
          <HStack>
            <Text
              color={'black'}
              fontWeight={isMe() ? '900' : 'bold'}
              fontSize={isMe() ? 'sm' : 'xs'}
              my="0"
            >
              {userTitles[title].name}
            </Text>
            <Icon
              boxSize="5"
              color={userTitles[title].color}
              icon={userTitles[title].icon}
            />
          </HStack>
        )}
      </VStack>
      <Image
        transform={facingRight ? 'scaleX(-1)' : 'scaleX(1)'}
        id="avatar"
        boxSize="100%"
        src={avatarSrc}
      />
      {canDelete && id !== myId && (
        <Box zIndex={5} position="absolute" top="2" right="2">
          <IconButton
            icon={<CloseIcon />}
            aria-label="Delete"
            onClick={handleDelete}
            variant="ghost"
            colorScheme="red"
          />
        </Box>
      )}
    </Flex>
  );
}

export default Player;
