import { useMediaQuery } from '@chakra-ui/react';
import { useMemo } from 'react'

const usePixi = () => {
  const [isLargerThan800] = useMediaQuery('(min-width: 800px)');

  const width = useMemo(() => isLargerThan800 ? window.innerWidth - 50 : window.innerWidth - 10, [isLargerThan800])
  const height = useMemo(() => window.innerHeight / 2 , [])

  return {width, height}

}

export default usePixi