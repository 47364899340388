import React from 'react';
import { Sprite } from '@pixi/react';

function ElementPixi({ x, y, image, size = 70, customRef, ...props }) {
  return (
    <Sprite
      image={image}
      x={x}
      y={y}
      anchor={{ x: 0.5, y: 0.5 }}
      width={size}
      height={size}
      ref={customRef}
      {...props}
    />
  );
}

export default ElementPixi;
