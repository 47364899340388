import { Image } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import MotionBox from '../../components/MotionBox';
import useSound from '../../hooks/useSound';

const MemoryElem = ({ imageSrc, soundSrc, type }) => {
  const [play] = useSound(soundSrc);

  useEffect(() => {
    play();
  }, [play]);

  return (
    <MotionBox
      key={type}
      animate={{ opacity: [1, 1, 0], scale: [0.7, 1] }}
      transition={{
        ease: 'anticipate',
        duration: 1.5,
      }}
      display="flex"
      h="60%"
      alignItems="center"
      justifyContent="center"
      m="5"
      w="100%"
    >
      <Image src={imageSrc} h="90%" alt={type} />
    </MotionBox>
  );
};

export default MemoryElem;
