import React from 'react';
import { useSelector } from 'react-redux';
import PlayersAbsolute from '../../components/PlayersAbsolute/PlayersAbsolute';
import { GameButton } from '../../components/Button';
import useSocket from '../../hooks/useSocket';
import TeamIcon from '../../components/Teams/TeamIcon';
import FlagIcon from '../../components/Teams/FlagIcon';
import GameHelper from '../../components/GameHelper/GameHelper';
import { Spacer } from '@chakra-ui/react';

function Relais() {
  const { players, gameId, myId, me } = useSelector(state => state.game);
  const socket = useSocket();

  const handleRun = () => {
    socket.emit('run', { gameId, myId });
  };

  return (
    <>
      <TeamIcon color="redTeam" position="absolute" left="5%" />
      <TeamIcon color="blueTeam" position="absolute" left="5%" top="30%" />

      <FlagIcon position="absolute" left="95%" />
      <FlagIcon position="absolute" left="95%" top="30%" />

      <PlayersAbsolute
        displayCondition={player => player.canMove && player.canPlay}
        players={players}
        facingRight
      />
      <Spacer h="50vh" />
      <GameHelper
        canDisplay={me.canMove && me.canPlay}
        text="Cliquez très vite ici pour courir! "
      >
        <GameButton disabled={!me.canMove || !me.canPlay} onClick={handleRun}>
          Run !
        </GameButton>
      </GameHelper>
    </>
  );
}

export default Relais;
