import React, { useState, useRef, useMemo, useEffect } from 'react';
import brokenTree from "sprites/trees/broken.png"
import fireSvg from 'svg/fire.svg';

import { Container } from '@pixi/react';
import * as PIXI from 'pixi.js';
import PixiCanvas from 'components/PixiCanvas';
import usePixi from 'hooks/usePixi';
import ElementPixi from 'components/PixiCanvas/ElementPixi';
import useSocket from 'hooks/useSocket';
import { useDispatch, useSelector } from 'react-redux';
import { cancelPowerAction, changeForestLocationAction} from 'redux/features/gameSlice';
import PlayerPixi from 'components/PixiCanvas/PlayerPixi';
import LocationButtons from './LocationButtons';
import useChangeForestLocation from 'hooks/useChangeForestLocation';
import AxeElement from './AxeElement';
import WalkingAnimation from 'animations/WalkingAnimation';

import BraceletSvg from 'svg/Bracelet.svg';

const gridW = 25;
const gridH = 11;

const Element = ({ image, position, onClick, isOpen, canClick, hasBracelet, power }) => {
  const [hovered, setHovered] = useState(false);
  const texture = PIXI.Texture.from(power && hovered ? fireSvg : isOpen ? brokenTree : image);

  const handlePointerOver = () => {
    setHovered(true);
  };

  const handlePointerOut = () => {
    setHovered(false);
  };

  return (
    <ElementPixi
      texture={texture}
      x={position.x}
      y={position.y}
      anchor={0.5}
      scale={hovered ? 0.9 : 1} // Reduce size on hover
      interactive={true}
      pointerdown={canClick ? onClick : undefined}
      pointerover={canClick ? handlePointerOver : undefined}
      pointerout={handlePointerOut}
      cursor="pointer"
      rotation={process.env.REACT_APP_DEV && hasBracelet ? '90' : '0'}
    />
  );
};

const PlayerWithBracelet = ({ player }) => {
  const [currentBracelets, setCurrentBracelets] = useState(player.bracelets || 0)
  const [showBracelet, setShowBracelet] = useState(false)

  useEffect(() => {
    if (player.bracelets > currentBracelets) {
      setShowBracelet(true)
      setCurrentBracelets(player.bracelets)
      setTimeout(() => {
        setShowBracelet(false)
      }, 3000);
    }
     // eslint-disable-next-line
  }, [player.bracelets])
  

return (
  <>
    <ElementPixi
      image={BraceletSvg} // Utilisez BraceletSvg comme image
      size={50}
      x={0} // Ajustez la position X en fonction de la taille de l'image
      y={-50} // Ajustez la position Y pour positionner l'image au-dessus du joueur
      visible={showBracelet}
    />
    <PlayerPixi
      key={player.firstId}
      name={player.name}
      coords={{ x: 0, y: 0 }}
      avatar={player?.avatar}
      showName={false}
    />
  </>
);
}


const BraceletCanvas = ({ elements, handleFoundBracelet, handleFoundHint, canClick, debounceClick, forestLocation, goldAxe = false }) => {
  const { me, gameId, power, myTeam } = useSelector(state => state.game);
  const changeForestLocation = useChangeForestLocation();
  const socket = useSocket();
  const dispatch = useDispatch();
  const { width, height } = usePixi();
  const containerRef = useRef(null);
  const [containerPosition] = useState({ x: 0, y: 0 });

  const playersInLocation = useMemo(
    () =>
      myTeam?.players?.filter(
        player => player.location === me.location && player.forestLocation === me.forestLocation
      ),
    [myTeam, me.location, me.forestLocation]
  );

  const handlePower = (pos) => {
    setTimeout(() => {
      dispatch(cancelPowerAction());
    }, 350);
    const toOpenPos = [];
    for (let x = 0; x < gridW; x++) {
      const tmpPos = pos - pos % gridW + x;
      toOpenPos.push(tmpPos);
    }
    for (let y = pos % gridW; y < (gridH * gridW); y += gridW) {
      const tmpPos = y;
      toOpenPos.push(tmpPos);
    }
    socket.emit('burnForestElement', { team: me.team, toOpenPos: toOpenPos, location: forestLocation, gameId });
  };

  const handleElementClick = (index, hasBracelet, isOpen, animationDuration = 15) => {
    const selectedPos = calculateGridPosition(index);
    const newContainerPosition = {
      x: width / 2 - selectedPos.x - selectedPos.width / 2,
      y: height / 2 - selectedPos.y - selectedPos.height / 2
    };

    if (isOpen) {
      animateCameraMove(newContainerPosition, animationDuration);
      socket.emit('pos', { pos: { x: index }, myId: me.firstId, gameId });
      return;
    }

    socket.emit('openForestElement', { team: me.team, pos: index, location: forestLocation, gameId });
    debounceClick && debounceClick(power);
    power && handlePower(index);
    if (hasBracelet) {
      handleFoundBracelet();
    } else {
      handleFoundHint(index, forestLocation);
    }
  };

  const calculateGridPosition = (index) => {
    const cols = 25;
    const spacingX = 100;
    const spacingY = 100;
    const row = Math.floor(index / cols);
    const col = index % cols;
    const x = col * spacingX;
    const y = row * spacingY;
    const width = 10; // Largeur de ton élément
    const height = 10; // Hauteur de ton élément

    return { x, y, width, height };
  };

  const animateCameraMove = (newPosition, duration = 15) => {
    const dx = newPosition.x - containerRef.current.x;
    const dy = newPosition.y - containerRef.current.y;

    let frame = 0;
    const animate = () => {
      if (frame < duration) {
        containerRef.current.x += dx / duration;
        containerRef.current.y += dy / duration;
        frame++;
        requestAnimationFrame(animate);
      }
    };

    requestAnimationFrame(animate);
  };

  const handleLocationClick = (location, pos) => {
    changeForestLocation(location);
    dispatch(changeForestLocationAction(location));
    let x = me.x;
    switch (pos) {
      case 0:
        x = gridW * gridH - (gridW - me.x % gridW);
        break;
      case 1:
        x = Math.floor(me.x / gridW) * gridW;
        break;
      case 2:
        x = me.x % gridW;
        break;
      case 3:
        x = Math.floor(me.x / gridW) * gridW + (gridW - 1);
        break;
      default:
        break;
    }
    socket.emit('pos', { pos: { x, y: me.x }, myId: me.firstId, gameId });
    handleElementClick(x, false, true, 1);
  };

  return (
    <PixiCanvas options={{ backgroundAlpha: 0, backgroundColor: "#818c3c" }}>
      <Container ref={containerRef} position={containerPosition}>
        <LocationButtons location={forestLocation} onClick={handleLocationClick} />
        {elements.map((elem, index) => (
          <Element
            key={`${myTeam?.name}${forestLocation}${elem.id}`}
            image={elem.image}
            position={calculateGridPosition(index)}
            onClick={() => handleElementClick(index, elem?.isBracelet, elem?.isOpen)}
            isOpen={elem?.isOpen}
            canClick={canClick}
            hasBracelet={elem?.isBracelet}
            location={forestLocation}
            power={power}
            me={me}
            index={index}
          />
        ))}
        {playersInLocation.map((player) => (
          <WalkingAnimation
            key={player.firstId}
            startX={calculateGridPosition(player.x).x + 40}
            startY={calculateGridPosition(player.x).y}
            endX={calculateGridPosition(player.x).x}
            endY={calculateGridPosition(player.x).y}
          >
            <PlayerWithBracelet player={player} />
          </WalkingAnimation>
        ))}
      </Container>
      <AxeElement goldAxe={goldAxe} isChopping={!canClick} />
    </PixiCanvas>
  );
};

export default BraceletCanvas;
