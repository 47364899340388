import React, { useRef, useEffect } from 'react';
import { Container, useApp } from '@pixi/react';
import { ColorMatrixFilter } from 'pixi.js';
import usePixi from 'hooks/usePixi';
import Confetti from './Confetti';

const JoyAnimation = ({ children }) => {
  const { width, height } = usePixi();
  const containerRef = useRef();
  const app = useApp();
  const maxScale = 1.5; // Target scale
  const duration = 300; // Duration in ticks (about 2 seconds with 60 FPS)
  const brightnessFilter = useRef(new ColorMatrixFilter());

  useEffect(() => {
    const container = containerRef.current;
    let elapsed = 0;

    container.filters = [brightnessFilter.current];

    const animate = (delta) => {
      if (elapsed < duration) {
        elapsed += delta;
        const progress = elapsed / duration;
        const newScale = 1 + (maxScale - 1) * progress;
        const newBrightness = 1 + (0.5 * progress); // Adjust brightness from 1 to 1.5

        container.scale.set(newScale);
        brightnessFilter.current.brightness(newBrightness, false);
      }
    };

    app.ticker.add(animate);

    return () => {
      app.ticker.remove(animate);
    };
  }, [app]);

  return (
    <Container ref={containerRef} x={width / 2} y={height / 3} pivot={{ x: 0.5, y: 0.5 }}>
      {children}
      <Confetti />
    </Container>
  );
};

export default JoyAnimation;
