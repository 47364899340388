import React from 'react';
import { Container, Heading, Text, VStack } from '@chakra-ui/react';

const PrivacyPolicyPage = () => {
  return (
    <Container maxW="container.md" mt={8}>
      <VStack align="start" spacing={6}>
        <Heading as="h1" size="xl">
          Politique de Confidentialité
        </Heading>

        <Text>
          Cette Politique de Confidentialité décrit comment Cozy Surviving
          collecte, utilise et protège vos informations personnelles lorsque
          vous utilisez notre jeu.
        </Text>

        <Heading as="h2" size="lg">
          1. Collecte d'Informations
        </Heading>

        <Text>
          Nous pouvons collecter des informations personnelles telles que votre
          nom, votre adresse e-mail et votre avatar lorsque vous choisissez de
          vous authentifier via Firebase. Lorsque vous jouez, vos statistiques
          de jeux sont également récupérés pour les afficher dans votre page
          profil (nombre de parties jouées, gagnées, poissons collectés etc ...)
          .Si vous ne vous authentifiez pas, aucune information personnelle ou
          autre n'est collectée.
        </Text>

        <Heading as="h2" size="lg">
          2. Utilisation des Informations
        </Heading>

        <Text>
          Les informations personnelles que nous collectons peuvent être
          utilisées pour personnaliser votre expérience de jeu, traiter vos
          transactions et améliorer notre service. Ces informations ne seront
          pas partagées avec des tiers sans votre consentement.
        </Text>

        <Heading as="h2" size="lg">
          3. Sécurité
        </Heading>

        <Text>
          Nous mettons en place des mesures de sécurité pour protéger vos
          informations personnelles. Cependant, aucun système n'est totalement
          sûr, et nous ne pouvons garantir la sécurité absolue de vos données.
        </Text>

        <Heading as="h2" size="lg">
          4. Cookies
        </Heading>

        <Text>
          Cozy Surviving n'utilise pas de cookies, sauf ceux éventuellement
          définis par Firebase lors de l'authentification. Consultez la
          politique de cookies de Firebase pour plus d'informations.
        </Text>

        <Heading as="h2" size="lg">
          5. Modifications de la Politique
        </Heading>

        <Text>
          Nous nous réservons le droit de mettre à jour cette politique de
          confidentialité à tout moment. Les changements seront publiés sur
          cette page, et il vous incombe de les consulter régulièrement.
        </Text>

        <Text fontWeight="bold">Dernière mise à jour : 2024</Text>
      </VStack>
    </Container>
  );
};

export default PrivacyPolicyPage;
