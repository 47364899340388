import { Select } from '@chakra-ui/react';
import Icon from 'components/Icon';
import userTitles from 'constants/userTitles';
import { useDispatch, useSelector } from 'react-redux';
import { updateUser } from 'myFirebase/user';
import { updateUserState } from 'redux/features/userSlice';

const ChangeUserTitle = () => {
  const { user } = useSelector(state => state.user);
  const dispatch = useDispatch();

  const handleNewTitle = e => {
    console.log('new title', e.target.value);
    if (!e.target.value || !user?.ref) return;
    const updateObj = { currentTitle: e.target.value };
    updateUser(user.ref, updateObj);
    dispatch(updateUserState(updateObj));
  };
  return (
    <Select
      onChange={handleNewTitle}
      value={user?.currentTitle}
      my="2"
      bg="primary"
      border="none"
      color="black"
      fontWeight="bold"
      cursor="pointer"
      placeholder="Changer de titre"
      maxW="50vw"
      w="250px"
      icon={
        <Icon
          color="gold"
          icon={userTitles?.[user?.currentTitle]?.icon || 'ic:outline-badge'}
        />
      }
    >
      {user?.titles?.map(title => (
        <option value={title} key={title}>
          {userTitles[title]?.name}
        </option>
      ))}
    </Select>
  );
};

export default ChangeUserTitle;
