import React, { useState, useEffect, useCallback } from 'react';
import { ChakraProvider, extendTheme, Box } from '@chakra-ui/react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
  Navigate,
} from 'react-router-dom';
import socketIOClient from 'socket.io-client';
import Home from './pages/Home';
import LoginPage from './pages/LoginPage';
import SignUpPage from './pages/SignUpPage';
import ResetPassword from './pages/ResetPassword';
import Header from './components/Header/Header';
import sandBg from './svg/sand-bg.svg';
import Preinscription from './pages/Preinscription';
import SocketContext from './context/socket-context';
import Lobby from './pages/Lobby';

// redux
import { useSelector, useDispatch } from 'react-redux';
import {
  updateFullGameState,
  updateGameState,
  updateMyId,
} from './redux/features/gameSlice';
import Start from './pages/Start';
import ReceivingFishesModalContainer from './components/Modal/ReceivingModal';
import TimerPopUpContainer from './components/TimerPopUp/TimerPopUp';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, db } from './myFirebase';
import { setUser } from './redux/features/userSlice';
import AboutPage from './pages/AboutPage';
import ConditionsPage from './pages/ConditionsPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import UpgradePage from './pages/UpgradePage';
import ReceivingBraceletModalContainer from './components/Modal/ReceivingBraceletModalContainer';
import Profil from 'pages/Profil';

// PAYPAL
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { LOCATIONS } from 'constants';
import Leaderboard from 'pages/Leaderboard';


export const theme = extendTheme({
  colors: {
    primary: '#76CBE5',
    vegetation: '#4A8F79',
    sand: '#BCA12E',
    sea: '#27A6D5',
    sky: '#04357F',
    gold: '#FFD700',

    redTeam: '#F24E1E',
    blueTeam: '#0075FF',
    blackTeam: '#000000',

    loser: '#796e86',

    progressBar: {
      100: '#76CBE5',
      200: '#76CBE5',
      300: '#76CBE5',
      400: '#76CBE5',
      500: '#76CBE5',
      600: '#76CBE5',
      700: '#76CBE5',
      800: '#76CBE5',
    },
    loadingBar: {
      100: '#4A8F79',
      200: '#4A8F79',
      300: '#4A8F79',
      400: '#4A8F79',
      500: '#4A8F79',
      600: '#4A8F79',
      700: '#4A8F79',
      800: '#4A8F79',
    },
  },
  fonts: {
    heading: '"Irish Grover", cursive',
    body: '"Kalam", cursive;',
  },
});

function App() {
  const [socket, setSocket] = useState(null);

  const gameState = useSelector(state => state.game);

  const paypalOptions = {
    "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
    currency: "EUR",
    intent: "capture",
  };

  useEffect(() => {
    const newSocket = socketIOClient(process.env.REACT_APP_SOCKET_ENDPOINT, {
      transports: ['websocket'], // Utiliser le transport websocket
      httpCompression: true, // Activer la compression HTTP
    });

    setSocket(newSocket);
    return () => socket?.disconnect();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // TODO: DELETE THIS USEEFFECT
    console.log('gameState', gameState);
    // console.log('stats', gameState?.me?.stats);
  }, [gameState]);

  return (
    <PayPalScriptProvider options={paypalOptions}>
      <SocketContext.Provider value={socket}>
        <ChakraProvider theme={theme}>
          <Router>
            <RouterContent socket={socket} />
          </Router>
        </ChakraProvider>
      </SocketContext.Provider>
    </PayPalScriptProvider>
  );
}

function RouterContent({ socket }) {
  const [user, loading] = useAuthState(auth);
  const { gameId, me } = useSelector(state => state.game);
  const [timer, setTimer] = useState(null);
  const [timeMax, setTimeMax] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const settingUser = useCallback(async () => {
    try {
      const q = query(collection(db, 'users'), where('uid', '==', user?.uid));
      const doc = await getDocs(q);
      const data = doc.docs[0].data();
      const tmpUser = { ...data, ref: doc.docs[0].id };
      dispatch(setUser(tmpUser));
    } catch (err) {
      console.error(err);
      alert('An error occured while fetching user data');
    }
  }, [user, dispatch]);
  useEffect(() => {
    if (loading) return;
    if (!user) return;
    settingUser();
  }, [user, loading, settingUser]);

  useEffect(() => {
    if (!socket) return;
    socket.on('timer', ({ time, timeMax }) => {
      setTimer(time);
      setTimeMax(timeMax);
    });
  }, [socket]);

  useEffect(() => {
    if (!socket) return;
    socket.on('gameState', data => {
      dispatch(updateGameState(JSON.parse(data)));
    });
    socket.on('fullGameState', data => {
      dispatch(updateFullGameState(JSON.parse(data)));
    });
    socket.on('myId', data => {
      dispatch(updateMyId(data));
    });

    socket.on('startGame', data => {
      navigate('/start');
    });
    socket.on('lobby', data => {
      dispatch(updateGameState({ isStart: false }));
      navigate('/lobby');
    });
  }, [socket, dispatch, navigate]);

  // reconnection
  useEffect(() => {
    if (!socket) return;
    const lastGameId = localStorage.getItem('lastGameId');
    const lastMyId = localStorage.getItem('lastMyId');
    if (lastGameId && lastMyId) {
      socket.emit('reconnect', lastGameId, lastMyId);
    }
    socket.on('reconnect', () => {
      navigate('/lobby');
    });
    // forced disconnection
    socket.on('forcedDisconnect', () => {
      localStorage.removeItem('lastGameId');
      localStorage.removeItem('lastMyId');
      navigate('/');
      window.location.reload();
    });
  }, [socket, navigate]);

  return (
    <Box
      minH="100vh"
    >
      <Box position="absolute" top="0" right="0" minH="100vh" w="100%" h="100%" zIndex="-1"
        filter={`brightness(${timer && me.location === LOCATIONS.CAMP ? timer/timeMax : 1})`}
        backgroundImage={`url(${sandBg})`}
        backgroundSize='cover'
        transition="all 0.8s ease-in-out"
         />
      <Header />

      <ReceivingFishesModalContainer />
      <ReceivingBraceletModalContainer />
      <TimerPopUpContainer />

      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/join/:gameId" element={<Home join />} />
        <Route exact path="/preinscription" element={<Preinscription />} />
        <Route exact path="/login" element={<LoginPage />} />
        <Route exact path="/signup" element={<SignUpPage />} />
        <Route exact path="/reset-password" element={<ResetPassword />} />
        <Route exact path="/upgrade" element={<UpgradePage />} />
        <Route exact path="/leaderboard" element={<Leaderboard />} />
        <Route exact path="/profil/:userRef" element={<Profil />} />

        <Route exact path="/about" element={<AboutPage />} />
        <Route exact path="/conditions" element={<ConditionsPage />} />
        <Route exact path="/privacy-policy" element={<PrivacyPolicyPage />} />

        <Route
          exact
          path="/lobby"
          element={socket ? <Lobby /> : <Navigate to="/" replace />}
        />
        <Route
          exact
          path="/start"
          element={gameId ? <Start /> : <Navigate to="/" replace />}
        />
      </Routes>
    </Box>
  );
}

export default App;
