import { useSound as useSoundLib } from 'use-sound';
import useOptions from './useOptions';
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

const useSound = (sound, options = {}) => {
  const { options: userOptions } = useSelector(state => state.user);
  const { getOption } = useOptions();
  const volume = useMemo(() => {
    return getOption('volume') ?? userOptions.volume ?? 50;
  }, [userOptions, getOption]);
  const [play, { stop, sound: howlsound }] = useSoundLib(sound, {
    volume: volume/100,
    ...options,
  });

  useEffect(() => {
    console.log('soudchange', getOption('volume'));
    if (userOptions.volume === undefined) return;
    if (!howlsound) return
    howlsound.volume(userOptions.volume / 100)
     // eslint-disable-next-line
  }, [userOptions.volume, getOption]);

  return [play, { stop, sound: howlsound }];
};

export default useSound;
