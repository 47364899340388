import { ANNOUNCE } from "constants";

import normal from "svg/animator/normal.svg"
import normalEyesLeft from "svg/animator/normal-eyes-left.svg"
import normalYesRight from "svg/animator/normal-eyes-right.svg"
import sneaky from "svg/animator/sneaky.svg"
import shocked from "svg/animator/shocked.svg"
import disappointed from "svg/animator/disappointed.svg"
import sad from "svg/animator/sad.svg"
import happy from "svg/animator/happy.svg"

export const faceMap = {
  [ANNOUNCE.FACE.NORMAL] : normal,
  [ANNOUNCE.FACE.NORMAL_EYES_LEFT] : normalEyesLeft,
  [ANNOUNCE.FACE.NORMAL_EYES_RIGHT] : normalYesRight,
  [ANNOUNCE.FACE.SNEAKY] : sneaky,
  [ANNOUNCE.FACE.SHOCKED] : shocked,
  [ANNOUNCE.FACE.DISAPPOINTED] : disappointed,
  [ANNOUNCE.FACE.SAD] : sad,
  [ANNOUNCE.FACE.HAPPY] : happy,
}