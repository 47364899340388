import React from 'react';
import { Container, Heading, Text, VStack } from '@chakra-ui/react';
import Link from '../components/Link';

const ConditionsPage = () => {
  return (
    <Container maxW="container.md" mt={8}>
      <VStack align="start" spacing={6}>
        <Heading as="h1" size="xl">
          Conditions Générales d'Utilisation
        </Heading>

        <Text>
          En utilisant Cozy Surviving, vous acceptez les conditions générales
          énoncées ci-dessous. Veuillez lire attentivement ces conditions avant
          de commencer à jouer.
        </Text>

        <Heading as="h2" size="lg">
          1. Acceptation des Conditions
        </Heading>

        <Text>
          En accédant à Cozy Surviving, vous déclarez avoir lu, compris et
          accepté les présentes conditions générales d'utilisation. Si vous
          n'acceptez pas ces conditions, veuillez ne pas utiliser notre jeu.
        </Text>

        <Heading as="h2" size="lg">
          2. Utilisation du Service
        </Heading>

        <Text>
          Cozy Surviving est destiné à un usage personnel et non commercial.
          Vous vous engagez à ne pas utiliser le service à des fins illégales ou
          interdites par ces conditions.
        </Text>

        <Heading as="h2" size="lg">
          3. Authentification
        </Heading>

        <Text>
          L'authentification via Firebase est facultative, mais elle peut offrir
          des fonctionnalités supplémentaires. En utilisant cette
          fonctionnalité, vous acceptez également les conditions d'utilisation
          de Firebase.
        </Text>

        <Heading as="h2" size="lg">
          4. Confidentialité
        </Heading>

        <Text>
          Nous respectons votre vie privée. Consultez notre{' '}
          <Link color="blue.500" to="/privacy-policy">
            politique de confidentialité
          </Link>{' '}
          pour en savoir plus sur la collecte et l'utilisation des données.
        </Text>

        <Heading as="h2" size="lg">
          5. Modifications des Conditions
        </Heading>

        <Text>
          Cozy Surviving se réserve le droit de modifier ces conditions
          générales à tout moment. Les utilisateurs seront informés de toute
          modification, et il leur incombe de consulter régulièrement ces
          conditions pour rester informés.
        </Text>

        <Text fontWeight="bold">Dernière mise à jour : 2024</Text>
      </VStack>
    </Container>
  );
};

export default ConditionsPage;
