import React, { useMemo, useState } from 'react';
import usePlayerItems from '../../hooks/usePlayerItems';
import { Grid, GridItem, Image, Text, useDisclosure } from '@chakra-ui/react';
import GiveOrEatModal from '../Modal/GiveOrEatModal';
import Fire from "components/Interface/Fire";
import { useSelector } from 'react-redux';
import { ITEMS, ITEM_NAME } from 'constants/items';
import AnimatedNumber from 'components/ui/AnimatedNumber';
import { motion } from 'framer-motion'; // Importer les composants nécessaires de Framer Motion


function Inventory({ playerId, itemsProps, noText = false, ...props }) {
  const { players } = useSelector(state => state.game);
  const { items } = usePlayerItems();
  const [currentObjectKey, setCurrentObjectKey] = useState(undefined);

  const displayItems = useMemo(() => playerId ? players?.find(p => p.id === playerId) : items, [players, playerId, items]);

  const { isOpen: isOpenGiveOrEat, onOpen: onOpenGiveOrEat, onClose: onCloseGiveOrEat } = useDisclosure();

  const handleOpenGiveOrEat = (key) => {
    setCurrentObjectKey(key);
    onOpenGiveOrEat();
  }

  return (
    <>
      {!!playerId && !noText && (
        <Text color="black" textAlign="center" fontWeight="bold" fontSize="xl">
          {displayItems?.name}
        </Text>
      )}
      <GiveOrEatModal
        isOpen={isOpenGiveOrEat}
        onClose={onCloseGiveOrEat}
        max={items?.[currentObjectKey]}
        itemKey={currentObjectKey}
      />
      <Grid
        templateColumns={{ base: "repeat(3, 1fr)", md: "repeat(5, 1fr)" }}
        templateRows="repeat(2, 1fr)"
        minH="100px"
        h="100%"
        w="100%"
        alignItems="center"
        // border="1px solid black"
        bg="gold"
        p="4"
        borderRadius="md"
        boxShadow="md"
      >
        {Object.keys(ITEMS).map(itemKey => (
          <Item
            onOpenGiveOrEat={handleOpenGiveOrEat}
            disabled={!!playerId}
            key={itemKey}
            {...ITEMS[itemKey]}
            value={displayItems[itemKey]}
          />
        ))}
      </Grid>
    </>
  );
}

export const Item = ({ disabled, name, image, canEat, canGive, value, onOpenGiveOrEat, onClick, ...props }) => {
  const handleClick = () => {
    if (onClick) {
      onClick()
      return
    }
    if (!value) return;
    if (canEat || canGive) {
      onOpenGiveOrEat(name);
      return;
    }
  }

  if (!value) return null;

  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.8 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0.8 }}
      transition={{ duration: 0.3 }}
    >
      <GridItem
        cursor={disabled || (!canEat && !canGive) ? "unset" : "pointer"}
        onClick={disabled ? null : handleClick}
        direction="column"
        align="center"
        w="90%"
        h="90%"
        minW="30px"
        minH="fit-content"
        border="2px solid black"
        m="1"
        p="2"
        bg="primary"
        borderRadius="md"
        boxShadow="sm"
        _hover={{ boxShadow: "lg" }}
        transition="all 0.2s"
        {...props}
      >
        <ItemDisplay disabled={disabled} value={value} image={image} name={name} />
      </GridItem></motion.div>
  );
}

const ItemDisplay = ({ value, image, name, disabled }) => {
  switch (name) {
    case ITEM_NAME.FIRE:
      return (
        <Fire
          canUse={!disabled}
          value={value}
          style={{ maxHeight: '30px', width: 'auto' }}
          fontSize="sm"
        />
      );
    default:
      return (
        <>
          <Image h="fit-content" maxH="30px" w="auto" src={image} alt={name} />
          <AnimatedNumber value={value} color="sky" fontWeight="extrabold" fontSize="sm" />
        </>
      );
  }
}

export default Inventory;
