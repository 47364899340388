import userTitles from 'constants/userTitles';
import { updateUser } from 'myFirebase/user';
import FirebaseUser from 'types/FirebaseUser';

const computeUserTitles = (
  user: FirebaseUser,
  onUpdate?: (o: object) => void
) => {
  const newTitles: string[] = [];

  Object.entries(userTitles).forEach(([key]) => {
    console.log(
      'on cherche si il y a des nouveaux titles',
      user.titles && user.titles.includes(key)
    );
    if (user.titles && user.titles.includes(key)) return; // user already has this title
    if (userTitles[key].condition(user)) {
      newTitles.push(key);
    }
  });

  if (!newTitles.length) {
    console.log('pas de nouveaux titres', user.stats);
    return;
  }
  console.log(
    'envoyer ces nouveaux titres',
    newTitles,
    "à l'utilisateur",
    user
  );
  updateUser(
    user.ref,
    { titles: user.titles ? [...user.titles, ...newTitles] : newTitles },
    (obj: object) => {
      onUpdate && onUpdate(newTitles);
    }
  );
};

export default computeUserTitles;
