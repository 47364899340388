import {
  Heading,
  Text,
  VStack,
  Container,
  Flex,
  Image,
  Box,
} from '@chakra-ui/react';
import Icon from '../components/Icon';
import Button from '../components/Button';
import Checkout from 'paypal/Checkout';

const UpgradePage = () => {

  return (
    <Container maxW="container.md" mt={8} textAlign="center" id="#top">
      <VStack alignItems="center" w="100%" align="start" spacing={6}>
      <Checkout/>
        <Flex alignItems="center">
          <Heading px="2" as="h1" size="2xl">
            Avantages premium
          </Heading>
          <Icon boxSize="14" icon="openmoji:goldfish" />
        </Flex>

        <Heading as="h2" size="lg">
          Jouer avec un avatar personalisé
        </Heading>
        <Image w="100%" src="/tutos/premium/premium-1.jpg" />
        <Text fontSize="xl">
          Tu auras la possibilité de jouer avec l'image de ton choix comme
          avatar, elle sera sauvegardée pour ta prochaine connexion
        </Text>

        <Heading as="h2" size="lg">
          Changer le nombre de joueurs initial
        </Heading>
        <Image w="100%" src="/tutos/premium/premium-2.jpg" />
        <Text fontSize="xl">
          Il te sera possible de choisir de faire des parties de 8 à 16 joueurs
        </Text>

        <Heading as="h2" size="lg">
          Changer le temps sur le camps et choisir ses épreuves
        </Heading>
        <Image w="100%" src="/tutos/premium/premium-4.jpg" />
        <Text fontSize="xl">
          Tu pourras choisir combien de temps dure la phase de camps et les épreuves que tu préféres pour ta partie
        </Text>

        <Heading as="h2" size="lg">
          Création d'équipe
        </Heading>
        <Image w="100%" src="/tutos/premium/premium-3.jpg" />
        <Text fontSize="xl">
          Tu pourras choisir précisément les joueurs de chaque équipe avant de
          lancer la partie
        </Text>
        <Heading as="h2" size="lg">
          Comment devenir membre premium ?
        </Heading>
        <Text fontSize="2xl">
         Le prix actuel pour devenir premium est de <Box as="span" fontWeight="bold">{process.env.REACT_APP_PREMIUM_PRICE}€ sans abonnement</Box>
        </Text>
        <Button onClick={() => window.scrollTo(0,0)} >Ça m'intéresse</Button>
      </VStack>
    </Container>
  );
};

export default UpgradePage;
