import React, { useRef, useEffect } from 'react';
import { Container, useApp } from '@pixi/react';
import usePixi from 'hooks/usePixi';
import Confetti from './Confetti'; // Assurez-vous que le chemin vers le composant Confetti est correct

const ProudAnimation = ({ children }) => {
  const { width, height } = usePixi();
  const containerRef = useRef();
  const app = useApp();

  useEffect(() => {
    const container = containerRef.current;
    let direction = 1;
    const rotationSpeed = 0.005;
    let rotation = 0;

    const animate = (delta) => {
      rotation += rotationSpeed * direction * delta;
      if (rotation > 0.1 || rotation < -0.1) {
        direction *= -1;
      }
      container.rotation = rotation;
    };

    app.ticker.add(animate);

    return () => {
      app.ticker.remove(animate);
    };
  }, [app]);

  return (
    <Container ref={containerRef} x={width / 2} y={height / 3}>
      {children}
      <Confetti />
    </Container>
  );
};

export default ProudAnimation;