import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useSocket from '../../hooks/useSocket';
import { Flex, Image, VStack } from '@chakra-ui/react';

import { MEMORY_OBJECTS } from '../../constants';
import MemoryButton from './MemoryButton';

import BraceletSvg from '../../svg/Bracelet.svg';
import FishSvg from '../../svg/Fish.svg';
import PalmSvg from '../../svg/Palm.svg';
import VotePng from '../../png/vote.png';

import BraceletSound from '../../sounds/discover.mp3';
import FishSound from '../../sounds/bubbles.mp3';
import PalmSound from '../../sounds/up.mp3';
import VoteSound from '../../sounds/vote.mp3';
import MemoryElem from './MemoryElem';
import Feedback from '../Feedback';
import { PlayersInLocation } from '../../components/Camp';
import useRerender from '../../hooks/useRerender';
import GameHelper from '../../components/GameHelper/GameHelper';

const ELEMENTS = {
  [MEMORY_OBJECTS.BRACELET]: { image: BraceletSvg, sound: BraceletSound },
  [MEMORY_OBJECTS.FISH]: { image: FishSvg, sound: FishSound },
  [MEMORY_OBJECTS.PALM]: { image: PalmSvg, sound: PalmSound },
  [MEMORY_OBJECTS.VOTE]: { image: VotePng, sound: VoteSound },
};

const SUITE_TIME = 7000; // 7 secs

function Memory() {
  const { gameId, myId, me, randomElemArray, players } = useSelector(
    state => state.game
  );
  const socket = useSocket();

  // to triger the error / success animation
  const [rerender, rerenderKey] = useRerender();

  const [currentElem, setCurrentElem] = useState({ index: 0, elem: null });
  const [currentTry, setCurrentTry] = useState([]);

  const [waiting, setWaiting] = useState(true);
  const [isError, setIsError] = useState(null);

  useEffect(() => {
    let current = 0;
    setWaiting(true);
    if (randomElemArray.length === 0) return;
    const interval = setInterval(() => {
      if (current >= randomElemArray.length) {
        setWaiting(false);
        setCurrentElem({ index: 0, elem: null });
        clearInterval(interval);
        return;
      }
      setCurrentElem({ index: current, elem: randomElemArray[current] });
      current++;
    }, SUITE_TIME / randomElemArray.length);

    return () => {
      setWaiting(false);
      setCurrentElem({ index: 0, elem: null });
      clearInterval(interval);
    };
  }, [randomElemArray]);

  const handleClick = type => {
    console.log('click on', type);
    const tmpTry = [...currentTry, type];
    console.log('currenttry = ', tmpTry);

    for (let i = 0; i < tmpTry.length; i++) {
      if (tmpTry[i] !== randomElemArray[i]) {
        // there is a mistake
        handleMistake();
        return;
      }
    }
    // everything is good
    if (tmpTry.length === randomElemArray.length) {
      handleSuccess();
      return;
    }
    // suite not completed yet
    setCurrentTry(tmpTry);
  };

  const handleMistake = () => {
    setCurrentTry([]);
    setIsError(true);
    rerender();
    socket.emit('memory-mistake', { gameId, myId });
  };

  const handleSuccess = () => {
    setCurrentTry([]);
    setWaiting(true);
    setIsError(false);
    rerender();
    socket.emit('memory-success', { gameId, myId });
  };

  return (
    <VStack h="100%">
      {currentElem.elem ? (
        <MemoryElem
          imageSrc={ELEMENTS[currentElem.elem].image}
          soundSrc={ELEMENTS[currentElem.elem].sound}
          type={currentElem.elem}
          key={`${currentElem.index}-${currentElem.elem}`}
        />
      ) : (
        <Flex w="100%" h="60%">
          {currentTry.map((tryElem, index) => (
            <Image
              alignSelf="center"
              p="2"
              h="fit-content"
              maxW="10%"
              key={tryElem + index}
              src={ELEMENTS[tryElem].image}
            />
          ))}
        </Flex>
      )}
      <GameHelper
        canDisplay={!waiting && me.canPlay & me.canMove && me.alive}
        text="Cliquez sur les éléments dans le bon ordre"
      >
        <Flex
          p="5"
          justify="center"
          align="center"
          flexWrap="wrap"
          h="fit-content"
          w="100%"
        >
          {Object.keys(ELEMENTS).map(key => (
            <MemoryButton
              key={key}
              type={key}
              soundSrc={ELEMENTS[key].sound}
              imageSrc={ELEMENTS[key].image}
              onClick={handleClick}
              disabled={waiting || !me.canPlay || !me.canMove || !me.alive}
            />
          ))}
        </Flex>
      </GameHelper>
      <Flex mt="4" justify="center">
        <PlayersInLocation
          players={players.filter(p => p.canMove && p.canPlay && p.alive)}
        />
      </Flex>
      <Feedback key={rerenderKey} isError={isError} />
    </VStack>
  );
}

export default Memory;
