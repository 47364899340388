import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useSocket from '../../hooks/useSocket';
import { Flex, VStack } from '@chakra-ui/react';

import Feedback from '../Feedback';
import { PlayersInLocation } from '../../components/Camp';
import useRerender from '../../hooks/useRerender';
import GameHelper from '../../components/GameHelper/GameHelper';
import Avatar from 'components/Avatar';

const variantTypeArray = ['eyes', 'mouth'];

function OddOneOut() {
  const { gameId, myId, me, randomNumber, players, round } = useSelector(
    state => state.game
  );
  const socket = useSocket();

  // to triger the error / success animation
  const [rerender, rerenderKey] = useRerender();

  const [waiting, setWaiting] = useState(false);
  const [isError, setIsError] = useState(null);

  const [avatars, setAvatars] = useState([]);

  const handleClick = useCallback(
    number => {
      const handleMistake = () => {
        setIsError(true);
        rerender();
        socket.emit('odd-one-out-mistake', { gameId, myId });
      };

      const handleSuccess = () => {
        setWaiting(true);
        setIsError(false);
        rerender();
        socket.emit('odd-one-out-success', { gameId, myId });
      };
      console.log('click on', number);
      if (number !== randomNumber) {
        // there is a mistake
        handleMistake();
        return;
      }
      // everything is good
      if (number === randomNumber) {
        handleSuccess();
        return;
      }
    },
    [randomNumber, myId, gameId, rerender, socket]
  );

  useEffect(() => {
    setWaiting(false);
    const variantType =
      variantTypeArray[randomNumber % variantTypeArray.length];
    const variantN = 1 + (randomNumber % 7);
    const name = `${randomNumber}`;
    let tmpAvatars = [];
    for (let i = 0; i < round; i++) {
      console.log('variant', `${variantType}=0${variantN}`);
      tmpAvatars.push(
        <Avatar
          name={name}
          cursor="pointer"
          _hover={{ opacity: 0.8 }}
          onClick={() => handleClick(i)}
          variant={`${variantType}=variant0${i === randomNumber ? variantN : variantN + 1}`}
          boxSize={{ base: '50px', md: '100px' }}
        />
      );
    }
    setAvatars(tmpAvatars);
  }, [randomNumber, handleClick, round]);

  return (
    <VStack h="100%">
      <GameHelper
        canDisplay={me.canPlay & me.canMove && me.alive}
        text="Selectionne la personne qui est différente des autres"
      >
        <Flex
          pointerEvents={
            !waiting && me.canMove && me.canPlay && me.alive ? 'auto' : 'none'
          }
          p="5"
          justify="center"
          align="center"
          flexWrap="wrap"
          h="fit-content"
          w="80vw"
        >
          {avatars}
        </Flex>
      </GameHelper>
      <Flex mt="4" justify="center">
        <PlayersInLocation
          players={players.filter(p => p.canMove && p.canPlay && p.alive)}
        />
      </Flex>
      <Feedback key={rerenderKey} isError={isError} />
    </VStack>
  );
}

export default OddOneOut;
