import React, { useEffect, useState } from 'react';
import { Sprite, Text } from '@pixi/react';
import { TextStyle } from 'pixi.js';
import userTitles from 'constants/userTitles';

function PlayerPixi({
  coords,
  name,
  id,
  facingRight = false,
  isMe = false,
  opacity = 1,
  avatar,
  size = 100,
  blind = false,
  usernameColor = '#0000006F',
  title,
  showName= true,
  ...props
}) {
  const { x, y, r } = coords;
  const [blob, setBlob] = useState(null);

  useEffect(() => {
    if (!avatar) return;
    // Charger l'image en tant que blob
    const loadTexture = async () => {
      const response = await fetch(avatar);
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      console.log('url', url);
      setBlob(url);
    };
    loadTexture();
  }, [avatar]);

  return (
    <>
      <Sprite
        id="avatar"
        image={
          blob
            ? blob
            : `https://api.dicebear.com/7.x/adventurer/svg?size=150&seed=${name?.replaceAll(
                '?',
                ''
              )}${blind ? '&eyes=variant19,variant20' : ''}`
        }
        x={x}
        y={y}
        rotation={r ? r * (Math.PI / 180) : 0}
        anchor={{ x: 0.5, y: 0.5 }}
        scale={{ x: facingRight ? -1 : 1, y: 1 }}
        width={size}
        height={size}
        alpha={opacity}
      />
      <Text
        anchor={0.5}
        x={x}
        y={y - (title ? size : size / 2)}
        text={showName ? name : ""}
        opacity={0.8}
        style={
          new TextStyle({
            align: 'center',
            opacity: 0.8,
            fill: isMe ? '#4A8F79' : usernameColor,
            fontWeight: 'bold',
            fontSize: isMe ? size / 6 : size / 7,
          })
        }
      />
      {title && (
        <Text
          anchor={0.5}
          x={x}
          y={y - size / 2}
          text={userTitles[title].name}
          opacity={0.8}
          style={
            new TextStyle({
              align: 'center',
              opacity: 0.8,
              fill: 'black',
              fontWeight: 'normal',
              fontSize: isMe ? size / 7 : size / 8,
            })
          }
        />
      )}
    </>
  );
}

export default PlayerPixi;
