import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useApp } from '@pixi/react';
import rockImage from 'components/Camp/forestAssets/rock.svg';
import handImage from 'svg/glove.svg';
import ElementPixi from 'components/PixiCanvas/ElementPixi';

const RockAndHand = ({ speed, onCatch, onMiss, canPlay }) => {
  const app = useApp();
  const [rockPos, setRockPos] = useState({ x: Math.random() * window.innerWidth / 2, y: 0 });
  const [handPos, setHandPos] = useState({ x: window.innerWidth / 2, y: window.innerHeight / 2 - 100 });
  const [targetPos, setTargetPos] = useState({ x: window.innerWidth / 2, y: window.innerHeight / 2 - 100 });
  const canvasRef = useRef();
  const smoothingFactor = 0.1;

  const updateRockPosition = useCallback(() => {
    setRockPos(pos => {
      if (pos.y <= handPos.y + 25 && pos.y >= handPos.y - 25 && pos.x >= handPos.x - 25 && pos.x <= handPos.x + 25) {
        onCatch();
        return { x: Math.random() * window.innerWidth / 2, y: 0 };
      }
      if (pos.y >= window.innerHeight / 2) {
        onMiss()
        return { x: Math.random() * window.innerWidth / 2, y: 0 };
      }
      return { ...pos, y: pos.y + speed };
    });
  }, [handPos, onCatch, speed, onMiss]);

  const updateHandPosition = useCallback(() => {
    setHandPos(pos => ({
      x: pos.x + (targetPos.x - pos.x) * smoothingFactor,
      y: pos.y
    }));
  }, [targetPos, smoothingFactor]);

  const updatePositions = useCallback(() => {
    updateRockPosition();
    updateHandPosition();
  }, [updateRockPosition, updateHandPosition]);

  useEffect(() => {
    let animationFrameId;
    const animate = () => {
      updatePositions();
      animationFrameId = requestAnimationFrame(animate);
    };
    animationFrameId = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(animationFrameId);
  }, [updatePositions]);

  const handleMouseMove = useCallback((e) => {
    if (!canvasRef.current) return;
    const rect = canvasRef.current.getBoundingClientRect();
    const x = e.clientX - rect.left;
    setTargetPos({ x, y: handPos.y });
  }, [handPos.y]);

  const handleTouchMove = useCallback((e) => {
    if (!canvasRef.current) return;
    const rect = canvasRef.current.getBoundingClientRect();
    const x = e.touches[0].clientX - rect.left;
    setTargetPos({ x, y: handPos.y });
  }, [handPos.y]);

  useEffect(() => {
    if (!app.view || !canPlay) return;
    canvasRef.current = app.view;
    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('touchmove', handleTouchMove);
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('touchmove', handleTouchMove);
    };
  }, [app.view, handleMouseMove, handleTouchMove, canPlay]);

  return (
    <>
      <ElementPixi size={50} image={rockImage} x={rockPos.x} y={rockPos.y} />
      <ElementPixi size={50} image={handImage} x={handPos.x} y={handPos.y} />
    </>
  );
};

export default RockAndHand;
