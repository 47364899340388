import React, { useEffect, useState } from 'react';
import useSocket from '../../hooks/useSocket';
import { useSelector } from 'react-redux';
import Player from '../../components/Player/Player';
import { Flex, useDisclosure } from '@chakra-ui/react';
import FinalSpeechWriting from '../../components/Modal/FinalSpeechWriting';
import FinalSpeechReading from '../../components/Modal/FinalSpeechReading';
import SelectPlayerModal from '../../components/Modal/SelectPlayerModal';
import VotePopUp from '../../components/VotePopUp/VotePopUp';
import WinnerCelebration from './WinnerCelebration';
import { LOCATIONS } from '../../constants';
import Tuto from '../Tuto';
import StatsModal from '../../components/Modal/StatsModal';
import Button from 'components/Button';

function Final() {
  const socket = useSocket();
  const { gameId, myId, me, players, teams } = useSelector(state => state.game);

  const { isOpen: isOpenStats, onClose: onCloseStats } = useDisclosure({
    defaultIsOpen: true,
  });
  const {
    isOpen: isOpenWriting,
    onOpen: onOpenWriting,
    onClose: onCloseWriting,
  } = useDisclosure();
  const {
    isOpen: isOpenReading,
    onOpen: onOpenReading,
    onClose: onCloseReading,
  } = useDisclosure();
  const {
    isOpen: isOpenVote,
    onOpen: onOpenVote,
    onClose: onCloseVote,
  } = useDisclosure();

  const [alreadySpeeched, setAlreadySpeeched] = useState(false);

  const [winner, setWinner] = useState();
  const [displayTuto, setDisplayTuto] = useState(true);

  const [alreadyVoted, setAlreadyVoted] = useState(false)

  useEffect(() => {
    if (
      (teams.finalist.includes(myId) || teams.finalist.includes(me?.firstId || "0") )&&
      teams.finalist.length === 2 &&
      !isOpenStats &&
      !alreadySpeeched
    ) {
      // I'm part of the finalist and we are both here so I have to write a speech
      onOpenWriting();
    }
  }, [teams, myId, onOpenWriting, isOpenStats, alreadySpeeched, me?.firstId]);

  useEffect(() => {
    socket.on('game-winner', winnerId => {
      const winner = players.find(p => p.id === winnerId || p.firstId === winnerId);
      console.log('winner', winner);
      setWinner(winner);
    });
  });

  const handleCloseReading = () => {
    onCloseReading();
    if (teams.finalist.includes(myId) || teams.finalist.includes(me.firstId)) {
      // I'm part of the finalist so I don't vote
      return;
    }
    // starting votes
    if (!alreadyVoted) {
      onOpenVote();
    }
  };

  const handleVote = ({ to, message }) => {
    socket.emit('sendVote', {
      to,
      message,
      gameId,
      isFinal: true,
    });
    onCloseVote();
    setAlreadyVoted(true)
  };

  if (displayTuto)
    return <Tuto id={LOCATIONS.FINAL} setDisplay={setDisplayTuto} />;
  if (winner) return <WinnerCelebration player={winner} />;

  return (
    <Flex
      direction="column"
      h="100%"
      justify="space-around"
      w="100%"
      my="5"
      alignItems="center"
    >
      <Flex my="5">
        {players
          .filter(p => teams?.finalist.includes(p.id) || teams?.finalist.includes(p.firstId))
          .map((player, index) => (
            <Player
              key={player.id}
              name={player.name}
              id={player.id}
              position="unset"
              avatar={player?.avatar}
              title={player?.title}
            />
          ))}
      </Flex>
      <Flex wrap="wrap" my="5">
        {players
          .filter(p => !teams?.finalist.includes(p.id) && !teams?.finalist.includes(p.firstId))
          .map((player, index) => (
            <Player
              key={player.id}
              name={player.name}
              id={player.id}
              position="unset"
              avatar={player?.avatar}
              title={player?.title}
            />
          ))}
      </Flex>
      {displayTuto}
      <StatsModal isOpen={isOpenStats} onClose={onCloseStats} />
      <FinalSpeechWriting
        isOpen={isOpenWriting}
        onClose={() => {
          setAlreadySpeeched(true);
          onCloseWriting();
        }}
      />
      <FinalSpeechReading
        onOpen={onOpenReading}
        isOpen={isOpenReading}
        onClose={handleCloseReading}
      />
      <SelectPlayerModal
        isOpen={isOpenVote && !isOpenStats && !me?.spectator}
        onValidate={handleVote}
        displayCondition={player => teams?.finalist.includes(player.id) ||teams?.finalist.includes(player.firstId)}
        forcePlayers={players}
      />
      <VotePopUp />
      {(!teams.finalist.includes(myId) && !teams.finalist.includes(me.firstId)) && !alreadyVoted &&
       <Button bg="red" color="white" fontSize="md" onClick={onOpenVote}>Si vous ne pouvez pas voter cliquez ici</Button>
       }
    </Flex>
  );
}

export default Final;
