import { Box, Grid, HStack, Image, Text, VStack } from '@chakra-ui/react'
import React, { useMemo, useState } from 'react'
import Inventory, { Item } from '../Inventory'
import { ITEMS, ITEM_TYPE, RECIPES } from 'constants/items'
import usePlayerItems from 'hooks/usePlayerItems'
import Button from 'components/Button'
import useSound from 'hooks/useSound'
import craftSound from "sounds/craft.mp3"
import useLocalStorage from 'hooks/useLocalStorage'
import Tuto from 'games/Tuto'
import { LOCATIONS } from 'constants'
import { useSelector } from 'react-redux'

const Crafting = () => {
  const [currentCraft, setCurrentCraft] = useState(undefined)
  const { isDemo } = useSelector(state => state.game);
  const [displayTuto, setDisplayTuto] = useLocalStorage('craft-tuto', true);
  if (isDemo && displayTuto)
    return (
      <Tuto
        canSkip
        timeout={4000}
        id={LOCATIONS.ACTIVITY.INVENTORY}
        setDisplay={setDisplayTuto}
      />
    );
  return (
    <VStack>
      <HStack w="100%" justify="space-around">
        <VStack w="40%">
          <Text>
            En stock
          </Text>
          <Inventory />
        </VStack>
        <VStack w="40%">
          <Text>
            A fabriquer
          </Text>
          <Grid
            templateColumns={{ base: "repeat(3, 1fr)", md: "repeat(5, 1fr)" }}
            templateRows="repeat(2, 1fr)"
            minH="100px"
            h="100%"
            w="100%"
            alignItems="center"
            bg="primary"
            p="4"
            borderRadius="md"
            boxShadow="md"
          >
            {Object.keys(RECIPES).map(itemKey => (
              <Item
                key={itemKey}
                {...ITEMS[itemKey]}
                value={1}
                bg="gold"
                cursor="pointer"
                onClick={() => setCurrentCraft(itemKey)}
              />
            ))}
          </Grid>
        </VStack>
      </HStack>
      <CraftingColumn currentCraft={currentCraft} />
    </VStack>
  )
}

const CraftingColumn = ({ currentCraft }) => {

  const { items, updateCraftItems, updateCollectibles, updateItems } = usePlayerItems();
  const currentCraftItem = useMemo(() => ITEMS?.[currentCraft], [currentCraft])
  const [play, { stop }] = useSound(craftSound);

  const handleCraft = () => {
    if (!currentCraft) return
    stop()
    play()
    updateCraftItems({ [currentCraft]: items?.[currentCraft] ? items[currentCraft] + 1 : 1 })
    for (const ingredient of RECIPES[currentCraft]) {
      if (ingredient.item.type === ITEM_TYPE.COLLECTIBLES) {
        updateCollectibles({ [ingredient.item.name]: items?.[ingredient.item.name] ? items[ingredient.item.name] - ingredient.quantity : 0 })
        continue
      }
      if (ingredient.item.type === ITEM_TYPE.CRAFT_ITEMS) {
        updateCraftItems({ [ingredient.item.name]: items?.[ingredient.item.name] ? items[ingredient.item.name] - ingredient.quantity : 0 })
        continue
      }
      updateItems({ [ingredient.item.name]: items?.[ingredient.item.name] ? items[ingredient.item.name] - ingredient.quantity : 0 })
    }
  }


  return <VStack w="30%" h="fit-content" justify="center" >
    <Box
      direction="column"
      align="center"
      w="100px"
      h="100px"
      maxH="15vh"
      maxW="100%"
      border="2px solid black"
      m="1"
      p="2"
      bg="gold"
      borderRadius="md"
      boxShadow="sm"
      _hover={{ boxShadow: "lg" }}
      transition="all 0.2s"
    >
      {currentCraft ?
        <Image h="fit-content" maxH="100%" w="auto" src={currentCraftItem.image} alt={currentCraftItem.name} />
        : null}
    </Box>

    {currentCraft ? <HStack>{RECIPES[currentCraft].map(ingredient => (
      <Item disabled value={ingredient.quantity} key={ingredient.item.name} {...ingredient.item} opacity={items?.[ingredient.item.name] >= ingredient.quantity ? "1" : "0.4"} />
    ))}</HStack> : null}

    <Button onClick={handleCraft} disabled={!RECIPES?.[currentCraft] || RECIPES?.[currentCraft].some(ingredient => items?.[ingredient.item.name] < ingredient.quantity)}>Fabriquer</Button>

  </VStack>

}

export default Crafting