import { UserStats } from 'types/FirebaseUser';
import Palm from 'svg/Palm.svg';
import Fish from 'svg/Fish.svg';
import Heart from 'svg/heart.svg';
import CampFire from 'svg/ActivityCampfire.svg';
import Hint from 'svg/hint.svg';
import FishCan from 'components/Camp/fishing-cursor.png';
import Vote from 'png/vote.png';
import BlackBall from 'svg/black-ball.svg';
import Trees from 'components/Camp/forestAssets/trees.svg';
import Bracelet from 'svg/Bracelet.svg';
import Coin from 'svg/coin.svg';

type Stat = {
  stat: string;
  bg?: string;
  color?: string;
  imageSrc?: any;
};

type Stats = {
  [key in keyof UserStats]: Stat;
};

const userStats: Stats = {
  total_final_won: {
    stat: 'parties remportées',
    bg: 'gold',
    // imageSrc: Flag,
  },
  total_games: {
    stat: 'parties jouées',
    bg: 'white',
    // imageSrc: Coin,
  },
  total_fishes: {
    stat: 'poissons obtenus au total',
    bg: 'sea',
    imageSrc: FishCan,
  },
  total_fishes_sent: {
    stat: 'objets envoyés aux coéquipiers',
    bg: 'sea',
    imageSrc: Fish,
  },
  total_game_won: {
    stat: 'épreuves remportées',
    bg: 'gold',
    imageSrc: Palm,
  },
  total_life_restored: {
    stat: 'points de vie restaurés',
    bg: 'primary',
    imageSrc: Heart,
  },
  total_bracelets: {
    stat: 'bracelets obtenus',
    bg: 'gold',
    imageSrc: Bracelet,
  },
  total_bracelets_used: {
    stat: 'bracelets utilisés au conseil',
    bg: 'red',
    imageSrc: Bracelet,
  },
  total_bracelets_sent: {
    stat: 'bracelets envoyés',
    bg: 'gray',
    color: 'white',
    imageSrc: Bracelet,
  },
  total_fire_sent: {
    stat: 'clics sur le feu de camps',
    bg: 'black',
    color: 'white',
    imageSrc: CampFire,
  },
  total_messages_sent: {
    stat: 'messages envoyés',
    bg: 'sky',
    color: 'white',
    imageSrc: Hint,
  },
  total_forest_search: {
    stat: 'recherches dans la forêt',
    bg: 'vegetation',
    imageSrc: Trees,
  },
  total_vote_received: {
    stat: 'votes reçus au conseil',
    bg: 'black',
    color: 'white',
    imageSrc: Vote,
  },
  total_tie_break_lose: {
    stat: 'boules noires piochées',
    bg: 'white',
    imageSrc: BlackBall,
  },
  total_coins: {
    stat: 'jetons au total',
    bg: 'gold',
    imageSrc: Coin,
  },
};

export default userStats;
