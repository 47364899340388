import React, { useState } from 'react';
import Modal from './Modal';
import { ModalHeader, Flex, Grid, GridItem } from '@chakra-ui/react';
import Button from '../Button/Button';
import Player from '../Player/Player';

import { useSelector } from 'react-redux';
import { TEAMS } from '../../constants';
import useSocket from '../../hooks/useSocket';

function CreateTeamsModal({ isOpen, onClose = () => {} }) {
  const socket = useSocket();

  const [selectedPlayerIds, setSelectedPlayerIds] = useState([]);
  const { players, gameId } = useSelector(state => state.game);

  const handleValidate = () => {
    onClose();
    socket.emit('createTeams', { gameId, redTeamIds: selectedPlayerIds });
  };

  return (
    <Modal
      onClose={() => {
        onClose();
      }}
      isOpen={isOpen}
      bg={`blueTeam`}
      size="lg"
    >
      <ModalHeader fontSize="2xl" align="center" color="white">
        Sélectionne les joueurs de l'équipe {TEAMS.RED}
      </ModalHeader>
      <Grid
        templateColumns={`repeat(5, 20%)`}
        w="100%"
        alignItems="center"
        justifyContent="center"
        p="5"
      >
        {players.map(player => (
          <SelectPlayers
            key={player?.id}
            player={player}
            setSelectedPlayerIds={setSelectedPlayerIds}
            selectedPlayerIds={selectedPlayerIds}
            max={players.length / 2}
          />
        ))}
      </Grid>
      <Flex direction="column" align="center">
        <Button
          disabled={selectedPlayerIds.length < players.length / 2}
          onClick={handleValidate}
        >
          Valider
        </Button>
      </Flex>
    </Modal>
  );
}

function SelectPlayers({
  player,
  setSelectedPlayerIds,
  selectedPlayerIds,
  max = 5,
}) {
  const isSelected = selectedPlayerIds.includes(player?.id);

  const handleClick = () => {
    if (isSelected) {
      // Si le joueur est déjà sélectionné, le retire de la liste
      setSelectedPlayerIds(prevSelectedIds =>
        prevSelectedIds.filter(id => id !== player?.id)
      );
    } else if (selectedPlayerIds.length < max) {
      // Si le joueur n'est pas déjà sélectionné et le nombre maximal de joueurs n'est pas atteint, l'ajoute à la liste
      setSelectedPlayerIds(prevSelectedIds => [...prevSelectedIds, player?.id]);
    }
  };

  return (
    <GridItem
      _hover={{ bg: isSelected ? 'redTeam' : 'gold' }}
      bg={isSelected ? 'redTeam' : ''}
      cursor="pointer"
      colSpan={1}
      onClick={handleClick}
      m="1"
    >
      <Player
        h="auto"
        w="auto"
        position="initial"
        name={player?.name}
        avatar={player?.avatar}
        title={player?.title}
      />
    </GridItem>
  );
}

export default CreateTeamsModal;
