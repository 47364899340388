import React, { useEffect, useState } from 'react';
import useSocket from '../../hooks/useSocket';
import { Image, Box, Text } from '@chakra-ui/react';
import vote from '../../png/vote.png';
import { ReactComponent as Bracelet } from '../../svg/Bracelet.svg';
import voteSound from '../../sounds/vote.mp3';
import protectionSound from '../../sounds/protection.mp3';

import { animate } from 'motion';
import useSound from '../../hooks/useSound';

function VotePopUp() {
  const [play] = useSound(voteSound);
  const [playProtection] = useSound(protectionSound);

  const [infos, setInfos] = useState({
    playerName: '',
    id: '',
    message: '',
    isProtected: false,
  });
  const socket = useSocket();

  useEffect(() => {
    if (!socket) return;
    socket.on('displayVote', data => {
      setInfos(data);
      animate(
        '#vote',
        { opacity: [0, 1, 0], scale: [0.8, 1, 1] },
        { duration: 5, offset: [0, 0.7] }
      );

      data?.isProtected &&
        animate(
          '#bracelet',
          {
            opacity: [0, 1, 0],
            scale: [1, 15, 15],
            rotate: [0, 85, 100],
            filter: ['brightness(0)', 'brightness(2)', 'brightness(0)'],
          },
          { duration: 3, offset: [0, 0.9] }
        );
      if (play && !data.isProtected) {
        play();
      }
      if (playProtection && data.isProtected) {
        playProtection();
      }
    });
  }, [socket, play, playProtection]);

  return (
    <>
      <Box
        id="vote"
        position="absolute"
        marginLeft="auto"
        marginRight="auto"
        left="0"
        right="0"
        textAlign="center"
        maxH="80vh"
        maxW="100vw"
        fontSize="7xl"
        zIndex={3}
        display="flex"
        justifyContent="center"
        align="center"
        opacity="0"
        top="0"
        pointerEvents="none"
      >
        <Image maxW="inherit" maxH="inherit" src={vote} />
        <Box
          position="absolute"
          left="0"
          right="0"
          textAlign="center"
          top="30%"
        >
          <Text fontWeight="800" fontSize="8xl">
            {infos.playerName}
          </Text>
          <Text p="4 " fontSize="4xl">
            {infos.message}
          </Text>
        </Box>
        <Box id="bracelet" position="absolute" top="50%" opacity="0">
          <Bracelet />
        </Box>
      </Box>
    </>
  );
}

export default VotePopUp;
