import { Flex } from '@chakra-ui/react';
import { Stage } from '@pixi/react';
import usePixi from 'hooks/usePixi';
function PixiCanvas({ children, border = false, customRef, options, ...props }) {
  const {width, height} = usePixi()

  return (
    <Flex w="100%" justify="center" m="2" {...props}>
      <div ref={customRef}>
        <Stage
          style={{ border: border ? '1px dashed black' : 'auto' }}
          height={height}
          width={width}
          options={{ backgroundAlpha: 0, ...options }}
        >
          {children}
        </Stage>
      </div>
    </Flex>
  );
}

export default PixiCanvas;
