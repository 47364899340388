import { ANNOUNCE } from 'constants'
import WalkingAnimation from './WalkingAnimation'
import usePixi from 'hooks/usePixi';
import { Container } from '@pixi/react';
import JoyAnimation from './JoyAnimation';
import ProudAnimation from './ProudAnimation';
import DisappointedAnimation from './DisappointedAnimation';


const Animations = ({ animation, children }) => {
  const { width, height } = usePixi();
  switch (animation) {
    case ANNOUNCE.ANIMATION.WALKING:
      return <WalkingAnimation>
        {children}
      </WalkingAnimation>
    case ANNOUNCE.ANIMATION.JOY:
      return <JoyAnimation>
        {children}
      </JoyAnimation>
    case ANNOUNCE.ANIMATION.PROUD:
      return <ProudAnimation>
        {children}
      </ProudAnimation>
    case ANNOUNCE.ANIMATION.DISAPPOINTED:
      return <DisappointedAnimation>
        {children}
      </DisappointedAnimation>
    default:
      return <Container x={width / 2} y={height / 3}>
        {children}
      </Container>
  }
}

export default Animations