import React, { useState, useEffect } from 'react';
import useSocket from '../../hooks/useSocket';
import { VStack, Flex, } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import Player from 'components/Player';
import { noDoublonPlayers } from 'utils/noDoublonPlayers';
import PixiCanvas from 'components/PixiCanvas';
import LetterByLetterText from './LetterByLetterText';

import normal from "svg/animator/normal.svg"
import Animations from 'animations/Animations';
import useSoundMapping from 'hooks/useSoundMapping';
import { faceMap } from 'constants/faceMap';
import { AnimatedSprite } from '@pixi/react';

function Announcement() {
  const { players } = useSelector(state => state.game);
  const socket = useSocket();
  const dispatch = useDispatch();
  const soundMap = useSoundMapping()

  const [playSound, setPlaySound] = useState(null);
  const [message, setMessage] = useState("");
  const [animatorFaces, setAnimatorFaces] = useState(normal);
  const [animatorAnimation, setAnimatorAnimation] = useState(null);
  const [bg, setBg] = useState('gold');
  const [color, setColor] = useState('black');
  const [playerIds, setPlayerIds] = useState([]);


  useEffect(() => {
    if (playSound) {
      soundMap[playSound]()
    }
  }, [playSound, soundMap])



  useEffect(() => {
    if (!socket || animatorAnimation) return;
    socket.on('announcement', ({ message, players: ids, animatorAnimation, playerAnimation, faces, sound, color }) => {
      setBg(color);
      setColor('black');
      setAnimatorAnimation(animatorAnimation)
      setAnimatorFaces(faces.map(f => faceMap[f]))
      setMessage(message);
      console.log("players ids", ids);
      setPlayerIds(ids);
      setPlaySound(sound)
    });
  }, [socket, dispatch, animatorAnimation ]);


  return (
    <VStack
      position="fixed"
      top={0}
      left={0}
      zIndex="2"
      pointerEvents="none"
      textAlign="center"
      align="center"
      pt="5"
      bg={bg}
      color={color}
      justify="center"
      h='100%'
      w="100%"
      maxW="100%"
      transition="1s ease"
      m="0"
      overflow="hidden"
      spacing={0}
    >
      <PixiCanvas>
        <Animations animation={animatorAnimation}>
          <AnimatedSprite
            images={animatorFaces}
            width={250}
            height={250}
            anchor={{ x: 0.5, y: 0.5 }}
            isPlaying={true}
            animationSpeed={0.01}
          />
        </Animations>
        <LetterByLetterText text={message} />
      </PixiCanvas>

      {players ? <Flex maxW="100%">
        {noDoublonPlayers(players).filter(p => playerIds.includes(p.id) || playerIds.includes(p.firstId)).map((player, index) => (
          <Player
            key={player.id}
            name={player.name}
            id={player.id}
            position="unset"
            avatar={player?.avatar}
            title={player?.title}
          />
        ))}
      </Flex> : null}

    </VStack>
  );
}

export default Announcement;
