import CoinSvg from "svg/coin.svg"
import Palm from 'svg/Palm.svg';
import Fish from 'svg/Fish.svg';
import CampFire from 'svg/ActivityCampfire.svg';
import Hint from 'svg/hint.svg';
import BlackBall from 'svg/black-ball.svg';
import Trees from 'components/Camp/forestAssets/trees.svg';
import Bracelet from 'svg/Bracelet.svg';

const easterEggAvatars = {
  "jeton": CoinSvg,
  "palmier": Palm,
  "poisson": Fish,
  "feu de camps": CampFire,
  "indice": Hint,
  "boule noire": BlackBall,
  "arbres": Trees,
  "bracelet": Bracelet,
  "easy": "/images/easter-egg/easy.jpg",
}

const isEasterEggAvatar = (name) => {
  if (!(name.toLowerCase() in easterEggAvatars)) return null
  return easterEggAvatars[name.toLowerCase()]
}

export { isEasterEggAvatar }