import React, { useState, useEffect, useRef } from 'react';
import { Sprite, useApp, Container } from '@pixi/react';
import * as PIXI from 'pixi.js';
import axeSvg from 'svg/axe.svg';
import goldAxeSvg from 'svg/gold-axe.svg';

const AxeElement = ({ isChopping = false, goldAxe=false, image }) => {
  const [size, setSize] = useState(50);
  const [rotation, setRotation] = useState(0);
  const app = useApp();
  const spriteRef = useRef(null);
  const animationRef = useRef(null);
  const lastMousePositionRef = useRef({ x: 0, y: 0 });

  useEffect(() => {
    const handleMouseMove = (event) => {
      const rect = app.view.getBoundingClientRect();
      const x = event.clientX - rect.left;
      const y = event.clientY - rect.top;
      if (spriteRef.current && !isChopping) {
        spriteRef.current.position.set(x, y);
      }
      lastMousePositionRef.current = { x, y };
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, [isChopping, app.view]);

  useEffect(() => {
    if (isChopping) {
      animateChop();
    } else {
      setRotation(0);
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
        animationRef.current = null;
      }
    }
  }, [isChopping]);

  const animateChop = () => {
    const startTime = performance.now();

    const animate = (currentTime) => {
      const elapsed = currentTime - startTime;

      if (elapsed < 200) {
        // Recul léger
        setRotation(50 * (elapsed / 200));
      } else if (elapsed < 400) {
        // Tranchage rapide
        setRotation(50 - 140 * ((elapsed - 200) / 200));
      } else {
        // Fin de l'animation
        setRotation(0);
        setSize(50);
        // Réinitialiser la position de la hache à la position de la souris
        spriteRef.current.position.set(lastMousePositionRef.current.x, lastMousePositionRef.current.y);
        return;
      }

      animationRef.current = requestAnimationFrame(animate);
    };

    animationRef.current = requestAnimationFrame(animate);
  };

  return (
    <Container>
      <Sprite
        ref={spriteRef}
        texture={PIXI.Texture.from(image ? image : goldAxe ? goldAxeSvg : axeSvg)}
        anchor={0.5}
        width={size}
        height={size}
        rotation={rotation * (Math.PI / 180)}
        interactive={false}
      />
    </Container>
  );
};

export default AxeElement;
