import React, { useEffect, useState, useReducer, useRef } from 'react';
import {
  useDisclosure,
  Text,
  Grid,
  GridItem,
  Box,
  Image,
  Flex,
} from '@chakra-ui/react';
import voteMusic from 'sounds/music/vote.mp3'

import SelectPlayerModal from '../Modal/SelectPlayerModal';
import VotePopUp from '../VotePopUp/VotePopUp';
import useSocket from '../../hooks/useSocket';
import { useSelector } from 'react-redux';
import MotionBox from '../MotionBox/MotionBox';
import UseBraceletModal from '../Modal/UseBraceletModal';
import usePlayerItems from '../../hooks/usePlayerItems';
import useSound from "hooks/useSound"
import Team from '../Teams/Team';
import votePng from '../../png/vote.png';

function Vote() {
  const TIME_TO_VOTE = 45;
  const [timeLeft, setTimeLeft] = useState(TIME_TO_VOTE);

  const { gameId, me, votingTeam, teams } = useSelector(state => state.game);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenBracelet,
    onOpen: onOpenBracelet,
    onClose: onCloseBracelet,
  } = useDisclosure();

  const isOpenRef = useRef(isOpen);

  const isOpenBraceletRef = useRef(isOpenBracelet);
  const [alreadyClosed, onAlreadyClosed] = useReducer(() => true, false);
  const [alreadyVote, onAlreadyVote] = useReducer(() => true, false);

  const {
    items: { bracelets },
  } = usePlayerItems();
  const socket = useSocket();
  const [playMusic, {stop}] = useSound(voteMusic);

  useEffect(() => {
    playMusic()
    return () => stop()
  }, [playMusic, stop])


  useEffect(() => {
    // we don't vote if our team did not lose
    if (votingTeam !== me.team) return;
    if (!me.alive) return;

    if (bracelets && !alreadyClosed && !me?.palm) {
      // don't propose bracelet if already immuned with palm
      onOpenBracelet();
      onAlreadyClosed();
    }
    if (!alreadyVote) {
      onOpen();
      onAlreadyVote();
    }
  }, [
    onOpen,
    onOpenBracelet,
    bracelets,
    alreadyClosed,
    votingTeam,
    me,
    alreadyVote,
  ]);

  // this is to make the setTimeout work with change values
  useEffect(() => {
    isOpenRef.current = isOpen;
    isOpenBraceletRef.current = isOpenBracelet;
  }, [isOpen, isOpenBracelet]);

  useEffect(() => {
    if (votingTeam !== me.team) return;
    setTimeout(() => {
      console.log('isopen', isOpen, isOpenBracelet);
      if (isOpenRef.current || isOpenBraceletRef.current) {
        console.log('vote contre moi meme');
        handleVote({ to: me.firstId, message: 'vote contre moi même' });
      }
    }, TIME_TO_VOTE * 1000);
    const interval = setInterval(() => {
      setTimeLeft(time => time - 1);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line
  }, []);

  const handleVote = ({ to, message }) => {
    socket.emit('sendVote', {
      to,
      message,
      gameId,
    });
    onCloseBracelet();
    onClose();
  };

  return (
    <div textAlign="center">
      <Team
        name={votingTeam}
        team={teams[votingTeam]}
        color={`${votingTeam}Team`}
        position="absolute"
        bottom="0"
      />
      <VoteCount />
      <Box textAlign="center">
        {isOpen || isOpenBracelet ? (
          <Text
            color="white"
            fontSize={{ sm: '2xl', md: '3xl' }}
            fontWeight="bold"
          >
            {timeLeft} secondes restantes pour voter
          </Text>
        ) : null}
      </Box>
      <UseBraceletModal isOpen={isOpenBracelet} onClose={onCloseBracelet} />
      <SelectPlayerModal
        isOpen={!isOpenBracelet && isOpen}
        onValidate={handleVote}
        displayCondition={player => !player.palm}
      />
      <VotePopUp />
    </div>
  );
}

function VoteCount() {
  const socket = useSocket();
  const [votes, setVotes] = useState({});

  useEffect(() => {
    if (!socket) return;
    socket.on('displayVote', data => {
      if (data?.isProtected) return;
      countVotes(data);
    });
  }, [socket]);

  const countVotes = ({ playerName, id, message, from }) => {
    setVotes(votes => {
      const vote = { playerName, message, id, from };

      return {
        ...votes,
        [id]: votes.hasOwnProperty(id) ? [...votes[id], vote] : [vote],
      };
    });
  };

  return (
    <Grid
      templateRows={`repeat(${Object.keys(votes).length}, 1fr)`}
      h="100%"
      w="100%"
    >
      {Object.keys(votes).map((key, i) => (
        <GridItem p="4" rowSpan={1} key={key} color="white">
          <Text fontWeight="bold" fontSize="4xl">
            {votes[key]?.[0].playerName}
          </Text>
          <MotionBox
            key={votes[key]?.from}
            initial={{ scale: 0.5 }}
            animate={{ scale: 1 }}
            transition={{ duration: 2 }}
          >
            <Flex>
              {votes[key].map(vote => (
                <MiniVotePic key={vote.from} message={vote.message} />
              ))}
            </Flex>
          </MotionBox>
        </GridItem>
      ))}
    </Grid>
  );
}

const MiniVotePic = ({ message }) => {
  return (
    <Box
      textAlign="center"
      position="relative"
      textColor="black"
      maxH="10vh"
      w="fit-content"
      mx={2}
    >
      <Image maxW="inherit" maxH="inherit" src={votePng} />
      <Box
        position="absolute"
        left="0"
        right="0"
        top="50%" // Ajustement ici pour placer le texte au milieu de l'image
        transform="translateY(-50%)" // Pour centrer verticalement
        textAlign="center"
      >
        <Text p="4 " fontSize="xs">
          {message}
        </Text>
      </Box>
    </Box>
  );
};

export default Vote;
