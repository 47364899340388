import { useSelector } from 'react-redux';
import useSocket from './useSocket';

export default function useChangeLocation() {
  const socket = useSocket();
  const { gameId, myId } = useSelector(state => state.game);

  const changeLocation = location => {
    // i removed redux because I think it was causing some bugs
    socket.emit('location', { location, gameId, myId });
  };
  return changeLocation;
}
